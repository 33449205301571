.action-invisible {
	--action-bg: #fff;
	background-image: linear-gradient(to right, transparent, var(--action-bg) 2rem);
	opacity: 0;
	pointer-events: none;
	visibility: hidden;

	@at-root tr[class|="table"] & {
		--action-bg: var(--mp-table-bg);
	}

	.action-invisible > * {
		border: 0;
		margin: 0 !important;
	}
}

.show-on-hover {
	position: relative;

	.action-invisible {
		padding-left: 2rem;
		position: absolute;
		z-index: 1;
		transform: translateX(-100%);
		transition: all .2s ease-in-out;

		.action-icon, .btn, .btn-sm {
			font-size: inherit!important;
			padding: 0!important;
			line-height: 1 !important;
		}
	}

	&:hover {
		.action-invisible {
			opacity: 1;
			pointer-events: auto;
			visibility: visible;
		}
	}
}