//
// tabs.scss
//

.nav-tabs,.nav-pills {
	> li {
		> a {
			color: #{map-get($grays, "700")};
			font-weight: $font-weight-semibold;
		}
	}
}

.nav-pills {
	> a {
		color: #{map-get($grays, "700")};
		font-weight: $font-weight-semibold;
	}
}


.bg-nav-pills {
	background-color: $nav-pills-bg;
}

//
// nav-bordered
//

.nav-tabs.nav-bordered {
	border-bottom: 0;
	box-shadow: inset 0 -1px 0 $gray-300;
	flex-wrap: nowrap;

	.nav-item {
		margin-right: 1rem;

		@include media-breakpoint-up(lg) {
			margin-right: 2rem;
		}
	}

	.nav-link {
		border-color: transparent;
		border-radius: 0;
		border-style: solid;
		border-width: 0 0 2px;
		color: $gray-600;
		font-weight: $font-weight-normal;
		gap: .5em;
		margin-bottom: 0;
		max-width: 30ch;
		outline-offset: -1px;
		overflow: hidden;
		padding-left: 0;
		padding-right: 0;
		text-overflow: ellipsis;
		white-space: nowrap;

		&:hover,
		&.active {
			background-color: transparent;
			border-color: tint-color($primary, 20%);
			color: $gray-900;
		}

		&.active {
			font-weight: $font-weight-semibold;

			// & > .badge {
			// 	// font-weight: inherit;
			// }
		}

		@mixin nav-bar-badge-style {
			// color: inherit;
			color: $gray-800;
			background-color: $gray-300;
			font-size: .75rem;
			font-weight: $font-weight-light;
			padding: .25em .5em;
			// vertical-align: baseline;
		}

		& > .badge {
			@include nav-bar-badge-style;
		}

		// Menu dropdown badges
		@at-root .nav-tabs .nav-item-tabs .dropdown-item {
			align-items: center;
			display: flex;
			gap: .5rem;
			justify-content: space-between;

			& > .badge {
				@include nav-bar-badge-style;
			}
		}
	}
}

.nav-item-tabs {
	background-color: var(--#{$variable-prefix}body-bg);
	position: absolute;
	right: 0;
}

// Dark mode
body[data-layout-color="dark"] {
	.nav-tabs,
	.nav-pills {
		> li {
			> a {
				color: $gray-700;
			}
		}
	}

	.nav-pills {
		> a {
			color: $gray-700;
		}
	}

	.nav-tabs.nav-bordered {
		box-shadow: inset 0 -1px 0 $gray-700;

		.nav-link {
			color: $gray-500;

			&:hover,
			&.active {
				color: $gray-200;
			}
		}
	}
}
