//
// page-head.scss
//

.page-title-box {
	.page-title {
		color: $page-title-color;
		column-gap: 1rem;
		flex-shrink: 0;
		font-size: 1.875rem;
		font-weight: $font-weight-light;
		margin: 18px 0px;
		min-width: 0;
	}

	.page-title-right {
		float: right;
		margin-top: 20px;
	}

	.breadcrumb {
		padding-top: 8px;
	}

	.navbar-toggler {
		font-size: .875rem;
		padding: .25rem .5rem;
	}

	.actions-row {
		margin-block: 1rem;
	}
}

.page-title-box-sm {
	.page-title {
		line-height: 1 !important;
		margin-bottom: 25px;
	}

	.page-title-right {
		float: right;
		margin-top: 0;
	}

	.breadcrumb {
		margin-top: -3px !important;
		padding-top: 0;
	}
}

.text-title {
	&,
	&:hover {
		color: $text-title-color;
	}
}

@include media-breakpoint-down(lg) {
	.page-title-box {
		.page-title {
			font-size: 1.5rem;
		}

		.breadcrumb,
		.page-title-right {
			display: none;
		}
	}
}

@include media-breakpoint-down(md) {
	.page-title-box {

		.page-title {
			margin: 6px 0;
		}

		.actions-row {
			display: grid !important;
			grid-template-columns: repeat(2, 1fr);
			margin-block: 0 1rem;

			& > :nth-child(2n+1):last-child {
				grid-column: span 2;
			}

			.dropdown-menu {
				width: calc(100vw - 24px);
			}

			& > :nth-child(2n+1) .dropdown-menu {
				inset-inline-start: 0;
			}

			.btn-group > .btn:first-child {
				flex-basis: 100%;
			}

			.btn-link,
			.btn-default,
			.btn-transparent {
				background-color: var(--#{$variable-prefix}white);
				border-color: var(--#{$variable-prefix}input-border-color);
			}
		}

		.breadcrumb {
			display: none;
		}
	}
}
