//
// save-buttons.scss
//

.save-buttons-wrapper {
	overflow: auto;

	.save-buttons-group {
		gap: 1px;
		padding: 1px;
	}

	.save-buttons-ext-group {
		background-color: $white;
	}

	.tox.tox-tinymce {
		border-width: 0;
		border-radius: unset;

		& + .save-buttons-ext-group {
			// background-color: $body-bg;
			background-color: $white;
		}
	}

	.form-control:disabled + .save-buttons-ext-group,
	.form-control[readonly] + .save-buttons-ext-group {
		background-color: var(--#{$variable-prefix}input-disabled-bg);
	}
}
