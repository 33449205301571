//
// pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
	.page-item {
		&.active .page-link {
			background-color: $gray-200;
			color: $gray-700;
			font-size: $font-size-sm;
		}
	}

	.page-link {
		background-color: transparent;
		border: none;
		color: $gray-600;
		font-size: $font-size-sm;
		line-height: 1rem;
		min-width: 1.5rem;
		padding: .25rem;
		text-align: center;

		&:hover {
			background-color: $gray-100;
		}
	}
}
