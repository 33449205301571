//
// offcanvas.scss
// PORTED from bootstrap 5.1.3 + shop-base-theme
//

.offcanvas {
	position: fixed;
	bottom: 0;
	z-index: $zindex-offcanvas;
	display: flex;
	flex-direction: column;
	max-width: 100%;
	color: $offcanvas-color;
	visibility: hidden;
	background-color: $offcanvas-bg-color;
	background-clip: padding-box;
	outline: 0;
	@include box-shadow($offcanvas-box-shadow);
	@include transition(transform $offcanvas-transition-duration ease-in-out);

	h1,
	.h1 {
		@include font-size($h1-font-size * .65);
	}

	h2,
	.h2 {
		@include font-size($h2-font-size * .65);
	}
}

.offcanvas-backdrop {
	@include overlay-backdrop($zindex-offcanvas-backdrop, $offcanvas-backdrop-bg, $offcanvas-backdrop-opacity);
}

.offcanvas-content {
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.offcanvas-header {
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: $offcanvas-padding-y $offcanvas-padding-x;

	.btn-close {
		padding: ($offcanvas-padding-y * .5) ($offcanvas-padding-x * .5);
		margin-top: $offcanvas-padding-y * -.5;
		margin-right: $offcanvas-padding-x * -.5;
		margin-bottom: $offcanvas-padding-y * -.5;
	}

	// border-bottom: $offcanvas-header-border-width solid $offcanvas-header-border-color;
	// @include box-shadow($offcanvas-header-box-shadow);
	box-shadow: $offcanvas-header-box-shadow;

	// &:not(&-transparent) {
	// 	background-color: $rightbar-title-bg;
	// 	color: $rightbar-title-color;

	// 	.btn-close {
	// 		color: map-get($dark-grays, "600");

	// 		// Override <a>'s hover style
	// 		&:hover {
	// 			color: map-get($dark-grays, "600");
	// 		}
	// 	}
	// }

	// Disable offcanvas-header-transparent on this project
	// &-transparent {
	// 	position: absolute;
	// 	width: 100%;
	// 	border-bottom: unset;
	// 	z-index: 1;
	// 	pointer-events: none;
	// 	box-shadow: none;

	// 	// Fix link clicks that are overlapping the header
	// 	& * {
	// 		pointer-events: auto;
	// 	}

	// 	// Make sure there is content so the close button doesn't wiggle
	// 	// &::before {
	// 	// 	content: "_";
	// 	// 	color: transparent;
	// 	// }
	// }

	&-transparent {
		border-bottom: unset;
		box-shadow: none;
		padding-bottom: 0;
		margin-bottom: -$offcanvas-padding-y;
		z-index: 1; // Allow clicks
	}
}

.offcanvas-title {
	margin-bottom: 0;
	line-height: $offcanvas-title-line-height;

	@include font-size($offcanvas-title-font-size);
	font-weight: 500;

	color: $gray-900;
	@at-root body[data-layout-color="dark"] & {
		color: #{map-get($dark-grays, "900")};
	}

	h1,
	.h1 {
		@include font-size($offcanvas-title-h1-font-size);
		margin-bottom: 0;
	}
}

.offcanvas-body {
	position: relative;
	flex-grow: 1;
	padding: $offcanvas-padding-y $offcanvas-padding-x;
	overflow-y: auto;
	@include scrollbar;
}

.offcanvas-start {
	top: 0;
	left: 0;
	width: $offcanvas-horizontal-width;
	border-right: $offcanvas-border-width solid $offcanvas-border-color;
	transform: translateX(-100%);
}

.offcanvas-end {
	top: 0;
	right: 0;
	width: $offcanvas-horizontal-width;
	border-left: $offcanvas-border-width solid $offcanvas-border-color;
	transform: translateX(100%);
}

.offcanvas-top {
	top: 0;
	right: 0;
	left: 0;
	height: $offcanvas-vertical-height;
	max-height: 100%;
	border-bottom: $offcanvas-border-width solid $offcanvas-border-color;
	transform: translateY(-100%);
}

.offcanvas-bottom {
	right: 0;
	left: 0;
	height: $offcanvas-vertical-height;
	max-height: 100%;
	border-top: $offcanvas-border-width solid $offcanvas-border-color;
	transform: translateY(100%);
	border-radius: $drawer-bottom-border-radius;
	@include box-shadow($box-shadow);

	.btn-close {
		background: none;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		width: auto;

		&:focus {
			box-shadow: none;
		}

		&::before,
		&::after {
			content: "";
			display: block;
			width: 1.5rem;
			height: 2px;
			position: relative;
			background-color: $gray-400;
		}

		&::before {
			transform: rotate(20deg);
			left: 1px;
		}

		&::after {
			transform: rotate(-20deg);
			right: 1px;
		}
	}

	.offcanvas-title {
		margin-top: 1rem;
	}

	// All offcanvas-bottom header except for cookie-bar
	.offcanvas-header:not(.offcanvas-header-transparent) {
		padding-top: $drawer-bottom-padding-top;
		border-bottom: 1px solid $gray-300;
		margin: 0 .5 * $spacer;
		padding-left: .5 * $spacer;
		padding-right: .5 * $spacer;
	}
}

.offcanvas.show {
	transform: none;
}

// Cookie overlayer border-radius fix
.cookie-policy-overlay.offcanvas-bottom {
	border-radius: 0;
}

// Footer (for actions)
.offcanvas-footer {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	flex-shrink: 0;
	align-items: center; // vertically center
	justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
	// padding: $offcanvas-padding-y - $offcanvas-footer-margin-between * .5   $offcanvas-padding-x - $offcanvas-footer-margin-between * .5;
	padding: $offcanvas-padding-y $offcanvas-padding-x;
	// border-top: $offcanvas-footer-border-width solid $offcanvas-footer-border-color;
	// @include box-shadow($offcanvas-footer-box-shadow);
	box-shadow: $offcanvas-footer-box-shadow;

	// Place margin between footer elements
	// This solution is far from ideal because of the universal selector usage,
	// but is needed to fix https://github.com/twbs/bootstrap/issues/24800
	> * {
		// margin: $offcanvas-footer-margin-between * .5;
	}

	&:empty {
		display: none;
	}

	.btn {
		padding: 6px 12px;
	}
}

.offcanvas-sm {
	&.offcanvas-start,
	&.offcanvas-end {
		width: $offcanvas-horizontal-width-sm;
	}

	&.offcanvas-top,
	&.offcanvas-bottom {
		height: $offcanvas-vertical-height-sm;
	}
}

.offcanvas-lg {
	&.offcanvas-start,
	&.offcanvas-end {
		width: $offcanvas-horizontal-width-lg;
	}

	&.offcanvas-top,
	&.offcanvas-bottom {
		height: $offcanvas-vertical-height-lg;
	}
}

.offcanvas-aside {
	&.offcanvas-start,
	&.offcanvas-end {
		width: $offcanvas-horizontal-width-aside;
	}
}

.offcanvas-xl {
	&.offcanvas-start,
	&.offcanvas-end {
		width: $offcanvas-horizontal-width-xl;
	}

	&.offcanvas-top,
	&.offcanvas-bottom {
		height: $offcanvas-vertical-height-xl;
	}
}

.offcanvas-full {
	&.offcanvas-start,
	&.offcanvas-end {
		width: 100vw;
	}

	&.offcanvas-top,
	&.offcanvas-bottom {
		height: 100vh;
	}
}
