.autocomplete-results {

	&:not(:empty) {
		background: #fff;
		// border-radius: 1.25rem;
		// border-top: 2.5rem solid #fff;
		// box-shadow: 0 1px #e9ecef inset;
		box-shadow: 2px 2px 4px #d3d3d3, -2px 0px 2px #d3d3d3;
		border: 1px solid #d3d3d3;
		cursor: default;
		// margin-top: -2.5rem;
		max-height: calc(var(--vh, 1vh) * 100 - 4.75rem);
		max-width: calc(100vw - 2rem);
		overflow-x: hidden;
		overflow-y: auto;
		position: absolute;
		z-index: $zindex-autocomplete-results;
	}

	&__title {
		align-items: center;
		color: #868e96;
		display: flex;
		font-weight: 400;
		white-space: nowrap;
	}

	&__title, &__suggestion {
		line-height: 1.5;
		padding: .5rem 1rem;
		pointer-events: none;
		position: relative;
	}

	&__suggestion {
		font-size: .875rem;
		font-weight: 500;
		align-items: center;
		cursor: pointer;
		display: flex;
		padding: .5rem 1rem;
		pointer-events: auto;

		&[data-selectable="false"] {
			cursor: auto;
			pointer-events: none;
		}

		&--selected {
			margin-left: auto;
			background: #f1f3f5;
		}
	}

	&__text {
		margin: 0 .25rem;
		font-weight: $font-weight-base;
	}

	&__highlight {
		font-weight: $font-weight-bold;
	}

	@media (max-width: 991.98px) {

		&__title, &__suggestion {
			line-height: 1.5;
			min-height: 2.5rem;
		}

		&__title, &__suggestion:not([data-selectable="false"]) {
			border-top: 1px solid #e9ecef;
		}

		&__title {
			background: #f8f9fa;
		}
	}

	@media (min-width: 992px) {

		&__title::after {
			align-self: center;
			background: $gray-300;
			content: "";
			height: 1px;
			margin-left: .75rem;
			width: 100%;
		}

		&__title {
			font-size: .75rem;
			margin-top: .5rem;
			text-transform: uppercase;
		}
	}
}
