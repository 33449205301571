@use "sass:color";

@mixin toast-content-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

@mixin toast-variant($state, $background, $border, $color) {
	color: $color;
	@include gradient-bg($background);
	border-color: color.scale($border, $alpha: -60%);

	.toast-header {
		color: tint-color($color, $toast-header-color-scale-amount);
		// background-color: shade-color($background, $toast-header-bg-scale-amount);
		background-color: color.scale($background, $lightness: $toast-header-bg-scale-amount);
		// border-bottom-color: shade-color($background, $toast-header-border-scale-amount);
		border-bottom-color: color.scale($background, $lightness: $toast-header-border-scale-amount);
	}
}

.toast {
	display: flex;
	flex-direction: column;
	width: $toast-max-width;
	max-width: 100%;
	max-height: calc(100vh - (2 * $toast-padding-y));
	@include font-size($toast-font-size);
	color: $toast-color;
	pointer-events: auto;
	background-color: $toast-background-color;
	background-clip: padding-box;
	border: $toast-border-width solid $toast-border-color;
	box-shadow: $toast-box-shadow;
	@include border-radius($toast-border-radius);

	&.toast-full-width {
		width: 100vw;
	}

	// Not needed anymore
	// &.showing {
	// 	opacity: 0;
	// }

	&:not(.show) {
		display: none;
	}

	a {
		color: currentColor;
		text-decoration: underline;
	}

	&-header {
		@include toast-content-wrapper();
		padding: $toast-padding-y $toast-padding-x;
		color: $toast-header-color;
		background-color: $toast-header-background-color;
		background-clip: padding-box;
		border-bottom: $toast-border-width solid $toast-header-border-color;
		@include border-top-radius(subtract($toast-border-radius, $toast-border-width));

		// .btn-close {
		// 	margin-right: $toast-padding-x * -.5;
		// 	margin-left: $toast-padding-x;
		// }
		.btn-close, .btn-minimize, .btn-maximize {
			flex: 0 0 auto;
			margin-left: $toast-padding-x;
		}

		// Not valid bem (but it will do for now)
		.toast-icon {
			@include font-size($toast-font-size * 1.5);
			line-height: 1;
			vertical-align: middle;
			height: 1em;
			width: 1em;
		}
	}

	&-wrapper {
		@include toast-content-wrapper();

		// Not valid bem (but it will do for now)
		.toast-icon {
			@include font-size($toast-font-size * 1.5);
			margin-left: $toast-padding-x;
			line-height: 1;
			vertical-align: middle;
			height: 1em;
			width: 1em;
		}

		// Not valid bem (but it will do for now)
		.toast-body {
			flex-grow: 1;
		}

		.btn-close, .btn-minimize, .btn-maximize {
			flex: 0 0 auto;
			margin-right: $toast-padding-x;
		}
	}

	&-title {
		width: 100%;
		margin: 0 $toast-padding-x;
		font-weight: $font-weight-bold;
	}

	&-body {
		padding: $toast-padding-x; // apply to both vertical and horizontal
		word-wrap: break-word;
		overflow-wrap: break-word;
		overflow: auto;

		@include scrollbar();
	}

	// Generate contextual modifier classes for colorizing the toast.
	@each $state, $value in $theme-colors {

		$toast-background: shift-color($value, $toast-bg-scale);
		$toast-border: shift-color($value, $toast-border-scale);
		$toast-color: color-contrast($toast-background);

		&-#{$state} {
			@include toast-variant($state, $toast-background, $toast-border, $toast-color);
		}
	}
}

.toast-container {
	position: fixed;
	z-index: $zindex-toast-container;
	width: max-content;
	max-width: 100%;
	pointer-events: none;
	padding: 1rem;

	> :not(:last-child) {
		margin-bottom: $toast-spacing;
	}

	&-x-left {
		left: 0;
	}

	&-x-right {
		right: 0;
	}

	&-x-center {
		left: 50%;
		transform: translateX(-50%);
	}

	&-y-top {
		top: 0;
	}

	&-y-bottom {
		bottom: 0;
	}

	&-y-center {
		top: 50%;
		transform: translateY(-50%);
	}

	// Not valid bem (but for now it will do)
	&-x-center#{&}-y-center {
		/* translateZ + scale = fix blurry edges, maybe */
		-webkit-font-smoothing: subpixel-antialiased;
		transform: translate3d(-50%, -50%, 0) scale(2, 2);
		zoom: 0.5;
	}

	// Fix debug layout
	div.shm-debug-layout {
		margin: 0;

		&:not(:last-of-type) {
			margin-bottom: 1rem;
		}
	}
}
