//
// tables.scss
//

// Table centered (Custom)
table.table-cells-middle > * > * > {
	th, td {
		vertical-align: middle !important;
	}
}
table.table-cells-top > * > * > {
	th, td {
		vertical-align: top !important;
	}
}
table.table-cells-bottom > * > * > {
	th, td {
		vertical-align: bottom !important;
	}
}

// Custom table components (Custom)
.table {
	&-transparent {
		background-color: transparent !important;
	}

	&-alt {
		th {
			color: $gray-500;
			font-weight: $font-weight-normal;
		}
	}

	thead.table-light {
		color: shade-color($white, 67.5%);
	}

	.table-user {
		img {
			height: 30px;
			width: 30px;
		}
	}

	.static-icon,
	.action-icon {
		aspect-ratio: 1;
		color: #{$table-icon-color};
		display: inline-block;
		font-size: 1.1rem;
		line-height: 1.3;
		padding: 0 3px;
	}

	.action-icon {
		&:hover {
			color: #{$table-icon-hover-color};
		}
	}

	.action-image {
		font-size: 1.25rem;
		height: 1em;
		width: 1em;
	}

	.action-image-xs {
		font-size: 1rem;
	}

	.action-image-sm {
		font-size: 1.125rem;
	}

	.action-image-lg {
		font-size: 1.375rem;
	}

	.action-image-xl {
		font-size: 1.5rem;
	}

	& > :not(:first-child) {
		border: inherit;
	}

	& > :not(caption) > * > * {
		background-color: var(--#{$variable-prefix}table-bg) !important;
	}

	&.table-bordered {
		tbody {
			border-top: $border-width solid;
			border-top-color: inherit;
		}
	}

	.table-image-wrapper {
		&-xs,
		&-sm,
		&-xl {
			margin: 0 auto;
		}

		&-sm {
			padding: .25rem;

			&.image-no-photo {
				padding: 1rem;
			}
		}

		&-lg {
			padding: .25rem;

			&.image-no-photo {
				padding: 2.25rem;
			}
		}

		@mixin product-image {
			background-color: $table-image-background-color;

			& > img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				mix-blend-mode: multiply;
			}
		}

		&-xs,
		&-sm,
		&-lg {
			@include product-image();
		}

		@at-root .product-image {
			@include product-image();
		}
	}

	.row-color {
		display: block;
		width: $row-color-tag-size;
		height: $row-color-tag-size;
		background-color: $gray-200;
	}

	// thead > tr {
	// 	box-shadow: 0 0 8px #{rgba($black, .1)};
	// }
	// ? Note: this will not work when the check-all option is selected and all rows & cells have a background-color set
	// &.mp-main-listing > tbody > tr[data-mp-dt-row] {
	// 	// box-shadow: inset 0 8px 2px -1px #{rgba($black, .03)};
	// 	box-shadow: inset 0 8px 10px -1px #{rgba($black, .03)};
	// }
	// ? Note: this is almost the same as the above, but it will work when the check-all option is selected and all rows & cells have a background-color set
	&.mp-main-listing > tbody > tr[data-mp-dt-row] > * {
		box-shadow: inset 0 15px 10px -10px #{rgba($black, .03)};
	}

	// tr.table-subrow {
		// box-shadow:
		// 	inset 0 1px 2px #{rgba($black, .075)},
		// 	inset 0 -2px 2px #{rgba($black, .075)};
		// box-shadow: inset 0 2px 1px #{rgba($black, .1)};
		// background-color: darken(#FFFFFF, 1%);

		// thead > tr,
		// tr[data-mp-dt-row] {
		// 	box-shadow: none;
		// }
	// }

	&.mp-main-listing > :not(caption,thead) > * > * {
		padding-block: 1.1rem; // 1.11 will fix the heading white pixel problem
	}

	// Remove the border from last cell
	& > :not(caption):not(thead) > :last-of-type > * {
		border-bottom-width: 0;
	}

	tr[data-picker-item] {
		cursor: pointer;
		&.selected {
			background-color: #{$table-row-selected-color};
		}
	}

	// Table sorting icons
	.sorting,
	.sorting_asc,
	.sorting_desc,
	.sorting_asc_disabled,
	.sorting_desc_disabled {
		cursor: pointer;
		position: relative;
		padding-right: 30px;
		// padding-left: $table-cell-padding-x;

		&:before,
		&:after {
			position: absolute;
			// bottom: 0.5em;
			display: block;
			opacity: .3;
		}
		&:before {
			top: 12px;
			right: 0.5rem;
			left: auto;
			content: "\F0360";
			font-family: "Material Design Icons";
			font-size: 1rem;
		}
		&:after {
			top: 18px;
			right: 0.5em;
			left: auto;
			content: "\F035D";
			font-family: "Material Design Icons";
			font-size: 1rem;
		}

		@at-root .table.table-sm {
			.sorting,
			.sorting_asc,
			.sorting_desc,
			.sorting_asc_disabled,
			.sorting_desc_disabled {
				padding-right: 20px;
				&:before {
					top: 5px;
					right: 2px;
				}
				&:after {
					top: 11px;
					right: 2px;
				}
			}
		}
	}

	.sorting_asc {
		&:before {
			opacity: 1;
		}
	}

	.sorting_desc {
		&:after {
			opacity: 1;
		}
	}
}

// Tables fluid
.table-nowrap {
	th, td {
		white-space: nowrap;
	}
}

table {
	--#{$variable-prefix}table-color: #{$gray-800};
	& a:not(.btn, .dropdown-item, .tag, .tag-filter-x) {
		color: inherit;
		text-decoration: underline;
		text-decoration-color: $gray-600;
		text-underline-offset: .125rem;

		&:hover {
			text-decoration: none;
		}
	}
	.table-light {
		color: $gray-800;
	}
	// Table Hover
	&.table-hover {
		tbody {
			tr {
				&:hover {
					> * {
						// --#{$variable-prefix}table-accent-bg: #{$body-bg};
						--#{$variable-prefix}table-bg: #{$body-bg};
					}
				}
			}
		}
	}
	// Table Active
	tr {
		&.table-active {
			// --#{$variable-prefix}table-accent-bg: #{$body-bg};
			--#{$variable-prefix}table-bg: #{$body-bg};
			// Add the bg color to the tr because it is transparent and where the cells have some transforms applied it will show the background
			background-color: var(--#{$variable-prefix}table-bg);
			& th,
			& td {
				// Also take cells out in a new stacking context so the background is not visible over other contents that might be in the cells
				position: relative;
			}
		}
	}
	//  Table Striped
	&.table-striped {
		tbody {
			tr {
				&:nth-of-type(odd) {
					>* {
						// --#{$variable-prefix}table-accent-bg: #{$body-bg};
						--#{$variable-prefix}table-bg: #{$body-bg};
					}
				}
			}
		}
	}
}

// Dark mode
body[data-layout-color="dark"] {
	table {
		--#{$variable-prefix}table-color: #{$gray-300};
		// Table Light - Dark
		.table-light {
			--#{$variable-prefix}table-bg: #464f5b;
			color: $gray-100;
			border-color: $table-group-separator-color;
		}
		.table-dark {
			--#{$variable-prefix}table-bg: #424e5a;
		}
		// Table Hover
		&.table-hover {
			thead,
			tbody {
				tr {
					&:hover {
						> * {
							// --#{$variable-prefix}table-accent-bg: rgba(64, 73, 84, .8);
							--#{$variable-prefix}table-bg: rgba(64, 73, 84, .8);
						}
					}
				}
			}
		}
		// Table Active
		tr {
			&.table-active {
				// --#{$variable-prefix}table-accent-bg: rgba(64, 73, 84, .8);
				--#{$variable-prefix}table-bg: rgba(64, 73, 84, .8);
			}
		}
		//  Table Striped
		&.table-striped {
			tbody {
				tr {
					&:nth-of-type(odd) {
						>* {
							// --#{$variable-prefix}table-accent-bg: rgba(64, 73, 84, .8);
							--#{$variable-prefix}table-bg: rgba(64, 73, 84, .8);
						}
					}
				}
			}
		}
	}
}

.table-inactive {
	// img {
	// 	filter: saturate(0);
	// }

	@at-root {
		td#{&},
		tr#{&} > td:not(.table-row-checker, .table-row-actions) > * {
			opacity: .4;
		}
	}
}

.actions-sticky {
	--top-offset: -1px;
	background-color: var(--#{$variable-prefix}card-bg);
	// box-shadow: 0 -1px var(--#{$variable-prefix}border-color) inset;
	position: sticky;
	top: calc($topbar-height + var(--top-offset));
	z-index: $zindex-dropdown - 1;

	// Table actions position fix for displaying in drawer/modal/dialogs
	@at-root {
		[data-overlay-type] & {
			top: 0;
			position: relative;
		}
	}
}

:root {
	--actions-height: 0px;
}

.thead-sticky {
	--top-offset: -1px;
	background-color: var(--#{$variable-prefix}card-bg);
	position: sticky;
	top: calc($topbar-height + var(--top-offset));
	z-index: $zindex-dropdown - 2;

	tr {
		// border-bottom: 1px solid var(--#{$variable-prefix}table-group-separator-color);
	}

	th {
		border-bottom-color: transparent;
		box-shadow: inset 0 -1px var(--mp-table-border-color);
	}

	@at-root {
		.actions-wrap + .table & {
			--top-offset: -2px;
			top: calc($topbar-height + var(--top-offset) + var(--actions-height));
		}

		// Table head position fix for displaying in drawer/modal/dialogs/subrows
		[data-overlay-type] &,
		table table & {
			position: static;
		}
	}

	// @each $breakpoint in map-keys($grid-breakpoints) {
	// 	$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
	// 	@include media-breakpoint-down($breakpoint) {
	// 		@at-root .table-responsive#{$infix} & {
	// 			position: static;
	// 		}
	// 	}
	// }

	&.invisible th {
		line-height: 0;
		padding-block: 0;

		& > * {
			display: none;
		}
	}
}

.mp-dt-actions-bar {
	min-width: 100%;
	width: fit-content;
}

// Orders listing product images
.images-snippet {

	@mixin image-snippet-box-type {
		position: relative;
		border-radius: $images-snippet-list-border-radius;
		width: $images-snippet-list-size;
		height: $images-snippet-list-size;
		background-color: $gray-100;
		overflow: hidden;
		padding: 2px;

		&--m {
			width: $images-snippet-list-size-m;
			height: $images-snippet-list-size-m;
		}

		&--l {
			width: $images-snippet-list-size-l;
			height: $images-snippet-list-size-l;
		}

		&--xl {
			width: $images-snippet-list-size-xl;
			height: $images-snippet-list-size-xl;
		}

		img {
			border-radius: $images-snippet-list-border-radius;
		}
	}

	.images-group {
		display: inline-flex;
		position: relative;

		& > * + * {
			margin-left: .3125 * $spacer;
		}
	}

	.image-wrapper {
		@include image-snippet-box-type();

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			image-rendering: -webkit-optimize-contrast;
			cursor: pointer;

			// Do not change image background on dark schemes;
			@media (prefers-color-scheme: light) {mix-blend-mode: multiply;};

		}
	}

	// Add similar card for counter;
	.count-wrapper {
		@include image-snippet-box-type();

		background-color: rgba($black, .3333);
		color: $white;
		display: grid;
		font-weight: $font-weight-bold;
		line-height: 0;
		margin-left: -.3125 * $spacer;
		place-items: center;
		position: absolute;
		right: 0;
		top: 0;

	}
}

.table > thead:not(.thead-sticky) + tbody.table-group-divider {
	border-top: 1px solid var(--#{$variable-prefix}table-group-separator-color);

	.table-sm#{&} {
		border-top-width: 0;
	}
}

.table {
	.table-group-header-row {
		background-color: #{$gray-100};
	}

	.save-buttons-wrapper,
	.form-check-input,
	.form-control,
	.form-select,
	.select2-selection {
		&:not([disabled]) {
			&:hover,
			&:focus-within {
				border-color: $primary!important;
				transition-duration: .15s;
			}
			transition: border-color .3s ease-in-out;

			&.action-success {
				border-color: $success!important;
			}
			&.action-error {
				border-color: $danger!important;
			}
		}
	}

	.form-control, .form-select {
		min-width: 8ch;
	}

	.fc-width-sm {
		max-width: 8ch;
	}

	.fc-width-md {
		max-width: 16ch;
	}

	.fc-width-lg {
		max-width: 24ch;
	}

	.row-indent-marker {
		min-width: 48px;
	}

	td.drag-handle,
	td.indent-level {
		transition: transform .1s ease-in-out;
	}

	// Hide row expand button if no children
	tr[data-num-children="0"] {
		.row-expand {
			display: none;
		}
	}

	&-with-levels {
		--drag-handle-estimated-outer-width: 44px;
		--drag-handle-initial-scale: 0;
		--drag-handle-base-spacing: var(--drag-handle-estimated-outer-width);
		// This needs to account for the missing td.drag-handle spacing (this td is only present when the .table-sortable class is present)
		--drag-handle-offset-spacing: 44px;
		--drag-handle-spacing-multiplier: 0;

		&.table-sortable {
			--drag-handle-offset-spacing: 0px;
			--drag-handle-initial-scale: 0;
		}

		// Hide the row sub-marker if level is 0
		tr[data-level="0"] {

			--drag-handle-spacing-multiplier: 0;

			.row-sub-marker {
				display: none;
			}
		}

		tr[data-level="1"] {
			--drag-handle-spacing-multiplier: 1;
		}

		tr[data-level="2"] {
			--drag-handle-spacing-multiplier: 2;
		}

		tr[data-level="3"] {
			--drag-handle-spacing-multiplier: 3;
		}

		tr[data-level] {

			--drag-handle-spacing: calc(var(--drag-handle-base-spacing) * var(--drag-handle-spacing-multiplier) + var(--drag-handle-offset-spacing));

			td.drag-handle {
				transform: translateX(var(--drag-handle-spacing)) scaleX(var(--drag-handle-initial-scale));
			}

			td.indent-level {
				--drag-content-spacing: calc(var(--drag-handle-spacing) + var(--drag-handle-estimated-outer-width) * (var(--drag-handle-initial-scale) - 1));
				transform: translateX(var(--drag-content-spacing));
				& > * {
					padding-right: var(--drag-content-spacing);
				}
			}

			// .row-indent-marker {
			// 	margin-left: 48px;
			// }
		}
	}

	.table-row-hide-checker {
		.table-row-checker {
			transform: scaleX(0) translate(0);
			opacity: 0;
			pointer-events: none;
		}
	}

	&-sortable {

		// overflow: hidden;

		.drag-handle {
			cursor: move;
		}

		td, td {
			transition: transform .3s ease-in-out, opacity .3s ease-in-out;
		}

		// tr {
		// 	transition: left .1s ease-in-out;
		// }

		// .row-sortable-chosen,
		// .row-sortable-ghost,
		// .row-sortable-drag,
		// .row-sortable-selected {
		// 	.table-row-checker {
		// 		// visibility: hidden;
		// 	}
		// 	// transform: translateX(90px);
		// }

		.row-sortable-chosen,
		.row-sortable-selected {
			background-color: mix($primary, $white, 15%);
		}

		.row-sortable-ghost {
			position: relative;
			background-color: rgba($gray-200, 25%);
			// td > * {
			// 	opacity: 0;
			// }
		}

		.row-sortable-ghost.row-drag-level-0 {
			--drag-handle-spacing-multiplier: 0;
		}

		.row-sortable-ghost.row-drag-level-1 {
			--drag-handle-spacing-multiplier: 1;
		}

		.row-sortable-ghost.row-drag-level-2 {
			--drag-handle-spacing-multiplier: 2;
		}

		.row-sortable-ghost.row-drag-level-3 {
			--drag-handle-spacing-multiplier: 3;
		}

		.row-selected-parent[data-level] {
			--gradient-spacing-multiplier: calc(var(--drag-handle-spacing-multiplier) + 1);
			--gradient-spacing: calc(var(--gradient-spacing-multiplier) * var(--drag-handle-base-spacing) + var(--drag-handle-offset-spacing));
			background-image: linear-gradient(to right, rgba($primary, 10%) 0%, rgba($primary, 10%) var(--gradient-spacing), rgba($gray-300, 25%) var(--gradient-spacing), transparent 100%);
		}

		.sortable-placeholder-container {
			.row-sortable-ghost {
				background-color: mix($blue, $white, 20%);
			}
		}

		// tr.row-sortable-placeholder td {
		// 	// padding: 0;
		// }

		@at-root .table-sorting-enabled {

			.table-sortable {
				--drag-handle-offset-spacing: -24px;
				--drag-handle-initial-scale: 1;
			}

			[data-batch-actions-row] {
				opacity: 0;
				transition: opacity .3s ease-in-out;
				pointer-events: none;
			}

			thead {
				th, td {
					&:not(.drag-handle-col, .indent-level-col) > * {
						transform: scaleX(0) translate(100%);
						opacity: 0;
						pointer-events: none;
					}
				}
			}

			tbody {
				th, td {
					&:not(.drag-handle, .indent-level, .table-row-checker) {
						transform: scaleX(0) translate(100%);
						opacity: 0;
					}
					&.table-row-checker {
						transform: scaleX(0) translate(0);
						opacity: 0;
						pointer-events: none;
					}
					&.drag-handle {
						pointer-events: auto;
					}
				}
			}
		}
	}
}

// Prevent narrow table text on non desktop
@include media-breakpoint-down(xl) {
	.table .text-break {
		max-width: 60vw;
		width: max-content;
	}
}