[data-widget-product-variants],
[data-widget-product-attributes] {
	.attribute-color-blue   { color: #1c7ed6; }
	.attribute-color-cyan   { color: #1098ad; }
	.attribute-color-grape  { color: #ae3ec9; }
	.attribute-color-green  { color: #37b24d; }
	.attribute-color-indigo { color: #4263eb; }
	.attribute-color-lime   { color: #74b816; }
	.attribute-color-orange { color: #f76707; }
	.attribute-color-pink   { color: #d6336c; }
	.attribute-color-red    { color: #f03e3e; }
	.attribute-color-teal   { color: #0ca678; }
	.attribute-color-violet { color: #7048e8; }
	.attribute-color-yellow { color: #f59f00; }
}
