// Must be scoped to the theme
.select2-container--bootstrap-5 {
	// Hide the dropdown when using 'enableCodeTags' mode
	&.select2-container--invisible {
		// Repair the select input styles
		&.select2-container--above,
		&.select2-container--below {
			.select2-code-tags {
				border: $s2bs5-border-width solid $s2bs5-border-color;
				@include border-radius($s2bs5-border-radius, 0);
				@include box-shadow($s2bs5-box-shadow);
			}
		}

		.select2-dropdown {
			display: none;
		}
	}
}
