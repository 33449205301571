[data-widget-image-choice] {
	.image-choice {
		display: flex;
		padding: $btn-padding-y-sm $btn-padding-x-sm;
		justify-content: center;
		border-radius: $input-border-radius;
		border: $input-border-width solid $input-border-color;

		img {
			min-height: add($input-line-height * 1em, 2 * subtract($btn-padding-y, $btn-padding-y-sm));
		}

		&.active {
			border-color: rgba($primary, 50%);
			background-color: rgba($primary, 15%);
		}
	}

}