// Fix overflow cropping of the color box
.select2-container--bootstrap-5 {
	.status-color-selector {
		.select2-selection__rendered {
			overflow: visible;
		}
	}
}

// Must be scoped to the theme
.select2-container--bootstrap-5 {

	.status-color-selector.select2-selection.activate-on-click {

		transition: border-color .1s ease-in-out, box-shadow .1s ease-in-out, background-image .1s ease-in-out, background-color .1s ease-in-out;

		.select2-selection__clear {
			transition: opacity .1s ease-in-out;
		}

		.status-color-label {
			transition: opacity .3s ease-in-out, font-size .15s ease-in-out;
		}

		.avatar {
			transition: outline .1s ease-in-out;
		}

		// Multi
		&.select2-selection--multiple {
			.select2-selection__choice {
				transition: background-color .1s ease-in-out;
			}
		}
	}

	&:not(.select2-container--open) {

		.status-color-selector.select2-selection.activate-on-click {
			border-color: transparent;
			box-shadow: none;
			background-image: none;
			background-color: transparent;

			.select2-selection__clear {
				opacity: 0;
			}

			.status-color-label {
				opacity: 0;
			}

			.avatar {
				outline-color: currentColor;
			}

			// Multi
			&.select2-selection--multiple {
				.select2-selection__choice {
					background-color: transparent;

					.select2-selection__choice__remove {
						display: none;
					}

					.status-color-label {
						font-size: 0.001px;
					}
				}
			}
		}
	}
}
