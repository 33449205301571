//
// sortable.scss
//

.sortable-handle {
	position: relative;
	width: 36px;
	height: 36px;
	font-size: 24px;
	text-align: center;
	cursor: move;

	&:before {
		content: "\F01DB";
		font-family: "Material Design Icons";
		position: absolute;
	}
}

.sortable-chosen .card {
	background-color: rgba($warning, 0.25);
}
