$default-link-color: $body-color;
$opened-link-color: darken($body-color, 20%);
$active-link-color: $primary;
$active-link-background-color: lighten($primary, 34%);
$menu-transition: max-height .3s linear;

.files-manager {
	color: $body-color;
	list-style-type: none;
	padding-left: 0;

	a {
		color: $default-link-color;
	}

	.menu-folder {
		display: block;
		padding: .3125rem;
		padding-left: 30px;
		position: relative;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		@include border-radius($border-radius);

		&-has-children {
			padding-left: .3125rem;
		}

		// Expand icon
		& > span {
			display: flex;
			align-items: center;
			position: absolute;
			height: 100%;
			left: -10px;
			top: 0;
			font-size: 9px;
		}

		// Folder icon color
		& > i {
			color: currentColor;
		}

		&-opened {
			color: $opened-link-color;

			& ~ ul {
				max-height: 100%;
				transition: all .2s ease-out;
			}
		}

		&-active {
			background-color: $active-link-background-color;
			color: $active-link-color;
		}
	}

	// Children lists
	&	ul {
			transition: all .2s ease-out;
			list-style-type: none;
			padding-left: .5rem;
			max-height: 0;
			overflow: auto;
			overflow-x: hidden;

			@include scrollbar;
		}
}