// @import "./icons/dripicons";
@import "./icons/md-icons-latest/scss/materialdesignicons.scss";
// @import "./icons/remixicon";
// @import "./icons/unicons";

// ------------------------------
// Custom icons
// ------------------------------
@import "./icons/custom-icons";

.mdi {
	vertical-align: middle;
	line-height: 1;
}

.alert .mdi {
	line-height: inherit;
}

.icon-text {
	display: inline-block;
	font-style: inherit;
	font-size: inherit;
	line-height: inherit;
	min-width: 1rem;
	min-height: 1rem;
	font-weight: 600;
}

.icon-svg {
	background-image: var(--icon-url);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	width: 1em;
	height: 1em;
}

// Fix webpack error by specifying the path of the font files
$material-symbols-font-path: '~material-symbols/';
// Import all material variations
// @import "material-symbols";
// Import only the outlined variation
@import "material-symbols/outlined";
// Import only the rounded variation
// @import "material-symbols/rounded";
// Import only the sharp variation
// @import "material-symbols/sharp";

// Alias font class
.icon {
	@extend .material-symbols-outlined;
	// @extend .material-symbols-rounded;
	// @extend .material-symbols-sharp;
}

// Customize the variable font axes (fill, weight, grade, and optical size),
@mixin icon-font-variation {
	font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}
.material-symbols-outlined,
.material-symbols-rounded,
.material-symbols-sharp,
.icon {
  @include icon-font-variation;
}

.icon {
  // speak: none;
  // text-decoration: inherit;
  // text-align: center;
  // /* For safety - reset parent styles, that can break glyph codes*/
  // font-variant: normal;
  // /* fix buttons height, for twitter bootstrap */
  // // line-height: 1em;
	// // line-height: inherit;
  // /* Uncomment for 3D effect */
  // text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);
	// vertical-align: middle;
  // font-size: inherit;
	// vertical-align: middle;
}

.icon-lh {
	line-height: 1!important;
}
.icon-lh-15 {
	line-height: 1.5!important;
}
.icon-lh-2 {
	line-height: 2!important;
}

.icon-xs,
.icon-sm,
.icon-lg,
.icon-xl,
.icon-lh,
.icon-lh-15,
.icon-lh-2 {
	vertical-align: middle!important;
}

.icon-xs {
	font-size: 60%!important;
}
.icon-sm {
	font-size: 80%!important;
}
.icon-lg {
	font-size: 120%!important;
}
.icon-xl {
	font-size: 140%!important;
}
.icon-xxl {
	font-size: 180%!important;
}