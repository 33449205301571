//
// topbar.scss
//

.navbar-custom {
	--navbar-left: #{$leftbar-width};
	padding: 0 ($spacer * 2);
	background-color: $bg-topbar;
	box-shadow: $box-shadow, inset 0 -1px rgba($black, .05);
	height: $topbar-height;
	position: fixed;
	left: var(--navbar-left);
	max-width: calc(100vw - var(--navbar-left));
	top: 0;
	width: 100%;
	z-index: calc($zindex-sticky + 1);

	.topbar-left {
		background-color: $bg-topbar;
		height: $topbar-height;
		position: fixed;
		z-index: 1;
		width: $leftbar-width;
		text-align: center;
		top: 0;
		left: 0;

		.logo {
			line-height: $topbar-height;

			i {
				display: none;
			}
		}
	}

	.topbar-menu {
		position: relative;
		z-index: 1;

		li {
			// float: left;
			max-height: $topbar-height;

			@include media-breakpoint-up(lg) {
				min-width: 2.75rem;
			}

			.show {
				&.nav-link {
					color: #{map-get($grays, "700")};
				}
			}
		}

		.nav-link {
			color: #{map-get($grays, "600")};
			display: block;
			margin: 0 10px;
			min-width: 32px;
			padding: 0;
			position: relative;
			text-align: center;
		}

		& > .notification-list {
			display: inline-flex;

			&:not(:first-child) {
				border-left: 1px solid var(--#{$variable-prefix}bg-topbar-search);
			}

			& > a {
				margin: 0;
				min-width: 2rem;
				padding: 0 .5rem;

				@include media-breakpoint-up(lg) {
					min-width: 3rem;
					padding: 0 1rem;
				}

				&.btn:not(.border-0) {
					margin: auto .5rem;

					@include media-breakpoint-up(lg) {
						margin: auto 1rem;
					}
				}
			}

			&:last-child {
				margin-right: -$spacer;

				& > a {
					padding-right: -$spacer;
				}
			}
		}
	}

	.app-search {
		align-self: center;
		overflow-y: hidden;
		position: static;

		form {
			// padding: calc(#{$topbar-height - 38px} * 0.5) 5px calc(#{$topbar-height - 38px} * 0.5) 0;
			overflow: hidden;
			max-width: 320px;
		}
	}
}

.topbar-dropdown {
	.nav-link {
		line-height: $topbar-height;
	}
}

/* Search */

.app-search {
	.form-control {
		background-color: $bg-topbar-search;
		border: none;
		box-shadow: none;
		height: $input-height;
		padding-left: 34px;
	}

	span.search-icon {
		font-size: 20px;
		left: 10px;
		line-height: 38px;
		position: absolute;
		top: 0;
		z-index: 4;
	}

	.input-group-text {
		margin-left: 0;
		z-index: 4;
	}
}


/* Notification */
.notification-list {
	margin-left: 0;

	.noti-title {
		background-color: transparent;
		padding: 15px 20px;
	}

	.noti-icon {
		font-size: 20px;
		vertical-align: middle;
		line-height: $topbar-height;
	}

	.noti-icon-badge {
		display: inline-block;
		position: absolute;
		top: 22px;
		right: 12px;
		border-radius: 50%;
		height: 7px;
		width: 7px;
		background-color: $danger;
	}

	.notify-item {
		padding: 10px 20px;

		&.unread-noti {
			background-color: #{tint-color(map-get($grays, "100"), 15%)};
		}

		&.read-noti {
			background-color: transparent;
			border: #{$border-width} solid #{map-get($grays, "200")};
		}

		.card-body {
			padding: 14px;

			.noti-close-btn {
				position: absolute;
				top: 3px;
				right: 5px;
			}

			.noti-item-title {
				margin: 0 0 2px;
			}

			.noti-item-title, .noti-item-subtitle {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		.notify-icon {
			float: left;
			height: 36px;
			width: 36px;
			line-height: 36px;
			text-align: center;
			border-radius: 50%;
			color: $white;
		}

		.notify-details {
			margin-bottom: 0;
			overflow: hidden;
			margin-left: 45px;

			b {
				font-weight: 500;
			}

			small {
				display: block;
			}
		}

		.user-msg {
			margin-left: 45px;
			white-space: normal;
			line-height: 16px;
		}
	}

	&.dropdown > .dropdown-toggle::after {
		margin-left: .25em;
	}

	.topbar-dropdown-menu {
		.notify-item {
			padding: 7px 20px;
		}
	}
}

.profile-dropdown {
	min-width: 170px;

	i,
	span {
		vertical-align: middle;
	}
}

.nav-user {
	padding: calc(#{$topbar-height - 39px} * 0.5) 20px calc(#{$topbar-height - 39px} * 0.5) 57px !important;
	text-align: left !important;
	position: relative;
	background-color: #{lighten(map-get($grays, "100"), 2.5%)};
	border: #{($border-width) solid map-get($grays, "100")};
	border-width: 0 1px;
	min-height: $topbar-height;

	.account-user-avatar {
		position: absolute;
		top: calc(#{$topbar-height - 32px} * 0.5);
		left: 15px;

		img {
			height: 32px;
			width: 32px;
		}
	}

	.account-position {
		display: block;
		font-size: 12px;
		margin-top: -3px;
	}

	.account-user-name {
		display: block;
		font-weight: $font-weight-semibold;
	}
}

.button-menu-mobile {
	appearance: none;
	background-color: transparent;
	border: none;
	flex-shrink: 0;
	height: $topbar-height;
	line-height: $topbar-height;
	margin-left: -$spacer;
	padding: 0 $spacer;
	position: relative;
	z-index: 2;

	// mobile backdrop
	@include media-breakpoint-down(md) {
		&::before {
			background-color: $black;
			content: "";
			inset: 0;
			opacity: 0;
			pointer-events: none;
			position: fixed;
			transition: opacity .1s ease-in-out;

			@at-root body:not([data-leftbar-compact-mode="condensed"]) & {
				opacity: .25;
				pointer-events: auto;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		margin-left: -$spacer;
	}

	&.disable-btn {
		display: none;
	}

	&-collapse,
	&-expand {
		background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 13'><path d='M2.875 8.75.625 6.5l2.25-2.25m-2.25 8.125h13.75M5.125 6.5h9.25M5.125.625h9.25' fill='none' stroke='#{$gray-600}' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.25'/></svg>"));
		background-size: .9375rem .8125rem;
		height: .8125rem;
		width: .9375rem;
	}

	&-expand {
		transform: scaleX(-1);

		@at-root body:not([data-leftbar-compact-mode="condensed"]) & {
			display: none;
		}
	}

	@at-root body[data-leftbar-compact-mode="condensed"] &-collapse {
		display: none;
	}
}

[data-keep-enlarged="true"] {
	.navbar-custom {
		padding-left: 0;
	}

	//.button-menu-mobile {
	//    margin: 0;
	//
	//    &.disable-btn {
	//        display: inline-block;
	//    }
	//}
}

@media (max-width: 576px) {
	.navbar-custom {
		.topbar-menu {
			position: initial;
		}

		.dropdown {
			position: static;

			//.dropdown-menu {
			//	left: 10px!important;
			//	right: 10px!important;
			//}
		}
	}
}

/*
@media (max-width: 1200px) {
	.navbar-custom {
		right: 0;

		.app-search {
			display: none;
		}
	}
}
*/

@include media-breakpoint-down(lg) {
	.navbar-custom {
		padding-left: $spacer;
		padding-right: $spacer;

		& + .container-fluid {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.nav-user {
		padding: 17px 5px 17px 57px !important;

		.account-position,
		.account-user-name {
			display: none;
		}
	}
}

@include media-breakpoint-down(md) {
	.navbar-custom {
		--navbar-left: 0px !important;
	}
}

// @media (max-width:375px) {
// 	.navbar-custom {
// 		.topbar-menu {
// 			.nav-link {
// 				margin: 0 5px;
// 			}
// 		}
//
// 		&.topnav-navbar {
// 			.container-fluid {
// 				padding-right: 12px;
// 				padding-left: 12px;
// 			}
//
// 			.navbar-toggle {
// 				margin: 27px 3px
// 			}
//
// 			.button-menu-mobile {
// 				width: auto;
// 			}
// 		}
// 	}
// }

// Scrollable Layout

@include media-breakpoint-up(lg) {
	body[data-leftbar-compact-mode="scrollable"]:not([data-layout-mode="boxed"]):not([data-layout="topnav"]) {
		.navbar-custom {
			position: absolute;
		}
	}
}

// Topbar Dark
.topnav-navbar-dark {
	background-color: $bg-dark-topbar;

	.nav-user {
		background-color: $nav-user-bg-dark-topbar !important;
		border: 1px solid $nav-user-border-dark-topbar !important;
	}

	.topbar-menu {

		li {
			.show {
				&.nav-link {
					color: $white !important;
				}
			}
		}
	}

	.app-search {
		.form-control {
			background-color: $bg-dark-topbar-search;
			color: $white;
		}
		span {
			color: #{map-get($grays, "600")};
		}
	}

	.navbar-toggle {
		span {
			background-color: rgba($white, 0.8) !important;
		}
	}
}

body[data-layout-mode="boxed"] {
	.navbar-custom {
		--navbar-left: 0px !important;
		position: relative;
		margin: -$topbar-height (-$spacer) 0;
	}

	&[data-layout="topnav"] {
		.navbar-custom {
			margin: 0;
		}
	}
}

// Dark mode
body[data-layout-color="dark"] {
	.navbar-custom {
		.topbar-menu {
			li {
				.show {
					&.nav-link {
						color: #{map-get($dark-grays, "700")};
					}
				}
			}

			.nav-link {
				color: #{map-get($dark-grays, "600")};
			}
		}
	}
	.notification-list {
		.notify-item {
			&.unread-noti {
				background-color: #{tint-color(map-get($dark-grays, "300"), 1%)};
			}

			&.read-noti {
				border: #{$border-width} solid #{map-get($dark-grays, "300")};
			}
		}
	}

	//.button-menu-mobile {
	//	color: #{map-get($dark-grays, "900")};
	//}

	.nav-user {
		background-color: #{lighten(map-get($dark-grays, "100"), 2.5%)};
		border: #{($border-width) solid map-get($dark-grays, "100")};
	}

	.topnav-navbar-dark {
		.app-search {
			span {
				color: #{map-get($dark-grays, "600")};
			}
		}
	}
}

.sticky-under-topbar {
	top: $topbar-height;
	z-index: $zindex-dropdown;
}

.navbar-sulogin::after {
	background-color: rgba(255, 50, 0, .1);
	content: "";
	inset: 0;
	pointer-events: none;
	position: absolute;
	z-index: 1;
}
