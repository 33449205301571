.select2-container--bootstrap-5 {
	.select2-selection--single {
		padding: $s2bs5-padding-y $s2bs5-indicator-padding $s2bs5-padding-y $s2bs5-padding-x;
		background-image: escape-svg($s2bs5-indicator);
		background-repeat: no-repeat;
		background-position: $s2bs5-bg-position;
		background-size: $s2bs5-bg-size;

		// Custom bg
		@at-root .select2-container--bootstrap-5.select2-container--open {
			.select2-selection--single {
				// background: rgba(#d6d6d6, 0.06); // wtf? why?
				box-shadow: inset 0px 2px 3px -2px;
			}
			&.select2-container--below {
				.select2-selection--single {
					box-shadow: inset 0px 1px 2px -2px, inset 0px -1px 2px -2px;
				}
			}
		}

		// Rendered view
		.select2-selection__rendered {
			padding: 0;
			font-weight: $s2bs5-font-weight;
			line-height: $s2bs5-line-height;
			color: $s2bs5-color;

			// Placeholder
			.select2-selection__placeholder {
				font-weight: $s2bs5-font-weight;
				line-height: $s2bs5-line-height;
				color: $s2bs5-placeholder-color;
			}

			// Disable arrow
			.select2-selection__arrow {
				display: none;
			}
		}
	}
}
