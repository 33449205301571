.select2-container--bootstrap-5 {
	.select2-selection--multiple {
		display: flex;
		flex-wrap: wrap;
		gap: .25rem;

		// Custom bg
		@at-root .select2-container--bootstrap-5.select2-container--open {
			.select2-selection--multiple {
				// background: rgba(#d6d6d6, 0.06); // wtf? why?
				box-shadow: inset 0px 2px 3px -2px;
			}
			&.select2-container--below {
				.select2-selection--multiple {
					box-shadow: inset 0px 1px 2px -2px, inset 0px -1px 2px -2px;
				}
			}
		}

		// List items
		.select2-selection__rendered {
			display: contents;
			padding-left: 0;
			margin: 0;
			list-style: none;

			// Items
			.select2-selection__choice {
				display: flex;
				flex-direction: row-reverse;
				align-items: center;
				padding: $s2bs5-multi-item-padding-y $s2bs5-multi-item-padding-x;
				// margin-right: .375rem;
				// margin-bottom: .375rem;
				// @include font-size($s2bs5-font-size);
				@include font-size(14px);
				color: $s2bs5-color;
				cursor: auto;
				// border: $s2bs5-border-width solid $s2bs5-border-color;
				background: $s2bs5-item-active-bg;
				@include border-radius($s2bs5-border-radius);

				// Removal button
				.select2-selection__choice__remove {
					width: $s2bs5-clear-width;
					height: $s2bs5-clear-height;
					padding: $s2bs5-clear-padding-y $s2bs5-clear-padding-x;
					margin-left: .25rem;
					overflow: hidden;
					text-indent: 100%;
					white-space: nowrap;
					background: $s2bs5-clear-bg;
					border: 0;

					&:hover {
						background: $s2bs5-clear-hover-bg;
					}

					// Remove children
					>span {
						display: none;
					}
				}

				// Override tags styles when the '.select2-code-tags' is present
				@at-root .select2-container--bootstrap-5 .select2-selection--multiple.select2-code-tags .select2-selection__rendered .select2-selection__choice {
					background: $gray-300;
					border: none;
				}
			}
		}

		// Input area
		.select2-search {
			display: block;
			// width: 100%;
			width: auto;
			height: $s2bs5-height-inner;
			padding: 0 1.5rem 0 .5rem;

			// Input field
			.select2-search__field {
				width: 100%;
				height: $s2bs5-height-inner;
				margin-top: 0;
				margin-left: 0;
				font-family: $s2bs5-font-family;
				line-height: $s2bs5-line-height;
				background-color: transparent;
			}
		}

		// Clear button override
		.select2-selection__clear {
			right: $s2bs5-padding-x;
		}

		&.tag-bg-color .select2-selection__choice {
			color: #fff;
		}
		&.tag-bg-color .select2-selection__choice .select2-selection__choice__remove,
		&.tag-bg-color .select2-selection__choice .select2-selection__choice__remove:hover {
			background: $s2bs5-clear-white-bg;
		}

		&.tag-bg-blue   .select2-selection__choice { background-color: #1c7ed6; }
		&.tag-bg-cyan   .select2-selection__choice { background-color: #1098ad; }
		&.tag-bg-grape  .select2-selection__choice { background-color: #ae3ec9; }
		&.tag-bg-green  .select2-selection__choice { background-color: #37b24d; }
		&.tag-bg-indigo .select2-selection__choice { background-color: #4263eb; }
		&.tag-bg-lime   .select2-selection__choice { background-color: #74b816; }
		&.tag-bg-orange .select2-selection__choice { background-color: #f76707; }
		&.tag-bg-pink   .select2-selection__choice { background-color: #d6336c; }
		&.tag-bg-red    .select2-selection__choice { background-color: #f03e3e; }
		&.tag-bg-teal   .select2-selection__choice { background-color: #0ca678; }
		&.tag-bg-violet .select2-selection__choice { background-color: #7048e8; }
		&.tag-bg-yellow .select2-selection__choice { background-color: #f59f00; }
	}

	.select2-selection--multiple:not(.select2-selection--clearable) {
		// Input area
		.select2-search {
			&--inline {
				width: 100%;
			}
		}
	}
}
