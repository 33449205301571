//
// alert.scss
//

@each $color, $value in $theme-colors {
	.alert-#{$color} {
		background-color: rgba($value, .18);
		border-color: rgba($value, .25);
		color: shade-color($value, 5%);

		.alert-link {
			color: shade-color($value, 30%);
		}
	}
}

.alert {
	&-highlighted::before {
		border-radius: 0 3px 3px 0;
		border-right: 3px solid var(--#{$variable-prefix}link-color);
		bottom: 0;
		content: "";
		height: calc(100% - 32px);
		left: 0;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 0;
	}

	a:not(.btn) {
		color: currentColor;
		text-decoration: underline;
	}
}
