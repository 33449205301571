//
// rating.scss
//

.rateit .rateit-hover-rtl, .rateit .rateit-selected-rtl {
    right: auto;
}

.rating-stars {
	color: $rating-star-color-empty;
	display: inline-block;
	font-size: $rating-star-input-width;
	line-height: 1;
	position: relative;
	vertical-align: top;
	white-space: nowrap;
	margin: $rating-star-spacing-y 0;
	margin-right: $rating-star-spacing-x;

	&::before {
		content: "\2605\2605\2605\2605\2605";
		vertical-align: text-top;
	}

	&-fill {

		color: $rating-star-color-full;
		left: 0;
		overflow: hidden;
		position: absolute;
		top: 0;
		z-index: 1;

		&::before {
			content: "\2605\2605\2605\2605\2605";
			vertical-align: text-top;
		}

		&--0-5 {width: 10%;}
		&--1 {width: 20%;}
		&--1-5 {width: 30%;}
		&--2 {width: 40%;}
		&--2-5 {width: 50%;}
		&--3 {width: 60%;}
		&--3-5 {width: 70%;}
		&--4 {width: 80%;}
		&--4-5 {width: 90%;}
		&--5 {width: 100%;}
	}

	&-count {
		display: inline-block;
		color: $gray-600;
		vertical-align: middle;
	}
}