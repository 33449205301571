//
// text.scss
//

// Weight

.fw-semibold {
	font-weight: $font-weight-semibold !important;
}

.text-body {
	color: #{map-get($grays, "700")} !important;
}

@each $color, $value in $theme-colors {
	.text-#{$color} {
		color: $value !important;
	}
}

// Dark mode
body[data-layout-color="dark"] {
	@each $color, $value in $dark-theme-colors {
		.text-#{$color} {
			color: $value !important;
		}
	}
	.text-body {
		color: #{map-get($dark-grays, "500")} !important;
	}
}

.line-clamp-2 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	line-height: $line-height-base * 1em;     /* fallback */
	max-height: $line-height-base * 2em;      /* fallback */
	-webkit-line-clamp: 2; /* number of lines to show */
	-webkit-box-orient: vertical;
}