.ss-results {
	&:not(:empty) {
		display: flex;
		flex-direction: column;
		background: $ss-results-bg;
		@include box-shadow($ss-results-box-shadow);
		border: $ss-results-border;
		cursor: default;
		overflow-x: hidden;
		overflow-y: auto;
		@include scrollbar;
		position: absolute;
		z-index: $zindex-search-results;
		max-height: 100vh;
		z-index: $zindex-offcanvas;

		max-width: 32rem;
		margin-left: auto;
		margin-right: auto;

		@include media-breakpoint-down(md) {
			max-width: 100vw;
		}
	}

	&__col,
	&__group,
	&__items {
		display: flex;
		flex-direction: column;
	}

	&__col {
		padding: $ss-results-col-spacing;
		max-width: 100%;

		&:not(:only-child):not(:last-child) {
			border-bottom: 1px solid $ss-results-group-border-color;
		}
	}

	&__group {
		padding: $ss-results-group-padding;
	}

	&__group:not(:last-child) {
		border-bottom: 1px solid $ss-results-group-border-color;
	}

	&__col,
	&__items {
		flex: 1 0 auto;
	}

	&__item {
		cursor: pointer;
		display: flex;
		flex: 1 0 auto;
		gap: .625rem;
		padding: $ss-results-entry-spacing;
		text-decoration: none;

		@include transition(background ease .3s);

		&:hover,
		&--selected {
			background: $ss-results-selected-item-bg;
		}
	}

	&__header {
		@include font-size($ss-results-header-font-size);
		color: $ss-results-header-color;
		font-weight: $ss-results-header-font-weight;
		line-height: 1;
		padding: $ss-results-header-spacing;
		display: flex;
		align-items: center;

		& .mdi {
			font-size: 1.125rem;
		}
	}

	&__text-group {
		padding: 0 $ss-results-text-spacing;
		display: flex;
		flex-direction: column;
		justify-content: center;
		overflow: hidden;
	}

	&__text {
		@include font-size($ss-results-text-font-size);
		color: $ss-results-text-color;
		font-weight: $ss-results-text-font-weight;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		.tag {
			font-size: .75rem;
			padding: .375em .5em;
		}
	}

	&__highlight {
		color: $ss-results-highlight-color;
		font-weight: $ss-results-highlight-font-weight;
		font-style: $ss-results-highlight-font-style;
		padding: unset;
		background-color: unset;
	}

	&__desc {
		@include font-size($ss-results-desc-font-size);
		color: $ss-results-desc-color;
		font-weight: $ss-results-desc-font-weight;
	}

	&__image {
		align-items: center;
		background-color: $white;
		border: $ss-results-image-border;
		border-radius: $border-radius;
		display: flex;
		flex: 0 0 $ss-results-image-width;
		height: $ss-results-image-width;
		justify-content: center;
		width: $ss-results-image-width;

		&[data-counter] {
			position: relative;

			&::before {
				background-color: rgba($black, .25);
				border-radius: inherit;
				color: $white;
				content: "+" attr(data-counter);
				display: grid;
				inset: 0;
				place-items: center;
				position: absolute;
				text-shadow: 1px 1px rgba($black, .25);
			}
		}

		&-img {
			border-radius: inherit;
			height: 100%;
			object-fit: cover;
			width: 100%;
		}

		.mdi {
			-webkit-text-stroke: 1px;
			-webkit-text-stroke-color: #fff;
		}
	}

	// Two columns
	@include media-breakpoint-up(md) {

		&:not(:empty) {
			flex-direction: row;
		}

		&__col:not(:only-child) {
			flex: 0 0 50%;

			&:not(:last-child) {
				border-bottom: unset;
			}
		}

		&__col:not(:last-child) {
			margin-right: $ss-results-group-side-spacing;
			border-right: 1px solid $ss-results-group-border-color;
		}

		&:not(:empty)#{&}--column {
			flex-direction: column;

			.ss-results__col:not(:last-child) {
				margin-right: 0;
				border-right: 0;
				border-bottom: 1px solid $ss-results-group-border-color;
			}
		}
	}
}