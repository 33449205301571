.input-group-container {
	position: relative;
	display: flex;
	flex-wrap: wrap; // For form validation feedback
	align-items: stretch;
	width: 100%;

	.form-control,
	.form-select {
		position: relative; // For focus state's z-index
		flex: 1 1 auto;
		min-width: 0; // https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size
	}
	.form-select {
		padding: $input-padding-y $input-padding-x + $form-select-indicator-padding $input-padding-y $input-padding-x;
	}

	// Bring the "active" form control to the top of surrounding elements
	.form-control:focus,
	.form-select:focus {
		z-index: 3;
	}

	// Ensure buttons are always above inputs for more visually pleasing borders.
	// This isn't needed for `.input-group-text` since it shares the same border-color
	// as our inputs.
	.btn {
		position: relative;
		z-index: 2;

		&:focus {
			z-index: 3;
		}
	}

	[class*="col-"] {
		.btn {
			display: block;
			width: 100%;
			text-align: left;
		}
	}
}

// Sizing
//
// Remix the default form control sizing classes into new ones for easier
// manipulation.

.input-group-container-lg .form-control,
.input-group-container-lg .form-select,
.input-group-container-lg .input-group-text,
.input-group-container-lg .btn {
	padding: $input-padding-y-lg $input-padding-x-lg;
	@include font-size($input-font-size-lg);
	@include border-radius($input-border-radius-lg);
}

.input-group-container-sm .form-control,
.input-group-container-sm .form-select,
.input-group-container-sm .input-group-text,
.input-group-container-sm .btn {
	padding: $input-padding-y-sm $input-padding-x-sm;
	@include font-size($input-font-size-sm);
	@include border-radius($input-border-radius-sm);
}

.input-group-container-lg .form-select,
.input-group-container-sm .form-select {
	padding-right: $form-select-padding-x + $form-select-indicator-padding;
}

// Rounded corners
//
// These rulesets must come after the sizing ones to properly override sm and lg
// border-radius values when extending. They're more specific than we'd like
// with the `.input-group >` part, but without it, we cannot override the sizing.

// stylelint-disable-next-line no-duplicate-selectors
.input-group-container {
	> :not(.last-child):not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
		.form-control,
		.form-select,
		.input-group-text,
		.btn {
			@include border-end-radius(0);
		}
	}

	$validation-messages: "";
	@each $state in map-keys($form-validation-states) {
		$validation-messages: $validation-messages + ":not(." + unquote($state) + "-tooltip)" + ":not(." + unquote($state) + "-feedback)";
	}

	> :not(.first-child):not(:first-child):not(.dropdown-menu)#{$validation-messages} {
		margin-left: -$input-border-width;
		.form-control,
		.form-select,
		.input-group-text,
		.btn {
			@include border-start-radius(0);
		}
	}
}
