.select2-container--bootstrap-5 {
	.select2-cat-tree-results {
		.select2-results__options {
			.select2-results__option:not(.select2-results__option--group) {
				display: flex;
				position: relative;
			}
		}
		.select2-results__options:not(.select2-results__options--nested) {
			max-height: 22rem;
		}
	}
}

.select2-cat-tree-results {

	.select2-results__options {
		// padding: 0 .5rem;
		padding: .5rem;

		.select2-results__option {
			padding: .5rem !important;
		}
	}

	.level:not(.level1)::before,
	.level:not(.level1)::after,
	.level.level3 > span::before {
		content: "";
		left: -28px;
		position: absolute;
		z-index: -1;
	}

	.level:not(.level1)::before,
	.level.level3 > span::before {
		border-left: 1px solid #ddd;
		bottom: 50%;
		top: -20px;
	}

	.level:not(.level1)::after {
		border-top: 1px solid #ddd;
		top: 50%;
		width: 27px;
	}

	.level.level3 > span::before {
		bottom: 0;
		left: -48px;
	}

	.level.level4 > span::before {
		border-color: #ddd;
		border-style: solid;
		border-width: 0 1px;
		bottom: 50%;
		content: "";
		left: -68px;
		position: absolute;
		top: -20px;
		width: 21px;
	}

	.level {
		padding-bottom: 3px;
		padding-top: 3px;
	}

	.level1 {
		margin-left: 22px;
	}

	.level2 {
		margin-left: 42px;
	}

	.level3 {
		margin-left: 62px;
	}

	.level4 {
		margin-left: 82px;
	}

	.readonly,
	.readonly-checked {
		color: #b3b3b3;
		pointer-events: none;

		&.form-check-input[type="radio"],
		&.form-check-input[type="checkbox"] {
			filter: saturate(0);
		}
	}

	.selectable-checked {
		color: #616161;

		&.form-check-input[type="radio"],
		&.form-check-input[type="checkbox"] {
			filter: none;
		}
	}

	.cat-tree-item {
		display: flex;
		align-items: center;
		// gap: 0.5rem;

		.level-icon {
			background: #fff;
			color: #868e96;
			margin-left: -1.5rem;

			&::before {
				transform: scale(1.5);
			}
		}

		.item-selector {
			flex-shrink: 0;
			margin: 0 .5rem 0 0;
		}

		.item-selector + span {
			-webkit-box-orient: vertical;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			max-height: 70px;
			overflow: hidden;
		}
	}
}
