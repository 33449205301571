//
// menu.scss
//

// Logo
.logo {
	align-items: center;
	display: flex;
	// justify-content: center;
	line-height: $topbar-height;
	opacity: .9;
	padding: 0 14px 0 18px;
	position: fixed;
	top: 0;
	transition: color .15s ease-in-out, opacity .15s ease-in-out;
	width: $leftbar-width;

	@media (prefers-reduced-motion: reduce) {
		transition: none;
	}

	&:hover {
		opacity: 1;
	}

	//span.logo-lg {
	//	display: block;
	//}

	//span.logo-sm {
	//	display: none;
	//}

	.logo-light {
		display: $logo-light-display;
	}

	.logo-dark {
		display: $logo-dark-display;
	}
}

// Wrapper
.wrapper {
	height: 100%;
	// overflow: hidden;
	width: 100%;
}

//Content Page
.content-page {
	// overflow: hidden;
	padding: $topbar-height 12px 65px;
	min-height: 100dvh;
	min-height: calc(var(--vh, 1dvh) * 100);

	@include media-breakpoint-up(md) {
		margin-left: $leftbar-width;
	}
}

// Left Side-menu (Default dark)
.leftside-menu {
	width: $leftbar-width;
	// z-index: 10;
	z-index: $zindex-fixed;
	background: $bg-leftbar-gradient;
	bottom: 0;
	position: fixed;
	top: 0;
	padding-top: $topbar-height;
	box-shadow: $box-shadow;

	& > .leftside-menu-container:has(> .side-nav),
	.simplebar-content {
		display: flex;
		flex-direction: column;
		height: calc(100dvh - $topbar-height);
		height: calc(var(--vh, 1dvh) * 100 - $topbar-height);
	}

	hr {
		background-color: transparent;
		border-color: rgba($black, .5) transparent rgba($white, .1);
		border-style: solid;
		border-width: 1px 0;
		opacity: 1;
		position: relative;
		z-index: 1;

		@at-root body:not([data-leftbar-theme="dark"]) & {
			border-color: $gray-200;
			border-width: 0 0 1px;
		}
	}

	& > hr {
		margin: -1px 18px 0;
	}
}

.leftside-menu-todo {
	color: $menu-item;
	line-height: 1.25;
	padding: 1.5rem 18px;

	&:hover {
		background-color: rgba($white, .025);
		color: $menu-item-hover;
	}

	&-title {
		color: $gray-800;
	}

	@at-root body[data-leftbar-theme="dark"] & {
		color: $sidebar-dark-menu-item;

		&:hover {
			color: $sidebar-dark-menu-item-hover;
		}

		&-title {
			color: $white;
		}
	}

	&-subtitle {
		align-items: center;
		display: flex;
		font-size: 13px;
		gap: 1rem;
		justify-content: space-between;
		margin-top: 5px;

		&::after {
			content: "\f0142";
			font-family: "Material Design Icons";
			font-size: 1.1rem;
			line-height: 1;
		}
	}

	.circle-wrap {
		flex-shrink: 0;
		max-width: 100%;
		height: auto;
	}

	@at-root body[data-leftbar-compact-mode="condensed"] & {
		padding: 18px;

		.leftside-menu-todo-text {
			display: none;
		}

		.circle-wrap {
			font-size: $small-font-size;
			font-weight: $font-weight-normal;
		}
	}
}

// Side-nav
.side-nav {
	list-style-type: none;
	margin-bottom: 0;
	padding: 12px 0;
	width: 100%;

	& + & {
		background: $bg-leftbar;
		bottom: 0;
		margin-top: auto;
		position: sticky;
	}

	ul {
		list-style-type: none;
	}

	.side-nav-link {
		align-items: center;
		color: $menu-item;
		display: flex;
		padding: 8px 10px 8px 25px;
		font-size: $font-size-base;
		min-height: 42px;
		position: relative;
		transition: color .1s ease-in-out;
		list-style: none;

		&:hover,
		&:focus,
		&:active {
			color: $menu-item-hover;
			text-decoration: none;
		}

		&[data-bs-toggle="collapse"] {
			padding-right: 30px;
		}

		span {
			vertical-align: middle;
		}

		i {
			display: inline-block;
			line-height: 1;
			margin: 0 14px 0 0;
			font-size: 1.25rem;
			vertical-align: middle;
		}


	}

	.menu-arrow {
		display: inline-block;
		font-family: 'Material Design Icons';
		font-size: 1.1rem;
		line-height: 1.5rem;
		padding: 0;
		position: absolute;
		right: 14px;
		text-rendering: auto;
		transform: rotate(90deg);
		transition: transform .15s ease-in-out;

		&::before {
			content: "\f0142";
		}
	}

	.side-nav-item {
		& > a[aria-expanded="true"] {
			span.menu-arrow {
				transform: rotate(-90deg);
			}
		}

		&.menuitem-active {
			& > a:not(.collapsed) {
				span.menu-arrow {
					transform: rotate(-90deg);
				}
			}
		}

		// Badge style
		.badge {
			color: map-get($utilities-text-colors, "light");
			background-color: map-get($utilities-bg-colors, "secondary");
			font-size: .75rem;
			font-weight: $font-weight-light;
			vertical-align: middle;
		}

		.inactive {
			& > span {
				color: $gray-600;

				& i.mdi {
					color: $gray-600;
				}
			}
		}
	}

	.side-nav-title {
		padding: 12px 30px;
		letter-spacing: .05em;
		pointer-events: none;
		cursor: default;
		font-size: 0.6875rem;
		text-transform: uppercase;
		color: $menu-item;
		font-weight: $font-weight-bold;
	}

	.menuitem-active {
		> a {
			background-color: rgba($white, .025);
			color: $menu-item-active !important;
		}
	}
}

*[dir="ltr"] {
	.side-nav {
		.side-nav-item {
			.menu-arrow {
				&:before {
					content: "\F0141" !important;
				}
			}
		}
	}
}

.side-nav-second-level,
.side-nav-third-level,
.side-nav-fourth-level {
	padding: 0;

	li {
		a,
		.side-nav-link {
			align-items: center;
			color: $menu-item;
			display: flex;
			font-size: $font-size-sm;
			padding: 6px 10px 6px 59px;
			position: relative;
			transition: color .1s ease-in-out;

			&:focus,
			&:hover {
				color: $menu-item-hover;
			}

			&[data-bs-toggle="collapse"] {
				padding-right: 30px;
			}

			//.menu-arrow {
			//	line-height: 1.3rem;
			//}
		}

		&.active {
			& > a {
				color: $menu-item-active;
			}
		}

		&:first-child > a {
			padding-top: 12px;
		}

		&:last-child > a {
			padding-bottom: 12px;
		}
	}
}

.side-nav-third-level {
	li {
		a, .side-nav-link {
			padding: 6px 10px 6px 79px;
		}
	}
}

.side-nav-fourth-level {
	li {
		a, .side-nav-link {
			padding: 6px 10px 6px 99px;
		}
	}
}

.side-nav {
	.side-nav-locked > i {
		font-size: .875rem;
	}
}

// Enlarge menu
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) {
	// min-height: 1600px;

	.wrapper {
		// Side menu
		.leftside-menu {
			padding-top: $topbar-height;
			position: fixed;
			width: 70px;
			// z-index: 5;

			.simplebar-mask,
			.simplebar-content-wrapper {
				overflow: visible !important;
			}

			.simplebar-scrollbar {
				display: none !important;
			}

			.simplebar-offset {
				bottom: 0 !important;
			}

			.logo {
				// background: $bg-leftbar-gradient;
				padding: 0 18px;
				text-align: center;
				width: 70px;
				z-index: 1;
			}

			.side-nav{
				.side-nav-heading {
					padding-left: 24px;
				}
			}
		}

		// Help Box
		.help-box {
			display: none;
		}

		// Content Page
		.content-page {
			margin-left: 70px;
		}

		// Navbar & Footer
		.navbar-custom {
			--navbar-left: 70px;
		}

		.footer {
			left: 70px;
		}

		@include media-breakpoint-down(md) {
			.content-page {
				margin-left: 0;
			}
		}
	}

	// Sidebar Menu
	.side-nav {
		.side-nav-title,
		//.badge,
		//.side-nav-locked,
		.menu-arrow {
			display: none !important;
		}

		.collapse,
		.collapsing{
			display: none;
			height: inherit !important;
			transition: none !important;

			.side-nav-second-level,
			.side-nav-third-level,
			.side-nav-fourth-level {
				display: none !important;
			}
		}

		.menuitem-active > a {
			background-color: rgba($white, .05);
		}

		.side-nav-item {
			position: relative;
			white-space: nowrap;

			.side-nav-link {
				min-height: 42px;
				padding: 10px 20px;
				transition: none;

				&:hover,
				&:active,
				&:focus {
					color: $menu-item-hover;
				}

				i {
					margin-left: auto;
					margin-right: auto;
				}

				span {
					display: none !important;
				}
			}

			&:hover {
				//.side-nav-link {
				//	position: relative;
				//	width: $leftbar-width;
				//	color: $white;
				//	background: $bg-leftbar-gradient;

				//	span {
				//		display: inline;
				//	}
				//}

				> ul {
					background: $bg-leftbar-gradient;
					border-radius: 5px;
					box-shadow: $box-shadow;
					display: block !important;
					height: auto !important;
					left: 55px;
					padding: 5px 0;
					position: absolute;
					top: 0;
					// width: calc($leftbar-width - 70px);
					z-index: 9999;

					a {
						padding: 6px 20px;
						position: relative;
						// width: calc($leftbar-width - 70px);

						&:hover {
							background-color: rgba($white, .05);
							color: $sidebar-dark-menu-item-hover;
						}
					}
				}

				> .collapse,
				> .collapsing {
					display: block !important;
					transition: none !important;

					> ul {
						background: $bg-leftbar-gradient;
						border-radius: 5px;
						box-shadow: $box-shadow;
						display: block !important;
						left: 55px;
						padding: 5px 0;
						position: absolute;
						top: 0;
						// width: calc($leftbar-width - 70px);

						a {
							box-shadow: none;
							padding: 6px 20px;
							position: relative;
							// width: calc($leftbar-width - 70px);
							z-index: 6;

							&:hover {
								background-color: rgba($white, .05);
								color: $sidebar-dark-menu-item-hover;
							}
						}

						li {
							&:hover {
								> .collapse {
									display: block !important;
									height: auto !important;
									transition: none !important;

									> ul {
										display: block;
										left: calc($leftbar-width - 70px);
										top: 0;
										position: absolute;
										// width: calc($leftbar-width - 70px);
									}
								}
							}
						}
					}
				}
			}
		}
	}

	//.logo {
	//	span.logo-lg {
	//		display: none;
	//	}
	//	span.logo-sm {
	//		display: block;
	//		line-height: 70px;
	//		color: $primary;
	//	}
	//}
}

@include media-breakpoint-down(md) {
	//body {
	//	overflow-x: hidden;
	//}
	//.leftside-menu {
	//    box-shadow: $box-shadow;
	//    display: none;
	//    z-index: 10 !important;
	//}
	.sidebar-enable {
		.leftside-menu {
			display: block;
		}
	}
	.navbar-nav.navbar-right {
		float: right;
	}
	//.content-page {
	//    margin-left: 0 !important;
	//    padding: 65px 10px 65px;
	//}
	.leftside-menu {
		transition: transform .1s ease-in-out;

		@at-root body[data-leftbar-compact-mode="condensed"] & {
			left: 0;
			margin-left: 0;
			opacity: 0;
			transform: translateX(-100%);
		}
	}
	//.logo {
	//	span.logo-lg {
	//		display: block;
	//	}
	//	span.logo-sm {
	//		display: none;
	//	}
	//}
	body.show:not(.sidebar-enable) {
		.leftside-menu,
		.button-menu-mobile::before {
			opacity: 0;
			pointer-events: none;
		}
	}
}

@include media-breakpoint-down(sm) {
	.leftside-menu {
		min-width: 75vw;
	}
}

/// Help-box
.help-box {
	border-radius: 5px;
	padding: 20px;
	margin: 65px 25px 25px;
	position: relative;
	background-color: $help-box-light-bg;

	.close-btn {
		position: absolute;
		right: 10px;
		top: 10px;
	}
}
// Light
body[data-leftbar-theme="light"] {
	.help-box {
		background-color: $primary;
	}

	.logo {
		.logo-light {
			display: $logo-dark-display;
		}
		.logo-dark {
			display: $logo-light-display;
		}
	}
}

// Layout -topnav
body[data-layout="topnav"] {
	.content-page {
		margin-left: 0 !important;
		padding: 0 0 60px 0;
	}
}

// Layout-boxed
body[data-layout-mode="boxed"] {
	background-color: $boxed-layout-bg;

	.wrapper {
		max-width: $boxed-layout-width;
		margin: 0 auto;
		background-color: $body-bg;
		box-shadow: $box-shadow;
	}

	&[data-leftbar-compact-mode="condensed"] {
		.logo {
			position: relative;
			margin-top: -$topbar-height;
		}
	}
}

// Scrollable Layout

@include media-breakpoint-up(xl) {
	body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"]) {
		padding-bottom: 0;

		.wrapper {
			display: flex;
		}

		.leftside-menu {
			position: relative;
			min-width: $leftbar-width;
			max-width: $leftbar-width;
			padding-top: 0;
		}

		.logo {
			position: relative;
			margin-top: 0;
		}

		.content-page {
			margin-left: 0;
			width: 100%;
			padding-bottom: 60px;
		}
	}
}

// Detached Left sidebar

body[data-layout="detached"] {
	padding-bottom: 0;

	@include media-breakpoint-up(lg) {
		.container-fluid {
			max-width: 95%;
		}
	}

	&[data-layout-mode="boxed"] {
		.wrapper {
			max-width: 100%;
		}
	}

	.wrapper {
		display: flex;
		overflow: inherit;
	}

	.content-page {
		margin-left: 0;
		overflow: hidden;
		padding: 0 15px 5px 30px;
		position: relative;
		margin-right: -15px;
		width: 100%;
		padding-bottom: 60px;
	}

	.leftside-menu {
		position: relative;
		background: $bg-detached-leftbar !important;
		min-width: $leftbar-width;
		max-width: $leftbar-width;
		box-shadow: $box-shadow;
		margin-top: 30px;
		padding-top: 0 !important;
		z-index: 1001 !important;

		.side-nav {
			.side-nav-link {
				color: $menu-item !important;
				&:hover,
				&:focus,
				&:active {
					color: $menu-item-hover !important;
				}
			}

			.side-nav-second-level,
			.side-nav-third-level,
			.side-nav-fourth-level {
				li {
					a {
						color: $menu-item;
						&:focus,
						&:hover {
							color: $menu-item-hover;
						}
					}
					&.mm-active {
						> a {
							color: $menu-item-active;
						}
					}
				}
			}

			.menuitem-active {
				> a {
					color: $menu-item-active !important;
				}
			}

			.side-nav-title {
				color: $menu-item;
			}
		}
	}

	.leftbar-user {
		background: url("../assets/images/waves.png") no-repeat;
		padding: 30px 20px;
		text-align: center;

		.leftbar-user-name {
			font-weight: 700;
			color: #{map-get($grays, "900")};
			margin-left: 12px;
			margin-top: 8px;
			display: block;
		}
	}

	@include media-breakpoint-down(md) {
		&.sidebar-enable {
			.leftside-menu {
				position: fixed;
				left: 0;
				overflow-y: auto;
				margin-top: 70px;
			}
		}

		.wrapper {
			max-width: 100%;
		}

		.content-page {
			margin-left: 0 !important;
			padding: 0 10px 60px 10px;
		}

		.lang-switch {
			display: none;
		}

		&[data-leftbar-compact-mode="condensed"] {
			&.sidebar-enable {
				.leftside-menu {
					margin-top: 0px;
				}
			}
		}
	}

	// Enlarge menu
	&[data-leftbar-compact-mode="condensed"] {
		.wrapper {
			// Side menu
			.leftside-menu {
				max-width: 70px;
				min-width: 70px;
				position: relative;
			}
			.leftbar-user {
				display: none;
			}

			// Content Page
			.content-page {
				margin-left: 0;
			}

			//Footer
			.footer {
				left: 0;
			}
		}
		// Sidebar Menu
		.side-nav {
			.side-nav-item {
				.side-nav-link {
					&:hover,
					&:active,
					&:focus {
						color: $menu-item-hover;
					}
				}

				&:hover {
					//.side-nav-link {
					//	background: $primary;
					//	color: $white !important;
					//	transition: none;
					//}

					 ul {
						background: $bg-leftbar !important;
						box-shadow: $box-shadow;

						a {
							&:hover {
								color: $menu-item-hover !important;
							}
						}
					}
				}
			}
		}
	}
}

// reaponsive detached mode
@include media-breakpoint-up(lg) {
	body[data-layout="detached"] {
		&[data-leftbar-compact-mode="scrollable"] {
			.wrapper {
				padding-top: 70px;
			}
		}
	}
}

//.button-menu-mobile {
//	.lines {
//		width: 18px;
//		display: block;
//		position: relative;
//		height: 16px;
//		transition: all .5s ease;
//		margin-top: 26px;
//		margin-left: 10px;
//	}
//	span {
//		height: 2px;
//		width: 100%;
//		background-color: rgba($white, 0.8);
//		display: block;
//		margin-bottom: 5px;
//		transition: transform .5s ease;
//
//		&:nth-of-type(2) {
//			width: 24px;
//		}
//	}
//
//	&.disable-btn {
//		display: none;
//	}
//}

// Light sidebar

body[data-leftbar-theme="light"] {
	.leftside-menu {
		background: $bg-leftbar;

		.logo {
			// background: $bg-leftbar !important;
			color: $menu-item;

			&:hover {
				color: $menu-item-active;
			}
		}
	}

	.side-nav {
		.side-nav-link {
			color: $menu-item;
			&:hover,
			&:focus,
			&:active {
				color: $menu-item-hover;
			}
		}

		.menuitem-active {
			> a {
				color: $menu-item-active !important;
			}
		}

		.side-nav-title {
			color: $menu-item;
		}

		.side-nav-second-level,
		.side-nav-third-level,
		.side-nav-fourth-level {
			li {
				a {
					color: $menu-item;
					&:focus,
					&:hover {
						color: $menu-item-hover;
					}
				}
				&.active {
					>a {
						color: $menu-item-active;
					}
				}
			}
		}
	}

	// Enlarge menu
	&[data-leftbar-compact-mode="condensed"] {
		// Sidebar Menu
		.side-nav {
			.side-nav-item {

				.side-nav-link {
					&:hover,
					&:active,
					&:focus {
						color: $menu-item-hover;
					}
				}

				&:hover {
					//.side-nav-link {
					//	background: $primary;
					//	color: $white !important;
					//	transition: none;
					//}

					> ul {
						background: $bg-leftbar;
						box-shadow: $box-shadow;

						a {
							&:hover {
								color: $menu-item-hover;
							}
						}
					}

					> .collapse,
					> .collapsing {

						> ul {
							background: $bg-leftbar;

							a {
								&:hover {
									color: $menu-item-hover;
								}
							}
						}
					}
				}
			}
		}
	}
}

// Dark sidenav
body[data-leftbar-theme="dark"] {
	.leftside-menu {
		background: $bg-leftbar;

		.logo {
			// background: $bg-leftbar !important;
			color: $sidebar-dark-menu-item;

			&:hover {
				color: $sidebar-dark-menu-item-active;
			}
		}
	}

	.side-nav {
		.side-nav-link {
			color: $sidebar-dark-menu-item;

			&:hover {
				background-color: rgba($white, .025);
				color: $sidebar-dark-menu-item-hover;
			}
		}

		.menuitem-active {
			> a {
				color: $sidebar-dark-menu-item-active !important;
			}
		}

		.side-nav-title {
			color: $sidebar-dark-menu-item;
		}

		.side-nav-second-level,
		.side-nav-third-level,
		.side-nav-fourth-level {
			background-color: rgba($white, .025);

			li {
				a {
					color: $sidebar-dark-menu-item;
					&:hover {
						background-color: rgba($white, .025);
						color: $sidebar-dark-menu-item-hover;
					}
				}
				&.active {
					>a {
						color: $sidebar-dark-menu-item-active;
					}
				}
			}
		}
	}

	// Enlarge menu
	&[data-leftbar-compact-mode="condensed"] {
		// Sidebar Menu
		.side-nav {
			.side-nav-item {

				.side-nav-link {
					&:hover {
						background-color: rgba($white, .025);
						color: $sidebar-dark-menu-item-hover;
					}
				}

				&:hover {
					//.side-nav-link {
					//	background: $primary;
					//	color: $white !important;
					//	transition: none;
					//}

					> ul {
						background: $bg-leftbar;
						box-shadow: $box-shadow;

						a {
							&:hover {
								background-color: rgba($white, .025);
								color: $sidebar-dark-menu-item-hover;
							}
						}
					}

					> .collapse,
					> .collapsing {

						> ul {
							background: $bg-leftbar;
						}
					}
				}
			}
		}
	}
}



// left-sidebar hide

body {
	&[data-layout="full"] {
		.navbar-custom {
			//.button-menu-mobile {
			//	&.disable-btn {
			//		display: block;
			//	}
			//}
		}
		&.hide-menu {
			.wrapper {
				.leftside-menu {
					width: 0 !important;
					padding-top: 0;
					.side-nav {
						opacity: 0.2;
					}
					.logo {
						width: 70px;
						// background: $bg-leftbar-gradient !important;
						// transition: $transition-base;
						// span.logo-lg {
						// 	display: none;
						// }
						// span.logo-sm {
						// 	display: block;
						// }
					}
				}
			}
			.content-page {
				margin-left: 0 !important;
				.navbar-custom {
					--navbar-left: 70px;
				}
				.footer {
					left: 0 !important;
				}
			}
			&[data-leftbar-compact-mode="condensed"] {
				.wrapper {
					.leftside-menu {
						padding-top: $topbar-height;
						.side-nav {
							transform: translateX(-50px);
							visibility: hidden;
						}
					}
				}
			}
		}
		&[data-leftbar-compact-mode="scrollable"] {
			&.hide-menu {
				.wrapper {
					.leftside-menu {
						max-width: 0px;
						min-width: 0px;
						// padding-top: 0 !important;
					}
				}
			}
			.wrapper {
				.leftside-menu {
					padding-top: 0 !important;
				}
			}
		}
		&[data-leftbar-theme="dark"] {
			&.hide-menu {
				.wrapper {
					.leftside-menu {
						.logo {
							background: $bg-leftbar !important;
						}
					}
				}
			}
		}
		&[data-leftbar-theme="light"] {
			&.hide-menu {
				.wrapper {
					.leftside-menu {
						.logo {
							background: $bg-leftbar !important;
						}
					}
				}
			}
		}
	}
}

// Dark mode
body[data-layout-color="dark"] {
	&[data-layout-mode="boxed"] {
		.wrapper {
			background-color: var(--#{$variable-prefix}body-bg);
		}
	}
	&[data-layout=detached] {
		.leftbar-user {
			.leftbar-user-name {
				color: #{map-get($dark-grays, "900")};
			}
		}
	}
}

.side-nav:first-child {
	.side-nav-item {
		&:nth-child(1) i { color: $yellow-400; }
		&:nth-child(2) i { color: $teal-400; }
		&:nth-child(3) i { color: $orange-400; }
		&:nth-child(4) i { color: $blue-400; }
		&:nth-child(5) i { color: $green-400; }
		&:nth-child(6) i { color: $indigo-400; }
	}
}

.side-nav:last-child {
	.side-nav-item {
		&:first-child i { color: $indigo-400; }
	}
}
