// Base layout
.select2-container--bootstrap-5:not(.select2-container--disabled) {

	// Container element
	.select2-selection__rendered--sortable:not(.select2-selection__rendered--sortable--dragging) {

		// Sortable items
		.select2-selection__choice {
			transition: outline .1s ease-in-out, background-color .1s ease-in-out;

			@at-root .select2-container--bootstrap-5:not(.select2-container--disabled) .select2-selection__rendered--sortable--dragging {
				.sortable-chosen {
					background-color: rgba($s2bs5-item-active-bg, .75);
				}
			}

			&:hover {
				cursor: grab;
				background-color: rgba($s2bs5-item-active-bg, .75);
			}
		}
	}
}
