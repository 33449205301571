// =======================================================================
/* Custom animations */
// =======================================================================

$variations: (
	'focus': (
		'bg': rgba($teal, 15%),
		'bg-row': rgba($teal, 25%),
	),
	'success': (
		'bg': rgba($success, 15%),
		'bg-row': rgba($success, 25%),
	),
	'error': (
		'bg': rgba($danger, 15%),
		'bg-row': rgba($danger, 25%),
	),
);

@mixin scroll-target-init($variation: 'focus') {
	// ? These var names are NOT the same as the ones used in the `animate` library
	--animation-delay: 0ms;
	--animation-duration: 1.2s;
	animation: highlight-#{$variation} var(--animation-duration) var(--animation-delay) cubic-bezier(0, 0, 0.3, -0.15);
	background-color: rgba(255, 255, 255, 0);
}

@mixin scroll-target($variation: 'focus') {

	@if $variation == 'focus' {
		[id]:target,
		.scroll-target-#{$variation}:not(tr) {
			@include scroll-target-init($variation);
		}
		[id]:target {
			--animation-delay: .1s;
			--animation-duration: 2s;
		}
	} @else {
		.scroll-target-#{$variation}:not(tr) {
			@include scroll-target-init($variation);
		}
	}

	tr.scroll-target-#{$variation} {
		// position: relative;
		-webkit-mask: linear-gradient(red 0 0);
		mask: linear-gradient(red 0 0);
		&::after {
			// Faster animation, because any overlays (select2) will also be clipped until the animation ends
			animation: highlight-#{$variation}-row var(--animation-duration, 600s) var(--animation-delay, 0ms) cubic-bezier(0, 0, 0.3, -0.15);
			background-color: map-get(map-get($variations, $variation), 'bg-row');
			content: "";
			inset: 0;
			opacity: 0;
			position: absolute;
			pointer-events: none;
		}
	}

	@keyframes highlight-#{$variation} {
		25% {
			background-color: map-get(map-get($variations, $variation), 'bg');
		}
	}

	@keyframes highlight-#{$variation}-row {
		25% {
			opacity: .5;
		}
	}
}

@include scroll-target('focus');
@include scroll-target('success');
@include scroll-target('error');