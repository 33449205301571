
.form {
	&__rating {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;

		// @for $i from 0 through $form-rating-max-items {
		// 	& :nth-child(#{$i + 1}) {
		// 		order: #{$form-rating-max-items - $i};
		// 	}
		// }
	}

	&__rating-option {
		width: $form-rating-input-width;
		height: $form-rating-input-height;
		margin: $form-rating-spacing-y 0;
		padding: 0 $form-rating-spacing-x + $form-rating-input-width * .5;
		vertical-align: top;
		appearance: none;
		print-color-adjust: exact; // Keep themed appearance for print
		cursor: pointer;
		@include transition($form-rating-transition);
		content: "\F04D2";
		color: $form-rating-color-empty;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		background-image: escape-svg(str-replace($form-rating-star-empty-bg-image, "%%COLOR%%", $form-rating-color-empty));

		&:checked,
		&:checked~&,
		&:hover,
		&:hover~& {
			content: "\F04CE";
			color: $form-rating-color-full;
			background-image: escape-svg(str-replace($form-rating-star-full-bg-image, "%%COLOR%%", $form-rating-color-full));
		}

		.form--validated &:invalid, .form__row--invalid &:invalid {
			content: "\F04D2";
			color: map-get(map-get($form-validation-states, "invalid"), "color");
			background-image: escape-svg(str-replace($form-rating-star-empty-bg-image, "%%COLOR%%", map-get(map-get($form-validation-states, "invalid"), "color")));
		}
	}
}