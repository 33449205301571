[data-widget-attribute-options] {

	.options-list {
		max-height: calc((15px * 1.5 * 10) + 20rem + 20px);
		overflow-x: hidden;
		overflow-y: auto;
		padding-right: .5rem;

		@include scrollbar;
	}
}
