//
// apexcharts.scss
//
.apex-charts {
	min-height: 16px;
	// text {
	// 	font-family: $font-family-base;
	// 	fill: #{map-get($grays, "500")};
	// 	tspan {
	// 		fill: #{map-get($grays, "500")};
	// 	}
	// 	&.apexcharts-title-text {
	// 		fill: #{map-get($grays, "500")};
	// 	}
	// }

	.apexcharts-canvas {
		margin: 0 auto;
	}
}

.apexcharts-tooltip-series-group.apexcharts-active, .apexcharts-tooltip-series-group:last-child {
	padding-bottom: 0 !important;
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
	font-family: $font-family-base;
	// color: #{map-get($grays, "700")};
}

.apex-charts-custom-tooltip {
	padding: .75rem;
}
.apexcharts-tooltip-group-wrapper {
	display: flex;
	flex-direction: column;
	@include font-size(12px);

	&:not(:last-child) {
		border-bottom: 1px solid #e3e3e3;
		margin-bottom: 0.75rem;
		padding-bottom: 0.75rem;
	}

	.apexcharts-tooltip-series-group,
	.apexcharts-tooltip-y-group {
		padding: 0;
	}
}

.apexcharts-tooltip-title-custom {
	margin-bottom: .5rem;
	font-weight: $font-weight-bolder;
	// @include font-size(13px);
}

.apexcharts-legend {
	left: 50% !important;
	position: fixed !important;
	transform: translateX(-50%);
	width: 100%;

	&-series {
		font-weight: 600;
	}
}

// .apexcharts-gridline {
// 	pointer-events: none;
// 	stroke: $apex-grid-color;
// }

.apexcharts-legend-text {
	// color: #{map-get($grays, "600")};
	font-family: $font-family-base;
}

.apexcharts-yaxis,
.apexcharts-xaxis {
	text {
		font-family: $font-family-base;
		// fill: #{$dark};
	}
}

// .apexcharts-point-annotations,
// .apexcharts-xaxis-annotations,
// .apexcharts-yaxis-annotations {
// 	text {
// 		fill: $white;
// 	}
// }

// .apexcharts-radar-series {
// 	polygon {
// 		fill: transparent;
// 		stroke: #{map-get($grays, "300")};
// 	}
// 	line {
// 		stroke: #{map-get($grays, "300")};
// 	}
// }

// .apex-charts text,
// .apexcharts-pie-label,
// .apexcharts-datalabel-label,
// .apexcharts-datalabel-value {
// 	fill: $white;
// }

// .apexcharts-xaxis .apexcharts-datalabel,
// .apexcharts-yaxis text {
// 	fill: #{map-get($grays, "600")};
// }

// .apexcharts-datalabels-group {
// 	text {
// 		fill: #{map-get($grays, "500")};
// 	}
// }

// Scatter images chart
.scatter-images-chart {
	.apexcharts-legend {
		overflow: hidden;
		min-height: 17px;
	}
	.apexcharts-legend-marker {
		background: none;
		margin-right: 7px;
	}
	.apexcharts-legend-series {
		align-items: flex-start;
	}
}

.apexcharts-pie-series {
	path {
		stroke: transparent;
	}
}

.apexcharts-track {
	path {
		stroke: #{lighten(map-get($grays, "300"), 5%)};
	}
}

// Dark mode
body[data-layout-color="dark"] {
	.apex-charts {
		text {
			fill: #{map-get($dark-grays, "500")};
			tspan {
				fill: #{map-get($dark-grays, "500")};
			}
			&.apexcharts-title-text {
				fill: #{map-get($dark-grays, "500")};
			}
		}
	}
	.apexcharts-tooltip-title,
	.apexcharts-tooltip-text {
		font-family: $font-family-base;
		// color: #{map-get($dark-grays, "400")};
	}
	.apexcharts-legend-text {
		color: #{map-get($dark-grays, "600")};
	}

	.apexcharts-yaxis,
	.apexcharts-xaxis {
		text {
			fill: #{map-get($dark-grays, "500")};
		}
	}
	.apexcharts-radar-series {
		polygon {
			stroke: #{map-get($dark-grays, "300")};
		}
		line {
			stroke: #{map-get($dark-grays, "300")};
		}
	}
	.apexcharts-xaxis .apexcharts-datalabel,
	.apexcharts-yaxis text {
		fill: #{map-get($dark-grays, "600")};
	}
	.apexcharts-datalabels-group {
		text {
			fill: #{map-get($dark-grays, "500")};
		}
	}
	.apexcharts-track {
		path {
			stroke: #{lighten(map-get($dark-grays, "300"), 5%)};
		}
	}
}
