//
// _tag.scss
//

$tag-bg-scale: -87.5%;
$tag-bg-hover-scale: -82.5%;
$tag-border-scale: -60%;
$tag-color-scale: 50%;

.tag {
	--tag-bg: #{$gray-200};
	--tag-bg-hover: #{$gray-200};
	--tag-border-color: #{$gray-400};
	--tag-color: #{$gray-800};
	--tag-bg-more: transparent;
	--tag-color-more: #{$gray-600};

	background-color: var(--tag-bg);
	border: #{$border-width} solid var(--tag-border-color);
	border-radius: $border-radius-sm;
	color: var(--tag-color);
	display: inline-flex;
	align-items: center;
	font-size: $font-size-sm;
	line-height: 1;
	padding: $tag-padding-y $tag-padding-x;
	text-align: center;
	text-decoration: none;

	@at-root {
		a#{&},
		button#{&} {
			&:hover {
				background-color: var(--tag-bg-hover);
				color: var(--tag-color);
			}
		}
	}

	@each $state, $value in $colors {
		$tag-bg: shift-color($value, $tag-bg-scale);
		$tag-bg-hover: shift-color($value, $tag-bg-hover-scale);
		$tag-border-color: shift-color($value, $tag-border-scale);
		$tag-color: shift-color($value, $tag-color-scale);

		@if (contrast-ratio($tag-bg, $tag-color) < $min-contrast-ratio) {
			$tag-color: mix($value, color-contrast($tag-bg), abs($tag-color-scale));
		}

		&-#{$state} {
			--tag-bg: #{$tag-bg};
			--tag-bg-hover: #{$tag-bg-hover};
			--tag-border-color: #{$tag-border-color};
			--tag-color: #{$tag-color};
		}
	}

	&-borderless {
		--tag-border-color: transparent;
	}

	&-compact {
		padding: $tag-compact-padding-y $tag-compact-padding-x;
	}

	&-more {
		background-color: var(--tag-bg-more);
		color: var(--tag-color-more);
	}

	&[data-text] {
		border-color: transparent;
		position: relative;
		// text-shadow: 0 0 currentColor;
		white-space: nowrap;

		&,
		&:hover {
			background-color: currentColor;
			color: var(--tag-bg);
		}

		&::before,
		&::after {
			border-radius: inherit;
			position: absolute;
		}

		&::before {
			background-color: transparent;
			color: currentColor;
			content: attr(data-text);
			filter: invert(1) grayscale(100%) contrast(100000%);
			inset: 0;
			opacity: .75;
			padding: inherit;
		}

		&::after {
			border: 1px solid $black;
			content: "";
			inset: -1px;
			mix-blend-mode: color-burn;
			opacity: .15;
		}

		&[href]:hover::after {
			background: rgba($black, .15);
		}

		&.tag-borderless::after {
			content: none;
		}
	}
}
