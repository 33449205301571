iframe.content-preview {
	border: 0;
	min-width: 100%
}

div.content-preview {
	// background-color: $body-bg;
	background-color: $white;
	padding: $spacer * .5;
	overflow: auto;
	@include scrollbar;
}