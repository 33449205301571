// Range
//
// Style range inputs the same across browsers. Vendor-specific rules for pseudo
// elements cannot be mixed. As such, there are no shared styles for focus or
// active states on prefixed selectors.

// Import the base bootstrap styles
@import "bootstrap/scss/forms/form-range";

.form-range {

	// Small size
	&.form-range-sm {

		$form-range-thumb-width-sm: calc($form-range-thumb-width * .75);
		$form-range-thumb-height-sm: calc($form-range-thumb-height * .75);
		$form-range-track-height-sm: calc($form-range-track-height * .75);

		height: $form-range-thumb-height-sm;

		&::-webkit-slider-thumb {
			width: $form-range-thumb-width-sm;
			height: $form-range-thumb-height-sm;
		}

		&::-webkit-slider-runnable-track {
			height: $form-range-track-height-sm;
		}

		&::-moz-range-thumb {
			width: $form-range-thumb-width-sm;
			height: $form-range-thumb-height-sm;
		}

		&::-moz-range-track {
			height: $form-range-track-height-sm;
		}
	}
}
