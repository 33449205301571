.tooltip-lg {
	.tooltip-inner {
		max-width: calc($tooltip-max-width + 60px);
	}
}

.tooltip-xl {
	.tooltip-inner {
		max-width: calc($tooltip-max-width + 120px);
	}
}