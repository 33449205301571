.mp-dashboard-kpi-container {
	&.kpi-statistics > div {
		// Two colums (sm < * < md)
		@include media-breakpoint-between(sm, md) {
			// All except every 2nd
			&:not(:nth-child(2n)) {
				border-right: thin solid $kpis-separator-color;
			}

			&:nth-child(n+3) {
				margin-top: $kpis-vertical-spacer;
			}
		}

		// Three columns (md < * < xl)
		@include media-breakpoint-between(md, xl) {
			// All except every 3rd
			&:not(:nth-child(3n)) {
				border-right: thin solid $kpis-separator-color;
			}

			&:nth-child(n+4) {
				margin-top: $kpis-vertical-spacer;
			}
		}

		// Four columns (xl < *)
		@include media-breakpoint-up(xl) {
			// All except every 4th
			&:not(:nth-child(4n)) {
				border-right: thin solid $kpis-separator-color;
			}

			&:nth-child(n+5) {
				margin-top: $kpis-vertical-spacer;
			}
		}

		// Cancel border for last item
		&:last-child {
			border-right: none;
		}

		& > * + * {
			margin-left: $spacer * .5;
		}

		& > div:first-child {
			max-width: 100%;
		}
	}

	$current-context: &;

	.kpi-title {
		max-width: $kpis-title-max-width;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: $font-size-base;
		color: #{$body-color};

		@at-root #{$current-context} {
			a#{&},
			a#{&}:hover {
				--#{$prefix}link-color: #{$body-color};
				--#{$prefix}link-color-rgb: #{to-rgb($body-color)};
				--#{$prefix}link-decoration: none;
				--#{$prefix}link-hover-decoration: none;
			}
		}
	}

	.kpi-sparkline {
		flex: 0 1 30%;
		margin-bottom: .5625rem;
	}

	.kpi-highlighted .text-dark {
		color: #ff6400 !important;
	}
}

.table-trim-spacing {
	th:first-child,
	td:first-child {
		padding-inline-start: 0;
	}

	th:last-child,
	td:last-child {
		padding-inline-end: 0;
	}
}