// https://animate.style/

// @import "animate.css/source/_vars";

// Custom vars
:root {
  --animate-duration: .8s;
  --animate-delay: .8s;
  --animate-repeat: 1;
}

@import "animate.css/source/_base";

.animated.fastest {
  animation-duration: calc(var(--animate-duration) / 4);
}

.animated.slowest {
  animation-duration: calc(var(--animate-duration) * 4);
}

/* Attention seekers  */
// @import "animate.css/source/attention_seekers/bounce";
// @import "animate.css/source/attention_seekers/flash";
// @import "animate.css/source/attention_seekers/pulse";
// @import "animate.css/source/attention_seekers/rubberBand";
// @import "animate.css/source/attention_seekers/shakeX";
// @import "animate.css/source/attention_seekers/shakeY";
// @import "animate.css/source/attention_seekers/headShake";
// @import "animate.css/source/attention_seekers/swing";
// @import "animate.css/source/attention_seekers/tada";
// @import "animate.css/source/attention_seekers/wobble";
// @import "animate.css/source/attention_seekers/jello";
// @import "animate.css/source/attention_seekers/heartBeat";

/* Back entrances */
// @import "animate.css/source/back_entrances/backInDown";
// @import "animate.css/source/back_entrances/backInLeft";
// @import "animate.css/source/back_entrances/backInRight";
// @import "animate.css/source/back_entrances/backInUp";

/* Back exits */
// @import "animate.css/source/back_exits/backOutDown";
// @import "animate.css/source/back_exits/backOutLeft";
// @import "animate.css/source/back_exits/backOutRight";
// @import "animate.css/source/back_exits/backOutUp";

/* Bouncing entrances  */
// @import "animate.css/source/bouncing_entrances/bounceIn";
// @import "animate.css/source/bouncing_entrances/bounceInDown";
// @import "animate.css/source/bouncing_entrances/bounceInLeft";
// @import "animate.css/source/bouncing_entrances/bounceInRight";
// @import "animate.css/source/bouncing_entrances/bounceInUp";

/* Bouncing exits  */
// @import "animate.css/source/bouncing_exits/bounceOut";
// @import "animate.css/source/bouncing_exits/bounceOutDown";
// @import "animate.css/source/bouncing_exits/bounceOutLeft";
// @import "animate.css/source/bouncing_exits/bounceOutRight";
// @import "animate.css/source/bouncing_exits/bounceOutUp";

/* Fading entrances  */
@import "animate.css/source/fading_entrances/fadeIn";
@import "animate.css/source/fading_entrances/fadeInDown";
@import "animate.css/source/fading_entrances/fadeInDownBig";
@import "animate.css/source/fading_entrances/fadeInLeft";
@import "animate.css/source/fading_entrances/fadeInLeftBig";
@import "animate.css/source/fading_entrances/fadeInRight";
@import "animate.css/source/fading_entrances/fadeInRightBig";
@import "animate.css/source/fading_entrances/fadeInUp";
@import "animate.css/source/fading_entrances/fadeInUpBig";
@import "animate.css/source/fading_entrances/fadeInTopLeft";
@import "animate.css/source/fading_entrances/fadeInTopRight";
@import "animate.css/source/fading_entrances/fadeInBottomLeft";
@import "animate.css/source/fading_entrances/fadeInBottomRight";

/* Fading exits */
@import "animate.css/source/fading_exits/fadeOut";
@import "animate.css/source/fading_exits/fadeOutDown";
@import "animate.css/source/fading_exits/fadeOutDownBig";
@import "animate.css/source/fading_exits/fadeOutLeft";
@import "animate.css/source/fading_exits/fadeOutLeftBig";
@import "animate.css/source/fading_exits/fadeOutRight";
@import "animate.css/source/fading_exits/fadeOutRightBig";
@import "animate.css/source/fading_exits/fadeOutUp";
@import "animate.css/source/fading_exits/fadeOutUpBig";
@import "animate.css/source/fading_exits/fadeOutTopLeft";
@import "animate.css/source/fading_exits/fadeOutTopRight";
@import "animate.css/source/fading_exits/fadeOutBottomRight";
@import "animate.css/source/fading_exits/fadeOutBottomLeft";

/* Flippers */
// @import "animate.css/source/flippers/flip";
// @import "animate.css/source/flippers/flipInX";
// @import "animate.css/source/flippers/flipInY";
// @import "animate.css/source/flippers/flipOutX";
// @import "animate.css/source/flippers/flipOutY";

/* Lightspeed */
// @import "animate.css/source/lightspeed/lightSpeedInRight";
// @import "animate.css/source/lightspeed/lightSpeedInLeft";
// @import "animate.css/source/lightspeed/lightSpeedOutRight";
// @import "animate.css/source/lightspeed/lightSpeedOutLeft";

/* Rotating entrances */
// @import "animate.css/source/rotating_entrances/rotateIn";
// @import "animate.css/source/rotating_entrances/rotateInDownLeft";
// @import "animate.css/source/rotating_entrances/rotateInDownRight";
// @import "animate.css/source/rotating_entrances/rotateInUpLeft";
// @import "animate.css/source/rotating_entrances/rotateInUpRight";

/* Rotating exits */
// @import "animate.css/source/rotating_exits/rotateOut";
// @import "animate.css/source/rotating_exits/rotateOutDownLeft";
// @import "animate.css/source/rotating_exits/rotateOutDownRight";
// @import "animate.css/source/rotating_exits/rotateOutUpLeft";
// @import "animate.css/source/rotating_exits/rotateOutUpRight";

/* Specials */
// @import "animate.css/source/specials/hinge";
// @import "animate.css/source/specials/jackInTheBox";
// @import "animate.css/source/specials/rollIn";
// @import "animate.css/source/specials/rollOut";

/* Zooming entrances */
@import "animate.css/source/zooming_entrances/zoomIn";
// @import "animate.css/source/zooming_entrances/zoomInDown";
// @import "animate.css/source/zooming_entrances/zoomInLeft";
// @import "animate.css/source/zooming_entrances/zoomInRight";
// @import "animate.css/source/zooming_entrances/zoomInUp";

/* Zooming exits */
@import "animate.css/source/zooming_exits/zoomOut";
// @import "animate.css/source/zooming_exits/zoomOutDown";
// @import "animate.css/source/zooming_exits/zoomOutLeft";
// @import "animate.css/source/zooming_exits/zoomOutRight";
// @import "animate.css/source/zooming_exits/zoomOutUp";

/* Sliding entrances */
@import "animate.css/source/sliding_entrances/slideInDown";
// @import "animate.css/source/sliding_entrances/slideInLeft";
@import "animate.css/source/sliding_entrances/slideInRight";
// @import "animate.css/source/sliding_entrances/slideInUp";

/* Sliding exits */
@import "animate.css/source/sliding_exits/slideOutDown";
@import "animate.css/source/sliding_exits/slideOutLeft";
@import "animate.css/source/sliding_exits/slideOutRight";
@import "animate.css/source/sliding_exits/slideOutUp";
