$templates-editor-color: #a9b0b6;
$templates-editor-background-color: #f6f8fa;
$templates-editor-border-color: #e9ecef;
$templates-editor-active-color: #595c60;
$templates-editor-edited-color: #410c96;
$templates-editor-active-background-color: #e8eaee;
$templates-editor-item-padding-y: 2px;
$templates-editor-highlight-color: #2a8add;

.templates-editor-container {
	display: flex;
	.form-filter {
		border-radius: 0;
		border-width: 1px 0 1px 1px;
		text-indent: 1.5rem;
	}

	.card.p-3 {
		padding: 0!important;
	}
}

.templates-editor-column {
	display: flex;
	flex-direction: column;
	width: 300px;
}

.templates-editor-files-tree {
	flex-grow: 1;
	background-color: $templates-editor-background-color;
	color: $templates-editor-color;
	border: 1px solid $templates-editor-border-color;
	margin: 0;

	border-width: 0 0 1px 1px;

	list-style-type: none;
	padding: 0;
	font-size: 0.875rem;

	a {
		color: currentColor;
	}

	.text-highlight {
		color: $templates-editor-highlight-color;
	}

	.menu-folder {
		.mdi-chevron-up,
		.mdi-folder-open-outline {
			display: none;
		}

		&-opened,
		&-expanded {
			.mdi-chevron-up,
			.mdi-folder-open-outline {
				display: inline;
			}
			.mdi-chevron-right,
			.mdi-folder-outline {
				display: none;
			}
		}

		&-opened,
		&-active,
		&:hover {
			background-color: $templates-editor-active-background-color;
			color: $templates-editor-active-color;
			> a {
				visibility: visible;
			}
		}

		> a {
			visibility: hidden;
		}

		padding-top: $templates-editor-item-padding-y;
		padding-bottom: $templates-editor-item-padding-y;

		display: flex;
		align-items: center;
		position: relative;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		.content-toggle {
			flex-grow: 1;
			cursor: default;
		}

		// &-has-children {
		// 	padding-left: .3125rem;
		// }

		// // Expand icon
		// & > span {
		// 	display: flex;
		// 	align-items: center;
		// 	position: absolute;
		// 	height: 100%;
		// 	left: -10px;
		// 	top: 0;
		// 	font-size: 9px;
		// }

		// Folder icon color
		& > i {
			color: currentColor;
		}

		&-opened,
		&-expanded {
			& ~ ul {
				max-height: 100%;
				// transition: all .2s ease-out;
			}
		}

		&-edited {
			color: $templates-editor-edited-color;
		}
	}

	.menu-file {
		&-active,
		&:hover {
			background-color: $templates-editor-active-background-color;
			color: $templates-editor-active-color;
		}

		&-edited {
			color: $templates-editor-edited-color;
		}

		padding-top: $templates-editor-item-padding-y;
		padding-bottom: $templates-editor-item-padding-y;
		
		display: flex;
		align-items: center;
		
		a {
			&:hover {
				text-decoration: none;
			}

			white-space: nowrap;
			flex-grow: 1;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	// Children lists
	& ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
		max-height: 0;
		overflow: auto;
		overflow-x: hidden;

		@include scrollbar;
	}
}