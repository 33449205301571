//
// Litepicker
//
@import "litepicker/dist/css/litepicker";
// @import "litepicker/dist/css/plugins/keyboardnav.js.css";
// @import "litepicker/dist/css/plugins/multiselect.js.css";
@import "litepicker/dist/css/plugins/mobilefriendly.js.css";
@import "litepicker/dist/css/plugins/ranges.js.css";

:root {
	// --litepicker-container-months-color-bg: #fff;
	--litepicker-container-months-box-shadow-color: #{$date-picker-box-shadow-color};
	--litepicker-footer-color-bg: #{$gray-100};
	--litepicker-footer-box-shadow-color: #{$date-picker-box-shadow-color};
	// --litepicker-tooltip-color-bg: #fff;
	// --litepicker-month-header-color: #333;
	// --litepicker-button-prev-month-color: #9e9e9e;
	// --litepicker-button-next-month-color: #9e9e9e;
	--litepicker-button-prev-month-color-hover: #{$primary};
	--litepicker-button-next-month-color-hover: #{$primary};
	// --litepicker-month-width: calc(var(--litepicker-day-width) * 7); // 7 days
	// --litepicker-month-weekday-color: #9e9e9e;
	// --litepicker-month-week-number-color: #9e9e9e;
	--litepicker-day-width: #{$date-picker-day-width};
	// --litepicker-day-color: #333;
	--litepicker-day-color-hover: #{$primary};
	// --litepicker-is-today-color: #f44336;
	--litepicker-is-in-range-color: #{tint-color($primary, 90%)};
	// --litepicker-is-locked-color: #9e9e9e;
	// --litepicker-is-start-color: #fff;
	--litepicker-is-start-color-bg: #{$primary};
	// --litepicker-is-end-color: #fff;
	--litepicker-is-end-color-bg: #{$primary};
	// --litepicker-button-cancel-color: #fff;
	// --litepicker-button-cancel-color-bg: #9e9e9e;
	--litepicker-button-apply-color: #{$white};
	--litepicker-button-apply-color-bg: #{$primary};
	// --litepicker-button-reset-color: #909090;
	--litepicker-button-reset-color-hover: #{$primary};
	// --litepicker-highlighted-day-color: #333;
	// --litepicker-highlighted-day-color-bg: #ffeb3b;
}
// .show-week-numbers {
// 	--litepicker-month-width: calc(var(--litepicker-day-width) * 8);
// }

.litepicker {
	box-shadow: var(--mp-box-shadow);

	.mobilefriendly[data-plugins*="mobilefriendly"] {
		inset-inline: 0 !important;
		margin: 0 5px;
		top: 50%;
		transform: translateY(-50%);
	}

	.container__main {
		background-color: $white;
		border-radius: 5px 5px 0 0;
		// transform: translate3d(3px, 3px, 200px);
	}

	.container__days {
		row-gap: 2px;

		.day-item {
			cursor: pointer;

			&.is-start-date {
				border-radius: 3px 0 0 3px;
			}

			&.is-end-date {
				border-radius: 0 3px 3px 0;
			}
		}
	}

	.container__months {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		box-shadow: none;

		.month-item-header {
			.button-previous-month,
			.button-next-month {
				cursor: pointer;
				stroke: $white;
				stroke-width: 2px;
			}
		}

		.month-item-weekdays-row > div {
			font-size: 100%;
		}
	}

	.container__footer {
		box-shadow: none;
		margin: 0;
	}

	select {
		padding: $form-select-padding-y-sm $form-select-indicator-padding $form-select-padding-y-sm $form-select-padding-x-sm;
		// stylelint-disable-next-line property-no-vendor-prefix
		-moz-padding-start: subtract($form-select-padding-x, 3px); // See https://github.com/twbs/bootstrap/issues/32636
		color: $form-select-color;
		background-color: $form-select-bg;
		background-image: escape-svg($form-select-indicator);
		background-repeat: no-repeat;
		background-position: $form-select-bg-position;
		background-size: $form-select-bg-size;
		border: $form-select-border-width solid $form-select-border-color;
		@include border-radius($form-select-border-radius-sm);
		@include box-shadow($form-select-box-shadow);
		@include transition($form-select-transition);
		appearance: none;

		@at-root .litepicker .container__months .month-item-header div > .month-item-year {
			padding: $form-select-padding-y-sm $form-select-indicator-padding $form-select-padding-y-sm $form-select-padding-x-sm;
		}

		&:focus {
			border-color: $form-select-focus-border-color;
			outline: 0;
			@if $enable-shadows {
				@include box-shadow($form-select-box-shadow, $form-select-focus-box-shadow);
			} @else {
				// Avoid using mixin so we can pass custom focus shadow properly
				box-shadow: $form-select-focus-box-shadow;
			}
		}

		&[multiple],
		&[size]:not([size="1"]) {
			padding-right: $form-select-padding-x;
			background-image: none;
		}

		&:disabled {
			color: $form-select-disabled-color;
			background-color: $form-select-disabled-bg;
			border-color: $form-select-disabled-border-color;
		}

		// Remove outline from select box in FF
		&:-moz-focusring {
			color: transparent;
			text-shadow: 0 0 0 $form-select-color;
		}
	}

	&[data-plugins*="ranges"][data-ranges-position] {
		& > .container__main {
			& > .container__predefined-ranges {
				align-items: normal;
				border-color: transparent;
				border-style: solid;
				border-width: 8px 0;
				box-shadow: none;
				flex-flow: column nowrap;
				gap: 4px;
				max-height: 236px;
				overflow: auto;
				padding: 0 8px;

				@include scrollbar;

				& button {
					background-color: rgba($gray-100, 50%);
					border-radius: 3px;
					margin: 0;
					padding: 5px 10px;
				}

				& button:hover {
					background-color: $gray-100;
					cursor: pointer;
					opacity: 1;
				}

				& button.selected {
					background-color: var(--litepicker-button-apply-color-bg);
					color: var(--litepicker-button-apply-color);
					order: -1;
				}
			}
		}
	}
}

//
// Datepicker
//
.datepicker {
	padding: 10px !important;

	td,
	th {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		font-size: 13px;
		line-height: 28px;
	}

	table {
		tr {
			td {
				&.active.active,
				&.active.disabled,
				&.active.disabled.active,
				&.active.disabled.disabled,
				&.active.disabled:active,
				&.active.disabled:hover,
				&.active.disabled:hover.active,
				&.active.disabled:hover.disabled,
				&.active.disabled:hover:active,
				&.active.disabled:hover:hover,
				.active.disabled:hover[disabled],
				.active.disabled[disabled],
				.active:active,
				.active:hover,
				.active:hover.active,
				.active:hover.disabled,
				.active:hover:active,
				.active:hover:hover,
				.active:hover[disabled],
				.active[disabled],
				span.active.active,
				span.active.disabled,
				span.active.disabled.active,
				span.active.disabled.disabled,
				span.active.disabled:active,
				span.active.disabled:hover,
				span.active.disabled:hover.active,
				span.active.disabled:hover.disabled,
				span.active.disabled:hover:active,
				span.active.disabled:hover:hover,
				span.active.disabled:hover[disabled],
				span.active.disabled[disabled],
				span.active:active,
				span.active:hover,
				span.active:hover.active,
				span.active:hover.disabled,
				span.active:hover:active,
				span.active:hover:hover,
				span.active:hover[disabled],
				span.active[disabled],
				&.today,
				&.today.disabled,
				&.today.disabled:hover,
				&.today:hover {
					background-color: $primary !important;
					background-image: none !important;
					color: $white;
				}

				&.day.focused,
				&.day:hover,
				span.focused,
				span:hover {
					background: #{map-get($grays, "200")};
				}

				&.new,
				&.old,
				span.new,
				span.old {
					color: #{map-get($grays, "700")};
					opacity: 0.4;
				}
			}
		}
	}

	.datepicker-switch:hover,
	.next:hover,
	.prev:hover,
	tfoot tr th:hover {
		background: #{map-get($grays, "200")};
	}

	.datepicker-switch {
		&:hover {
			background: none;
		}
	}
}

.datepicker-dropdown {
	&:after {
		border-bottom: 6px solid $dropdown-bg;
	}
	&:before {
		border-bottom-color: $dropdown-border-color;
	}
	&.datepicker-orient-top {
		&:before {
			border-top: 7px solid $dropdown-border-color;
		}
		&:after {
			border-top: 6px solid $dropdown-bg;
		}
	}
}
.daterangepicker {
	z-index: $zindex-dropdown;
}

[dir="rtl"] .daterangepicker {
	&.opensright {
		&:after {
			right: 10px;
			left: auto;
		}
		&:before {
			right: 9px;
			left: auto;
		}
	}
}

// Dark mode
body[data-layout-color="dark"] {
	.datepicker {
		table {
			tr {
				td {
					&.day.focused,
					&.day:hover,
					span.focused,
					span:hover {
						background: #{map-get($dark-grays, "200")};
					}

					&.new,
					&.old,
					span.new,
					span.old {
						color: #{map-get($dark-grays, "700")};
					}
				}
			}
		}
		.datepicker-switch:hover,
		.next:hover,
		.prev:hover,
		tfoot tr th:hover {
			background: #{map-get($dark-grays, "200")};
		}
	}
}
