[data-widget-fields-mapping] {

	[data-pagination] {
		display: block;
		text-align: right;
	}
	[data-page-current] {
		font-size: $font-size-sm;
	}

	[data-page-prev],
	[data-page-next] {
		font-size: 1.25rem;
		cursor: pointer;
		vertical-align: middle;
	}

	.disabled {
		opacity: .5;
		cursor: default;
	}
}
