.chat {

	&-color {
		&-offline {
			color: var(--chat-color-offline);
		}

		&-online {
			color: var(--chat-color-online);
		}
	}

	&-wrap  {
		--chat-color-offline: var(--mp-gray-400);
		--chat-color-online: #1dc36b;

		contain: content;
		height: calc(var(--vh, 1vh) * 100);

		& .simplebar-content {
			display: flex;
			flex-direction: column;
			height: 100%;

			&::before {
				content: none;
			}
		}
	}

	&-add-wrap {
		&:focus-within {
			flex-grow: 1;

			.mdi {
				display: none;
			}
		}

		&:not(:focus-within) .chat-add-tag {
			clip: rect(0, 0, 0, 0);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			white-space: nowrap;
			width: 1px;
		}
	}

	&-item {
		&-right {
			flex-direction: row-reverse;
		}

		.chat-avatar {
			align-self: end;
		}
	}

	&-message {
		border-radius: .5rem;
		color: var(--mp-dark);
		line-height: 1.3125;
		padding: .5rem .75rem;
		position: relative;
		white-space: pre-wrap;
		width: fit-content;
		word-break: break-word;

		@at-root .chat-item-left & {
			background-color: $white;
			filter: drop-shadow(0 -1px var(--mp-border-color)) drop-shadow(1px 0 var(--mp-border-color)) drop-shadow(0 1px var(--mp-border-color)) drop-shadow(-1px 0 var(--mp-border-color));
			margin-left: .25rem;
		}

		@at-root .chat-item-right &-wrap {
			align-items: flex-end;
			display: flex;
			flex-direction: column;
		}

		@at-root .chat-item-right & {
			background-color: #d9ebfe;
			margin-right: .25rem;
		}

		&:last-child::before {
			border-style: solid;
			bottom: 0;
			content: "";
			height: 1.5rem;
			position: absolute;
			width: 1.5rem;

			@at-root .chat-item-left & {
				border-bottom-right-radius: 1.5rem;
				border-color: $white;
				border-width: .75rem .75rem 0 0;
				clip: rect(.75rem .75rem auto auto);
				margin: -.75rem -.75rem 0 0;
				right: calc(100% - 1px);
			}

			@at-root .chat-item-right & {
				border-bottom-left-radius: 1.5rem;
				border-color: #d9ebfe;
				border-width: .75rem 0 0 .75rem;
				clip: rect(.75rem auto auto .75rem);
				left: calc(100% - 1px);
				margin: -.75rem 0 0 -.75rem;
			}
		}

		&-wrap & + & {
			margin-top: .5rem;
		}
	}

	&-time {
		align-items: center;
		color: var(--mp-gray);
		display: flex;
		font-size: .6875rem;
		user-select: none;
		white-space: nowrap;

		@at-root .chat-item & {
			bottom: auto;
			float: right;
			margin-left: .5rem;
			position: relative;
			top: .4375rem;

			&::before {
				margin-left: .25rem;
				margin-right: -.25rem;
				order: 1;
			}
		}

		&::before {
			background-repeat: no-repeat;
			content: "";
			display: inline-block;
			height: .5rem;
			position: relative;
			width: .875rem;
		}

		&-sent::before {
			background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 14 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m1.5 3.5 2.5 3 4.5-6' stroke='%23868e96' stroke-linecap='round' stroke-linejoin='round' fill='none'/%3E%3C/svg%3E");
		}

		&-received::before {
			background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 14 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.5 4.5 1.5 2 4.5-6m-12 3 2.5 3 4.5-6' stroke='%23868e96' stroke-linecap='round' stroke-linejoin='round' fill='none'/%3E%3C/svg%3E");
		}

		&-read::before {
			background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 14 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.5 4.5 1.5 2 4.5-6m-12 3 2.5 3 4.5-6' stroke='%2337b24d' stroke-linecap='round' stroke-linejoin='round' fill='none'/%3E%3C/svg%3E");
		}
	}

	&-col-middle,
	&-form-wrap {
		background-color: #f3f5f9;
	}

	&-avatar {
		--chat-avatar-size: 40px;
		align-self: flex-start;
		border-radius: 100%;
		cursor: default;
		display: inline-block;
		flex-shrink: 0;
		font-family: var(--mp-font-monospace);
		font-size: calc(var(--chat-avatar-size) / (2 + 2 / 3));
		height: var(--chat-avatar-size);
		line-height: var(--chat-avatar-size);
		position: relative;
		text-align: center;
		user-select: none;
		width: var(--chat-avatar-size);

		&:not([class*="badge-"]) {
			background-color: var(--mp-gray-200);
			color: var(--mp-gray-600);
		}

		&-online,
		&-offline {
			background-image: linear-gradient(var(--chat-color-offline), var(--chat-color-offline));
			background-position: 100% 100%;
			background-repeat: no-repeat;
			background-size: 25% 25%;
			border-radius: 0;
			mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'%3e%3cpath d='M20 0A20 20 0 0 0 0 20a20 20 0 0 0 20 20 20 20 0 0 0 9.328-2.318A6 6 0 0 1 28 34a6 6 0 0 1 6-6 6 6 0 0 1 3.705 1.287A20 20 0 0 0 40 20 20 20 0 0 0 20 0zm14 30.5a3.5 3.5 0 0 0-3.5 3.5 3.5 3.5 0 0 0 3.5 3.5 3.5 3.5 0 0 0 3.5-3.5 3.5 3.5 0 0 0-3.5-3.5z'/%3e%3c/svg%3e");
		}

		&-online {
			background-image: linear-gradient(var(--chat-color-online), var(--chat-color-online));
		}
	}

	&-form {
		border: 1px solid var(--mp-border-color);
		border-radius: .5rem;
	}

	&-textbox {
		resize: none;
		scrollbar-width: thin;
		scrollbar-color: var(--mp-gray-300) #fff;

		&::-webkit-scrollbar {
			width: 6px;
		}

		&::-webkit-scrollbar-track {
			background: #fff;
		}

		&::-webkit-scrollbar-thumb {
			background-color: var(--mp-gray-300);
			border: 1.5px solid #fff;
			border-radius: 3px;
		}
	}

	@media (max-width: 991px) {
		&-wrap::before {
			background-color: rgba(0, 0, 0, .25);
			content: "";
			inset: 0;
			position: absolute;
			pointer-events: none;
			transition: opacity .3s ease-in-out;
			z-index: 1;
		}

		&-wrap:not(.chat-wrap-col-shown)::before {
			opacity: 0;
		}

		&-form {
			border-width: 1px 0 0;
			border-radius: 0;
		}

		&-col {
			&-left,
			&-right {
				position: absolute;
				transition: transform .3s ease-in;
				z-index: 2;
			}

			&-left {
				padding-right: 10vw;
				transform: translate(-100%);
			}

			&-right {
				padding-left: 10vw;
				transform: translate(100%);
			}

			&-show {
				transform: translate(0);
				transition: transform .3s ease-out;
			}
		}
	}
}