// 
// progress.scss
//

// Custom height

// Progress height small
.progress-sm {
	height: 5px;
}

// Progress height medium
.progress-md {
	height: 8px;
}

// Progress height large
.progress-lg {
	height: 12px;
}

// Progress height extra large
.progress-xl {
	height: 15px;
}

.progress-cell {
	td.text-center & {
		justify-content: center;
	}

	td.text-right & {
		justify-content: flex-end;
	}

	& {
		align-items: center;
		display: flex;
		gap: .75rem;
		max-width: 100%;
	}

	& .progress {
		width: 80px;
	}

	& .progress-bar {
		background-color: hsl(var(--progress-percent), 75%, 50%);
		width: calc(var(--progress-percent) * 1%);
	}

	& .progress-text {
		font-size: .875rem;
		text-align: left;
		width: 50px;
	}
}

$progress-colors: (  
	"red":     0,
	"yellow": 50,
	"green": 100,
);

@each $name, $value in $progress-colors {
	.progress-#{$name} {
		& .progress-bar,
		&.progress-bar {
			background-color: hsl($value, 75%, 50%);
		}
	}
}
