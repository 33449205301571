//
// editor.scss
//

.tox {
	&#{&}-tinymce {
		border: 1px solid #e3e3e3;
		border-radius: $card-border-radius;
	}

	&#{&}:not(&-tinymce-inline) &-editor-header {
		padding: 0;
	}

	&#{&} &-menubar,
	&#{&} &-toolbar__group {
		padding: 0 4px 0 4px;
	}

	&#{&} &-menubar + &-toolbar,
	&#{&} &-menubar + &-toolbar-overlord,
	&#{&}:not([dir="rtl"]) &-toolbar__group:not(:last-of-type) {
		border-color: rgba(gray, .15);
	}

	&#{&} &-mbtn {
		margin: 2px 0 3px 0;
	}

	&#{&} &-mbtn {
		margin: 2px 0 3px 0;
	}

	&#{&}-tinymce-aux {
		z-index: $zindex-modal + 1;
	}

	&#{&} &-button:not(&-button--naked) {
		@extend .btn-primary;
	}
}
