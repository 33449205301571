//
// general.scss
//

html {
	min-height: 100%;
	position: relative;
	scroll-padding-top: $topbar-height;
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
	body {
		cursor: pointer;
	}
}

// The .link-underline class has been ported from BS 5.3.1. in src\scss\config\mpro\_utilities-overrides.scss
// .link-underline {
// 	text-decoration: underline;

// 	&:hover {
// 		text-decoration: none;
// 	}
// }

.link-dotted {
	text-decoration: underline dotted;

	&:hover {
		text-decoration: none;
	}
}

.underline-help {
	cursor: help;
	text-decoration-color: rgba($black, .25);
	text-decoration-line: underline;
	text-decoration-style: dashed;
	text-underline-position: under;
}

:where([data-href]) {
	color: var(--#{$variable-prefix}link-color);
	cursor: pointer;
	text-decoration: none;

	&:hover {
		color: var(--#{$variable-prefix}link-hover-color);
	}
}

#beacon-container {
	position: fixed;
	z-index: $zindex-fixed;
}