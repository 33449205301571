[class^="app-theme_editor"] {

	// Hide the body scrollbar
	&.is_logged body {
		overflow-y: hidden;
	}

	#content-wrapper {
		--designer-aside-size: 19rem;
		--designer-sidebar-size: 3rem;
		--designer-sidebar-display: flex;

		// Hide the sidebar
		.sidebar-hidden {
			--designer-sidebar-display: none;
			--designer-sidebar-size: 0rem; // must have rem unit to work
		}
	}

	.main {
		display: flex;
		flex-direction: column;
		height: calc(var(--vh) * 100);
		height: 100dvh;
	}

	.theme-designer-actions {
		.btn:not(.dropdown-toggle) {
			padding-inline: 1rem;
		}
		.active {
			font-weight: $font-weight-bold;
		}
	}

	#main-content {
		overflow-y: auto;
		--mp-z-index-loader: 1000;
	}

	// Normal label font-weight
	.form-label,
	.col-form-label,
	.form-check-label {
		color: $dark;
		font-weight: inherit;
	}

	.designer-navbar {
		background-color: var(--#{$variable-prefix}bg-leftbar);
	}

	.designer-brand {
		padding: 14px 18px;

		&:not(:hover) {
			opacity: .9;
		}
	}

	.designer-wrap {
		background-color: var(--#{$variable-prefix}gray-100);
		display: flex;
		overflow-y: auto;

		--designer-iframe-size: 100%;
		--designer-aside-display: flex;

		&.size-mobile {
			--designer-iframe-size: 375px;
		}
		&.size-tablet {
			--designer-iframe-size: 768px;
		}
		&.size-fullscreen {
			--designer-aside-display: none;
		}
	}

	.designer-sidebar {
		display: var(--designer-sidebar-display);
		gap: .375rem;
		padding: .37rem;

		.designer-sidebar-btn {
			aspect-ratio: 1;
			border-radius: .375rem;
			padding: 0;

			&:hover,
			&:focus {
				background-color: rgba(var(--#{$variable-prefix}gray-rgb), .1);
				box-shadow: none;
			}

			&.active {
				background-color: rgba(var(--#{$variable-prefix}primary-rgb), .1);
				color: var(--#{$variable-prefix}primary);
			}
		}
	}

	.designer-header {
		border-bottom: thin solid var(--#{$variable-prefix}border-color);
	}

	.designer-aside {
		background-color: $white;
		border-color: var(--#{$variable-prefix}border-color);
		border-style: solid;
		display: var(--designer-aside-display);
		flex-direction: column;
	}

	.designer-content {
		--content-font-size: 0.875rem;
		flex-grow: 1;
		font-size: var(--content-font-size);
		overflow-y: auto;

		.form-section {
			margin: 0 !important;

			.card {
				border: 0;
				box-shadow: none;
				margin: 0;
				overflow-x: hidden;
				padding: 0 .5rem !important;
			}

			.accordion-button {
				box-shadow: inset 0 1px 0 var(--#{$variable-prefix}border-color);
				margin: 0;
				padding: 1rem .5rem;
			}

			&:first-child {
				.accordion-button {
					box-shadow: none;
				}
			}

			.accordion-collapse {
				padding: 0 .5rem 1rem;
			}
		}
		.form-section-title {
			font-size: var(--content-font-size);
			line-height: 1.25;
			margin-block: 0;
			white-space: nowrap;
		}

		[data-modal-footer] {
			display: none;
		}
	}

	.designer-main {
		border-radius: .5rem;
		border: thin solid var(--#{$variable-prefix}border-color);
		display: flex;
		flex-grow: 1;
		overflow: hidden;
	}

	.designer-iframe {
		aspect-ratio: 1;
		height: 100%;
		margin: auto;
		width: 100%;
		display: flex;
		justify-content: center;
		// pointer-events: none; // might need it in the future

		iframe {
			transition: width .3s ease-in-out;
			width: var(--designer-iframe-size) !important;
			height: 100%;
		}
	}

	@media (max-width: 767.98px) {
		.designer-wrap {
			flex-direction: column;
		}
		.designer-sidebar {
			flex-direction: row;
			height: var(--designer-sidebar-size);
			max-height: var(--designer-sidebar-size);
		}
		.designer-iframe {
			max-height: calc(var(--vh) * 86);
			max-height: 86dvh;
		}
		.designer-aside {
			border-width: thin 0;
		}
	}

	@media (min-width: 768px) {
		.designer-navbar {
			max-height: 70px;
			min-height: 70px;
		}
		.designer-brand {
			width: calc(var(--designer-sidebar-size) + var(--designer-aside-size));
		}
		.designer-wrap {
			height: 100%;
		}
		.designer-sidebar {
			flex-direction: column;
			max-width: var(--designer-sidebar-size);
			width: var(--designer-sidebar-size);
		}
		.designer-aside {
			border-width: 0 thin;
			max-width: var(--designer-aside-size);
			width: var(--designer-aside-size);
		}
	}

	.btn-check-resize:checked + .btn {
		color: var(--#{$variable-prefix}primary);
	}

	.page-title-box .page-title {
		color: #000;
		font-size: 1.125rem;
		font-weight: bold;
		line-height: 1;
		margin: 0;
		padding: .875rem 1rem;
		white-space: nowrap;
	}
}
