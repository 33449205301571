.product-zone {
	overflow-y: auto;
	overflow-x: hidden;
	min-height: 93px;
	max-height: calc(3 * 95px + 2rem + 40px);

	@include media-breakpoint-down(md) {
		&.dropzone {
			padding: 0;
			height: calc(3 * 95px + 2rem + 40px);
		}
	}
}