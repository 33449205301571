.mdi-table-sort:after,
.mdi-table-sort-asc:after,
.mdi-table-sort-desc:after {
	@extend .mdi-set;
}

// Table sorting icons
.mdi-table-sort,
.mdi-table-sort-asc,
.mdi-table-sort-desc {
	&:before,
	&:after {
		position: relative;
		opacity: .3;
	}
	&:before {
		content: "\F0360";
		top: -0.15em;
	}
	&:after {
		content: "\F035D";
		bottom: -0.15em;
		margin-left: -1em;
	}
}

.mdi-table-sort-asc {
	&:before {
		opacity: 1;
	}
}

.mdi-table-sort-desc {
	&:after {
		opacity: 1;
	}
}