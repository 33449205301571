.edit-in-place {
	& &-reader-input:not([disabled]):not(.action-success):not(.action-error) {
		border-color: transparent !important;
	}

	@include media-breakpoint-up(lg) {
		&-wrap {
			position: relative;

			&::before {
				border: $border-width solid tint-color($primary, 25%);
				content: "";
				opacity: 0;
				position: absolute;
				inset: -1px;
				transition: opacity .15s ease-in-out;
			}

			&:not(:focus-within):hover::before {
				opacity: 1;
			}
		}

		&-wrap &-reader-btn {
			opacity: 0;
			transition: opacity .15s ease-in-out;
		}

		&-wrap:hover &-reader-btn {
			opacity: 1;
		}
	}
}
