.no-results {
	&-title {
		font-size: 1.3125rem;

		&-small {
			font-size: 1.125rem;
		}
	}

	&-action {
		margin-top: .5rem;
	}
}