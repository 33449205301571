[data-widget-tabbed-selector] {
	.nav-item > .form-check {
		display: block;
		padding: $nav-link-padding-y $nav-link-padding-x;
		@include font-size($nav-link-font-size);
		font-weight: $nav-link-font-weight;
		color: $nav-link-color;
		text-decoration: if($link-decoration == none, null, none);
		@include transition($nav-link-transition);

		&:hover,
		&:focus {
			color: $nav-link-hover-color;
			text-decoration: if($link-hover-decoration == underline, none, null);
		}

		// Disabled state lightens text
		&.disabled {
			color: $nav-link-disabled-color;
			pointer-events: none;
			label {
				cursor: default;
			}
		}

		border-color: transparent;
		border-radius: 0;
		border-style: solid;
		border-width: 0 0 2px;
		color: $gray-600;
		font-size: 1rem;
		font-weight: $font-weight-normal;
		gap: .5em;
		margin-bottom: 0;
		max-width: 30ch;
		outline-offset: -1px;
		overflow: hidden;
		padding-left: 0;
		padding-right: 0;
		text-overflow: ellipsis;
		white-space: nowrap;

		label {
			cursor: pointer;
		}

		&:hover,
		&.active {
			background-color: transparent;
			border-color: tint-color($primary, 20%);
			color: $gray-900;
		}

		&.active {
			font-weight: $font-weight-semibold;
		}
	}

	// Dark mode
	body[data-layout-color="dark"] {

		.nav-item > .form-check {
			color: $gray-500;

			&:hover,
			&.active {
				color: $gray-200;
			}
		}
	}
}

