.seo-metafields {
	position: relative;


	&__edit {
		position: absolute;
		top: 0;
		right: 0;
		margin: $form-label-margin-bottom 0;
		font-size: 1.25rem;
	}

	&__preview {
		//margin-bottom: $form-label-margin-bottom;
	}

	&__meta-title {
		color: #1a0dab;
		font-size: 1.125rem;
		margin-bottom: 2px;
	}

	&__slug {
		color: #006621;
		font-size: 0.8125rem;
		margin-bottom: 2px;
	}

	&__meta-description {
		color: $gray-500;
		font-size: 0.8125rem;
		margin-bottom: 2px;
	}

	&__option-index {
		color: #009900;
	}

	&__option-noindex {
		color: #ff3333;
	}
}