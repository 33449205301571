.mp-dt-notifications {
	// Notification icon

	tbody {
		& > tr {
			& > td {
				& > .break-word:last-of-type {
					font-size: .9em;
					color: $text-muted;
				}
			}
		}
	}

	.notification-icon {
		&.warning {
			color: $warning;
		}

		&.error {
			color: $danger;
		}

		&.info {
			color: $info;
		}

		&.readed {
			color: $medium;
		}
	}

}

// Offcanvas fixes

.offcanvas {
	.mp-dt-notifications {

		// Table cell pdding adjusted for drawer view
		.table {
			& > :not(caption) {
				& > * > * {
					padding-left: $spacer * .3125;
					padding-right: $spacer * .3125;
				}
			}

			& tr:last-child {
				& > td {
					border-bottom: 0;
				}
			}
		}
	}
}