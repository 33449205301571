//
// reboot.scss
//

body {
// 	padding-right: 0 !important;
// 	padding-left: 0 !important;

	.loading-overlay {
		visibility: hidden;
	}
}

// a:focus,
// a:focus-visible,
// button:focus,
// button:focus-visible {
// 	outline: thin dotted currentColor !important;
// 	outline-offset: 0 !important;
// }
//
// a:focus:not(:focus-visible),
// button:focus:not(:focus-visible) {
// 	outline: 0 !important;
// }

// Forms
label {
	font-weight: $font-weight-semibold;
}

// Address
address.address-lg {
	line-height: 24px;
}

b,
strong {
	font-weight: $font-weight-semibold;
}

// https://www.youtube.com/watch?v=nIJF_oYU_Po

// Note: this is disabled because it interferes with the modal transition when pressing the escape key

// @media (prefers-reduced-motion: no-preference) {
// 	:focus-visible {
// 		transition: outline-offset 75ms ease-out;
// 	}
// 	:not(:active):focus-visible {
// 		transition-duration: .25s;
// 	}
// }
// :not(:active):focus-visible {
// 	outline-offset: .5rem;
// }


// Links

a {
  color: rgba(var(--#{$prefix}link-color-rgb), var(--#{$prefix}link-opacity, 1));
  text-decoration: var(--#{$prefix}link-decoration, if($link-decoration == null, inherit, $link-decoration));
	text-underline-offset: var(--#{$prefix}link-offset, auto);

  &:hover {
    --#{$prefix}link-color-rgb: var(--#{$prefix}link-hover-color-rgb);
    text-decoration: var(--#{$prefix}link-hover-decoration, if($link-hover-decoration == null, inherit, $link-hover-decoration));
  }
}