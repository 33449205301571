//
// card.scss
//

.card {
	border: none;
	box-shadow: $card-box-shadow;
	margin-bottom: $grid-gutter-width;

	&-divider {
		width: 100%;
		border-top: 1px solid $gray-200;
	}

	.header-title {
		margin-bottom: 0.5rem;
		font-size: 1rem;
		line-height: 1.5;
		margin-top: 0;
	}
	.card-drop {
		font-size: 16px;
		color: inherit;
	}
	.card-widgets {
		float: right;
		height: 16px;
		> a {
			color: inherit;
			font-size: 18px;
			display: inline-block;
			line-height: 1;

			&.collapsed {
				i {
					&:before {
						content: "\F0415";
					}
				}
			}
		}
	}
}

// Card title / Card Header
.card-title,
.card-header {
	margin-top: 0;
}

//Card disable loading (Custom Cards)
.card-disabled {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	border-radius: $card-border-radius;
	background: #{rgba(map-get($grays, "100"), 0.8)};
	cursor: progress;

	.card-portlets-loader {
		background-color: #{map-get($grays, "900")};
		animation: rotatebox 1.2s infinite ease-in-out;
		height: 30px;
		width: 30px;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -($grid-gutter-width * 0.5);
		margin-top: -($grid-gutter-width * 0.5);
	}
}

@keyframes rotatebox {
	0% {
		transform: perspective(120px) rotateX(0deg) rotateY(0deg);
	}
	50% {
		transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
	}
	100% {
		transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
	}
}

// Pricing card

.card-pricing {
	position: relative;

	.card-pricing-plan-name {
		padding-bottom: 20px;
	}

	.card-pricing-icon {
		font-size: 22px;
		box-shadow: $box-shadow;
		height: 60px;
		display: inline-block;
		width: 60px;
		line-height: 56px;
		border-radius: 50%;
	}

	.card-pricing-price {
		padding: 30px 0 0;

		span {
			font-size: 40%;
			color: #{map-get($grays, "600")};
			letter-spacing: 2px;
			text-transform: uppercase;
		}
	}
	.card-pricing-features {
		color: #{map-get($grays, "600")};
		list-style: none;
		margin: 0;
		padding: 20px 0 0 0;

		li {
			padding: 15px;
		}
	}
}

.card-pricing-recommended {
	@include media-breakpoint-up(lg) {
		margin-top: -1.9375rem;
	}

	.card-pricing-plan-tag {
		background-color: rgba($danger, 0.2);
		color: $danger;
		padding: 5px 0;
		font-weight: 700;
		border-radius: .25rem .25rem 0 0;
		margin: -1.5rem -1.5rem 1.5rem -1.5rem;
	}
}

// Custom card height

.card-h-100 {
	height: calc(100% - #{$grid-gutter-width});
}

// Dark mode
body[data-layout-color="dark"] {
	.card-pricing {
		.card-pricing-price span,
		.card-pricing-features {
			color: map-get($dark-grays, "600");
		}
	}
	.card-disabled {
		background: #{rgba(map-get($dark-grays, "100"), 0.8)};
		.card-portlets-loader {
			background-color: #{map-get($dark-grays, "900")};
		}
	}
}

// Order summary links (in card);

.block-container,
.card-order-summary {

	color: var(--#{$prefix}body-color);

	& a:not(.btn, .dropdown-item, .tag, .tag-filter-x) {
		--#{$prefix}link-color: var(--#{$prefix}dark);
		--#{$prefix}link-color-rgb: var(--#{$prefix}dark-rgb);
		--#{$prefix}link-decoration: none;
		--#{$prefix}link-offset: .125rem;
		&:hover {
			color: var(--#{$prefix}gray-dark);
			--#{$prefix}link-hover-decoration: underline;
		}
	}
}

.block-container-transparent {
	background: transparent;
	.block-container-header {
		background: transparent;
	}
}