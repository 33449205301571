//
// authentication.scss
//

// authentication pages background
body.authentication-bg {
	// background-image: $auth-bg-pattern-img;
	// background-size: cover;
	// background-position: center;
	.account-pages {
		align-items: center;
		display: flex;
		min-height: 100vh;
	}
}

.authentication-bg.enlarged,
.auth-fluid-pages.enlarged,
body.auth-fluid-pages[data-leftbar-compact-mode="condensed"] {
	min-height: 100px;
}

// Logout page
.logout-icon {
	width: 140px;
}

// Authentication fluid
.auth-fluid {
	position: relative;
	display: flex;
	align-items: center;
	min-height: 100vh;
	flex-direction: row;
	align-items: stretch;

	.auth-fluid-form-box {
		max-width: 50%;
		border-radius: 0;
		z-index: 2;
		// padding: 3rem 2rem;
		padding: 2rem;
		background-color: $auth-bg;
		position: relative;
		width: 100%;
	}

	&-form {
		max-width: 540px;
	}

	.lead {
		font-size: 1rem;
	}

	@at-root .app-register & {
		.auth-logo {
			height: 17px;
		}
	}

	.auth-fluid-right,
	.auth-fluid-left {
		font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		padding: 6rem 3rem;
		flex: 1;
		position: relative;
		color: $white;
		background-color: rgba($black, 0.3);
		background-image: url("../assets/images/bg-auth.jpg");
		background-position: center;
		background-size: cover;

		@at-root .app-register & {
			background-image: url("../assets/images/bg-auth-register.jpg");
		}

		@at-root .app-oauth-install & {
			background-image: linear-gradient(#f0edfe 0 0);
		}

		.hero-title,
		.hero-subtitle {
			@include font-size(3.125rem);
			font-weight: 600;
			line-height: 1.2;
			margin: 0;
		}

		.hero-title {
			// font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
			// font-family: "Sofia Pro Semi", "Poppins", Arial, Sans-serif;
			font-family: "sofia-pro", "Poppins", Arial, Sans-serif;
			font-style: normal;
		}

		.hero-subtitle,
		.hero-title em,
		.hero-title i {
			// font-family: ui-serif, Georgia, Cambria, 'Times New Roman', Times, serif;
			font-family: "Playfair Display", serif;
			display: inline-block;
			font-style: italic;
		}

		// .hero-subtitle,
		.hero-title em,
		.hero-title i {
			background-image: url('../assets/images/underline.svg');
			background-position-x: center;
			background-position-y: bottom;
			background-repeat: no-repeat;
			background-size: contain;
			padding: 0 0 0.75rem;
		}
	}

	--#{$variable-prefix}text-muted: #6C757D;
	--#{$variable-prefix}input-placeholder-color: #AFB5BC;

	// Form adjustments
	.form-floating > .form-control,
	.form-floating > .iti .form-control,
	.form-floating > .iti .iti__flag-container,
	.form-floating > label {
		font-size: 1rem;
		color: var(--#{$variable-prefix}input-placeholder-color);
	}

	::placeholder {
		color: var(--#{$variable-prefix}input-placeholder-color);
	}

	.form-floating > .form-control,
	.form-floating > .form-select,
	.form-floating > .iti .form-control {
		height: add(3rem, $input-height-border);
    line-height: $form-floating-line-height;
	}

	.form-floating > .form-control:focus ~ label,
	.form-floating > .form-control:not(:placeholder-shown) ~ label,
	.form-floating > .form-select ~ label {
		transform: scale(0.85) translate(0.5rem, calc(-50% - 1.8rem));
	}

	.btn {
		font-family: "sofia-pro", "Poppins", Arial, Sans-serif;
		font-size: 1rem;
		font-weight: 400;
		line-height: 3;
		height: 3rem;
		padding: 0 25px;

		@at-root .app-oauth-install & {
			flex: 1 1 auto;
		}
	}
}

.auth-brand {
	margin-bottom: 2rem;

	.logo-dark {
		display: $logo-auth-dark-display;
	}

	.logo-light {
		display: $logo-auth-light-display;
	}
}

.auth-user-testimonial {
	position: absolute;
	margin: 0 auto;
	padding: 0 1.75rem;
	bottom: 3rem;
	left: 0;
	right: 0;

	p.lead {
		font-size: 1.125rem;
		margin: 0 auto 20px auto;
		max-width: 700px;
	}
}

@include media-breakpoint-up(lg) {
	.auth-brand {
		position: absolute;
		top: 3rem;
	}
}

@include media-breakpoint-down(lg) {
	.auth-fluid {
		display: block;

		.auth-fluid-form-box {
			max-width: 100%;
			min-height: 100vh;
		}

		.auth-fluid-right {
			display: none;
		}
	}
}

// Dark mode
body[data-layout-color="dark"] {
	.auth-brand {
		.logo-dark {
			display: $logo-auth-light-display;
		}
		.logo-light {
			display: $logo-auth-dark-display;
		}
	}
}
