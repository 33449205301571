//
// forms.scss
//

$form-control-important-color: #edf3fb;

.form-container {
	--form-row-spacing: #{$spacer};
}

.not_logged {
	.form-container {
		// --form-row-spacing: calc(#{$spacer} * 1.5);
		--form-row-spacing: #{$spacer};
	}
}

.form-inline {
	margin: 0;
}

.form-row {
	position: relative;
}

.form-group {
	margin-bottom: var(--form-row-spacing);
}

.widget-row:not(:last-child,.widget-row-hidden) {
	margin-bottom: var(--form-row-spacing);
}

.form-group .form-row:not(:last-child):not(:last-child) {
	margin-bottom: var(--form-row-spacing);
}

.widget-row-hidden {
	display: none;
}

.form-section-title {
	color: $dark;
	font-size: $h4-font-size;
	font-weight: $font-weight-semibold;
	margin: 0 0 calc(#{$spacer} * .25);
}

.form-section-help {
	color: $text-muted;
}

.form-section.accordion {
	.accordion-button {
		font-size: unset;
		color: unset;
		background: unset;
		padding: 0 0 1rem 0;
		margin: 0 0 1rem 0;
		@include transition($accordion-transition, padding 0.15s ease-in-out, margin 0.15s ease-in-out);

		&:focus {
			box-shadow: inset 0 -1px 0 var(--mp-accordion-border-color);
		}

		&.collapsed {
			box-shadow: none;
			padding: 0;
			margin: 0;
		}
	}

	// ? Doesn't fix the animation issue
	// .collapsing {
	// 	@include transition($transition-collapse, padding 0.35s ease, margin 0.35s ease);

	// 	&.collapse-horizontal {
	// 		@include transition($transition-collapse-width, padding 0.35s ease, margin 0.35s ease);
	// 	}
	// }
}

// Form-control light
.form-control-light {
	background-color: #{map-get($grays, "100")} !important;
	border-color: #{map-get($grays, "100")} !important;
}

// Form elements (Color and Range)
input.form-control[type="color"],
input.form-control[type="range"] {
	min-height: 39px;
}

// Custom select
// .custom-select.is-invalid,
// .form-control.is-invalid,
// .custom-select:invalid,
// .form-control:invalid,
// .custom-select.is-valid,
// .form-control.is-valid,
// .custom-select:valid,
// .form-control:valid {
// 	&:focus {
// 		box-shadow: none !important;
// 	}
// }

select.form-control {
	&:not([size]):not([multiple]) {
		height: $input-height;
	}
}

select.form-control-sm {
	&:not([size]):not([multiple]) {
		height: $input-height-sm;
	}
}

// Show Password
.password-eye {
	&:before {
		font-family: "Material Design Icons";
		// content: "\F06D0"; // outline style
		content: "\F0208"; // solid style
		font-style: normal;
		font-weight: 400;
		font-variant: normal;
		vertical-align: middle;
		line-height: 1.2;
		font-size: 18px;
	}
}

.show-password {
	.password-eye {
		&:before {
			// content: "\F06D1"; // outline style
			content: "\F0209"; // solid style
		}
	}
}

.hide-infinite-stock {
	width: 100px;

	.form-control {
		opacity: 1;
		width: 100%;
		transition: width .3s linear, opacity .3s linear;
	}
	.input-group-text {
		width: calc(3ch + 2 * #{$input-padding-x});
		transition: width .3s linear;
	}
}
.show-infinite-stock {
	.form-control {
		opacity: 0;
		width: 0!important;
		min-width: 0!important;
		padding-left: 0;
		padding-right: 0;
		border-left-width: 0;
		flex-shrink: 1;
		//transition: all .5s ease;
	}
	.input-group-text {
		border-radius: $input-border-radius !important;
		min-height: $input-height;
		flex-grow: 1;
		width: 100%;
		min-width: calc(3ch + 2 * #{$input-padding-x});
		//transition: all .5s ease;
	}

	.input-group-sm {
		.input-group-text {
			min-height: $input-height-sm;
		}
	}
}

// Dark mode
body[data-layout-color="dark"] {
	.form-control-light {
		background-color: #{map-get($dark-grays, "100")} !important;
		border-color: #{map-get($dark-grays, "100")} !important;
	}
}

.form-hint,
.help-text {
	color: #{map-get($grays, "600")};
	display: block;
	font-size: .875rem;
	margin-top: .5rem;

	@at-root {
		.not_logged & {
			color: var(--#{$variable-prefix}input-placeholder-color);
			font-size: .875rem;
			margin-top: .25rem;
			margin-bottom: 1rem;
		}
	}

	a {
		color: currentColor;
		text-decoration: underline;
	}

	label > &,
	legend > & {
		margin-top: 0;
		margin-left: .5rem;
		display: inline-block;
		font-weight: 400;
	}
}

body[data-layout-color="dark"] {
	.form-hint,
	.help-text {
		color: #{map-get($grays, "400")};
	}
}

[role="button"] {
	.form-check-label,
	.form-check-input {
		cursor: pointer;
	}
}

.form-check-label {
	font-weight: $font-weight-normal;
}

$input-highlight: $primary;
$svg-highlight: str-slice('#{$input-highlight}', 2, -1);

.form-label,
.col-form-label {
	font-weight: $font-weight-bold;
	color: $dark;
	display: flex;
	align-items: baseline;
}

.vertical-form-label {
	padding-top: 0;
  padding-bottom: 0;
  margin-bottom: $form-label-margin-bottom;
}

:root:not(.not_logged) {
	legend,
	.form-label,
	.col-form-label {
		&.required:not(.not-required-visually) {
			&:after {
				content: "*";
				color: $danger;
				margin-left: $spacer * .25;

				// @at-root .not_logged & {
				// 	color: $text-muted;
				// }
			}
		}
	}
}

.form-check-input {
	background-color: $white;
	border-color: $input-border-color;

	&[type=radio]:checked,
	&[type=radio].readonly-checked,
	&[type=radio].selectable-checked,
	&[type=checkbox]:checked,
	&[type=checkbox].readonly-checked,
	&[type=checkbox].selectable-checked,
	&[type="checkbox"]:indeterminate {
		border-color: $input-border-color;
		background-color: $white;
	}

	&[type=radio]:checked,
	&[type=radio].readonly-checked {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='1.75' fill='%23#{$svg-highlight}'/%3e%3c/svg%3e");
	}

	&[type="checkbox"]:indeterminate {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23#{$svg-highlight}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
	}

	&[type="radio"].readonly,
	&[type="checkbox"].readonly,
	&[type="radio"].readonly-checked,
	&[type="checkbox"].readonly-checked {
		filter: invert(0%) sepia(60%) saturate(100%) hue-rotate(203deg) brightness(105%) contrast(97%);
		pointer-events: none;
	}

	&[type="radio"].selectable-checked,
	&[type="checkbox"].selectable-checked {
		filter: invert(0%) sepia(10%) saturate(60%) hue-rotate(-10deg) brightness(115%) contrast(107%);
	}

	@at-root {
		:not(.form-switch) > &[type=checkbox]:checked,
		:not(.form-switch) > &[type=checkbox].readonly-checked {
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23#{$svg-highlight}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
		}

		@media (prefers-reduced-motion: no-preference) {
			.form-switch > & {
				transition: background-color .15s ease-in-out, background-position .15s ease-in-out, border-color .15s ease-in-out;
			}
		}

		.form-switch {
			--switch-width: 3em;
			align-items: center;
			display: flex;
			margin: .125rem 0;
			padding-left: calc(.5em + var(--switch-width));

			&.form-switch-lg {
				--switch-width: 3.5em;
			}

			&.form-switch-sm {
				--switch-width: 2.5em;
			}
		}

		.form-switch > &#{&} {
			background-color: $input-border-color;
			border-color: $input-border-color;
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3.5' fill='%23fff'/%3e%3c/svg%3e") !important;
			height: calc(var(--switch-width) / 2);
			margin: 0 .5em 0 calc(-.5em - var(--switch-width));
			min-width: var(--switch-width);
			width: var(--switch-width);

			&:checked,
			&.readonly-checked,
			&.selectable-checked {
				background-color: $input-highlight;
				border-color: $input-highlight;
				color: $input-highlight;
			}
		}
	}

	&:focus:not(:checked,.readonly-checked) {
		border-color: $input-border-color;
	}

	&#{&}:hover {
		border-color: $input-focus-border-color;
	}

	&:focus,
	&:focus-visible {
		outline: thin dotted currentColor;
		outline-offset: 0 !important;
	}

	&:focus:not(:focus-visible) {
		outline: 0;
	}

	&:active {
		filter: brightness(97.5%);
	}
}

// Ported from latest BS5
.input-group > .form-control,
.input-group > .form-floating,
.input-group > .form-select {
	position: relative;
	flex: 1 1 auto;
	width: 1%;
	min-width: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select,
.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
// End ported from latest BS5

.form-floating {
	&.input-group > .form-label {
		z-index: 3;
	}

	& > .form-control,
	& > .iti .form-control,
	& > .iti .iti__flag-container,
	& > label {
		font-size: 1.0625rem;
		color: $text-muted;
	}

	.iti {
		.iti__arrow {
			border-top-color: $text-muted;
		}
		.iti__arrow--up {
			border-bottom-color: $text-muted;
		}
	}

	// From bs5 source
	& > .iti .form-control {
		height: $form-floating-height;
    line-height: $form-floating-line-height;
	}

	& > .form-control,
	& > .iti .form-control {
		padding: 1rem;
	}

	& > label {
		font-weight: $font-weight-normal;
		height: auto;
		margin: 0 .5rem !important;
		top: 50%;
		transform: translateY(-50%);
	}

	& > .iti ~ label {
		opacity: 1;
		transform: scale(0.85) translate(0.5rem, calc(-50% - 2rem));
	}

	@at-root body[data-layout-color="dark"] & {
		& > label {
			background-color: #{map-get($dark-grays, "100")};
		}
	}

	@at-root body:not([data-layout-color="dark"]) & {
		& > .form-control,
		& > .iti .form-control {
			color: $gray-800;
		}

		& > label {
			background-color: $white;
			color: $gray-500;
		}

		& > .form-control-important ~ label {
			background-color: $form-control-important-color;
		}

		& > .form-control:focus ~ label,
		& > .form-control:not(:placeholder-shown) ~ label,
		& > .form-select ~ label {
			color: $gray-600;
			border-radius: 8px;
		}

		& > .form-control.form-control-important:focus ~ label,
		& > .form-control.form-control-important:not(:placeholder-shown) ~ label,
		& > .form-select.form-control-important ~ label {
			background-color: $white;
		}

		& > .iti ~ label {
			color: $gray-600;
			border-radius: 8px;
			background-color: $white;
		}
	}
}

// Fix right arrow paddings for small select
.form-select-sm {
	background-position: right $form-select-padding-x-sm center;
	// 16px is the box width of the arrow image
	padding-right: calc(#{$form-select-padding-x-sm * 2} + 16px);
}

.input-group {
	flex-wrap: nowrap;

	&:not(&-lg) {
		.mdi {
			@include font-size($input-font-size);
		}
	}
}

:checked+.label-checked-black {
	color: $black;
}

[data-overhead-buttons] {
	transform: translateY(-200px);
	opacity: 0;
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
[data-overhead-buttons].show {
	display: flex;
	transform: translateY(0);
	opacity: 1;
}

.form-control-important {
	background-color: $form-control-important-color;
}