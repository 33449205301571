// Always show the scroll bar, to avoid page jumping when the scroll bar appears and disappears.
.is_logged:not(.app-theme_editor) {
	body {
		overflow-y: scroll;
	}
}

[class*="ajax-fragment-"] {
	max-width: 100%;
	will-change: opacity;
	contain: strict;
}

// .load-strip::before,
// .load-strip {
// 	background: #{$primary};
// 	height: 2px;
// 	left: 0;
// 	position: fixed;
// 	top: 0;
// }

// .load-strip::before {
// 	content: "";
// 	opacity: 0.2;
// 	width: 100%;
// }

// .load-strip {
// 	content: "";
// 	display: none;
// 	width: 0;
// 	z-index: 1005;
// }

@keyframes load-strip {
	100% {
		background-position: top left;
	}
}

.load-strip,
.load-strip::before {
	color: #{$primary};
	background-color: currentcolor;
	height: 3px;
	left: 0;
	position: fixed;
	top: 0;
}

.load-strip {
	animation: load-strip 5s linear infinite;
	// background-image: linear-gradient(135deg, rgba(255, 255, 255, .5) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, .5) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
	// background-size: 32px 32px;
	// background image linear gradient from white to $primary
	background: repeating-linear-gradient(90deg, rgba(255, 255, 255, 0.75), transparent, rgba(255, 255, 255, 0.75) 50%) bottom right/200% 200% $primary;
	box-shadow: 0 0 3px $primary;
	color: rgba($primary, 0.5);
	content: "";
	opacity: 0;
	transition:
		opacity 0.1s,
		width 0.3s;
	width: 0;
	will-change: opacity, width;
	z-index: 100001;
}

.load-strip::before {
	content: "";
	opacity: 0.2;
	width: 100%;
}

.load-strip::after {
	border-color: currentcolor rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) currentcolor;
	border-style: solid;
	border-width: 2px;
	content: "";
	left: 100%;
	position: absolute;
	top: 0;
}

// ------------------------------------------
// --- SWUP ---
// ------------------------------------------

.swup-progress-bar {
  height: 3px;
  animation: load-strip 5s linear infinite;
	background: repeating-linear-gradient(90deg, rgba(255, 255, 255, 0.75), transparent, rgba(255, 255, 255, 0.75) 50%) bottom right/200% 200% $primary;
	box-shadow: 0 0 1px $primary;
	color: rgba($primary, 0.5);
}

html {
	--swup-slide-theme-direction: 1;
	--swup-slide-theme-translate: 60px;
	--swup-slide-theme-duration-fade: .4s;
	--swup-slide-theme-duration-slide-fade: .3s;
	--swup-slide-theme-duration-slide: .4s;
	--swup-slide-theme-translate-forward: calc(var(--swup-slide-theme-direction) * var(--swup-slide-theme-translate));
	--swup-slide-theme-translate-backward: calc(-1 * var(--swup-slide-theme-translate-forward));
}

html.swup-theme-reverse {
	--swup-slide-theme-direction: -1;
}

html.is-changing {

	.overflow-hidden-transition {
		overflow: hidden;
	}

	overflow-x: hidden;

	.swup-transition-main,
	.swup-transition-left,
	.swup-transition-right {
		opacity: 1;
		transform: translate3d(0, 0, 0);
		transition: opacity var(--swup-slide-theme-duration-slide-fade), transform var(--swup-slide-theme-duration-slide);
	}
	.swup-transition-fade {
		opacity: 1;
		transition: opacity var(--swup-slide-theme-duration-fade);
	}
	.swup-transition-gray {
		opacity: 1;
		transition: opacity var(--swup-slide-theme-duration-fade);
		pointer-events: none;
	}
}

html.is-animating {
	.swup-transition-main {
		opacity: 0;
		transform: translate3d(0, var(--swup-slide-theme-translate-backward), 0);
	}
	.swup-transition-left {
		opacity: 0;
		transform: translate3d(var(--swup-slide-theme-translate-forward), 0, 0);
	}
	.swup-transition-right {
		opacity: 0;
		transform: translate3d(var(--swup-slide-theme-translate-backward), 0, 0);
	}
	.swup-transition-fade {
		opacity: 0;
	}
	.swup-transition-gray {
		opacity: 0.5;
	}
}

html.is-animating.is-leaving {
	.swup-transition-main {
		transform: translate3d(0, var(--swup-slide-theme-translate-forward), 0);
	}
	.swup-transition-left {
		transform: translate3d(var(--swup-slide-theme-translate-backward), 0, 0);
	}
	.swup-transition-right {
		transform: translate3d(var(--swup-slide-theme-translate-forward), 0, 0);
	}
}
