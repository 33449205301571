.shm-debug-layout {
	position: relative;
	z-index: 9999999;
}

// Modal errors
.request-error {
	overflow-wrap: break-word;

	iframe {
		width: 100%;
		height: 100%;
		min-height: 250px;
		border: 0;
		padding: 0;
		margin: 0;
		// Not working
		// @include scrollbar;
	}

	pre {
		white-space: break-spaces;
	}

	.rqe_m {
		color: #03a500;
	}
	.rqe_ud {
		color: #0043ff;
	}
	.rqe_bd {
		color: #500000;
	}
	.rqe_e {
		color: #818181;
	}
}
.request-error-title, .request-error-status {
	font-weight: $font-weight-bold;
	margin: 0 0 .75rem 0;
}

.request-error-status,
.request-error-url,
.request-error-body {
	font-family: $font-family-monospace;
	font-size: $font-size-base * .8;
	overflow-wrap: break-word;
}

.request-error-status {
	color: #800000;
	font-size: $font-size-base * 1.2;
}

.request-error-response {
	margin-top: 1rem;
	overflow-wrap: break-word;
}
