@mixin scrollbar($bg: $scrollbar-bg, $color: $scrollbar-color, $size: $scrollbar-size, $thumb-bg: $scrollbar-thumb-bg, $thumb-bg-hover: $scrollbar-thumb-bg-hover) {
	--scrollbar-bg: #{$bg};
	--scrollbar-color: #{$color};
	--scrollbar-size: #{$size};
	--scrollbar-thumb-bg: #{$thumb-bg};
	--scrollbar-thumb-bg-hover: #{$thumb-bg-hover};

	@supports (scrollbar-width: thin) {
		scrollbar-width: thin;
		scrollbar-color: var(--scrollbar-color) var(--scrollbar-bg);
	}

	@supports not (scrollbar-width: thin) {
		&::-webkit-scrollbar {
			height: var(--scrollbar-size);
			width: var(--scrollbar-size);
		}

		&::-webkit-scrollbar-track {
			// background: var(--scrollbar-bg);
			background: inherit;
		}

		&::-webkit-scrollbar-thumb {
			background-clip: padding-box;
			background-color: var(--scrollbar-color);
			border: .125rem solid var(--scrollbar-bg);
			border-radius: var(--scrollbar-size);
		}

		&::-webkit-scrollbar-thumb:hover {
			background-color: var(--scrollbar-thumb-bg-hover);
		}

		&::-webkit-scrollbar-thumb:active {
			background-color: var(--scrollbar-thumb-bg);
		}
	}
}