.select2.select2-container--bootstrap-5 {
	overflow-x: hidden;
}

.select2-add-new {
	&.select2-add-new--dropdown {
		display: block;
		width: 100%;
		padding: $form-select-padding-y $form-select-padding-x;
		border-top: $form-select-border-width solid $form-select-border-color;
		border-bottom: $form-select-border-width solid $form-select-border-color;
	}
}

.select2-new-choice {
	align-items: center;
	background-color: $white;
	display: flex;
	justify-content: space-between;
	margin: -#{$form-select-padding-y} -#{$form-select-padding-x};
	padding: $form-select-padding-y $form-select-padding-x;
	transition: background-color .15s ease-in-out;

	& .mdi {
		color: $gray-500;
		font-size: 1.25em;
		transition: color .15s ease-in-out;
	}

	@at-root .select2-container--bootstrap-5 .select2-results__option--highlighted .select2-new-choice {
		background-color: $s2bs5-item-active-bg;

		.mdi {
			color: $primary;
		}
	}

	&:hover {
		background-color: $s2bs5-item-active-bg;

		.mdi {
			color: $primary;
		}
	}
}

[data-widget-single-theme-selector] .select2-selection {
	height: auto;
}

.select2-selection {
	.select2-theme {
		display: inline-block;
		height: 60px;
	}
}
.select2-results {
	.select2-theme {
		display: inline-block;
		height: 60px;
		img {
			width: 100px;
		}
	}
}