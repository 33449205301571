[data-default-collection-handler] {
	align-items: center;
	display: grid;
	gap: 1rem;
	grid-template-columns: 1fr auto;

	& .collection-row {
		display: contents;
	}

	& .form-row.form-row:not(:last-child) {
		margin-bottom: 0; 
	}

	& [class$="-collection-actions"] {
		text-align: right;
	}
}
