#page_form_app_support_ticket {
	& > .form-columns {
		.form-sections {

			.form-section:not(#form-section-reply) {

				&#form-section-conversation {
					& > .card {
						padding: 0 !important; // Overwrite card padding (cannot be removed from PHP, it uses the default component)
					}
				}

				// Section cards
				.card {
					background-color: transparent;
					box-shadow: none;
				}


				.accordion-item {
					border: none;
					box-shadow: var(--mp-card-box-shadow);
					width: 100%;
					@include transition($transition-base);

					.support-acronym {
						display: flex; 
						justify-content: center;
						align-items: center;
						width: 3rem;
						height: 3rem;
						border-radius: 50%; 
						margin-right: 1rem;
					}

					@include media-breakpoint-up (lg) {
						&:has(> .accordion-header > .accordion-button:not(.collapsed)) {
								width: 135%;
								@include transition($transition-base);
						}
					}
				}

			}
		}
	}
}