//
// stars.scss
//

.stars {
	color: $gray-400;
	display: inline-block;
	font-size: 1em;
	line-height: 1;
	position: relative;
	vertical-align: middle;
	white-space: nowrap;

	&::before,
	&-fill::before {
		content: "\2605\2605\2605\2605\2605";
		vertical-align: text-top;
	}

	&-fill {
		color: $orange;
		left: 0;
		overflow: hidden;
		position: absolute;
		top: 0;
		z-index: 1;

		&--0-5 {width:  10%;}
		&--1   {width:  20%;}
		&--1-5 {width:  30%;}
		&--2   {width:  40%;}
		&--2-5 {width:  50%;}
		&--3   {width:  60%;}
		&--3-5 {width:  70%;}
		&--4   {width:  80%;}
		&--4-5 {width:  90%;}
		&--5   {width: 100%;}
	}

	&-count {
		color: $gray-600;
		display: inline-block;
		vertical-align: middle;
	}
}