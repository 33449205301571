//
// buttons.scss
//

// Create separate variants for white
.btn-white {
	@include button-variant($white, map-get($grays, "300"), $gray-700);

	@at-root body[data-layout-color="dark"] & {
		color: $gray-400;
		background-color: $gray-900;
		border-color: #{tint-color(map-get($dark-grays, "100"),5%)};
	}

	i {
		color: $gray-600;
	}

	.btn-check:checked + &,
	.btn-check:active + &,
	&:active,
	&.active,
	&.dropdown-toggle.show {
		@at-root body[data-layout-color="dark"] & {
			color: $gray-400;
		}
	}
}

.btn-outline-white {
	@include button-outline-variant($gray-700);

	$border-color: map-get($grays, "300");

	border-color: $border-color;

	&:hover {
		border-color: $border-color;
	}

	.btn-check:checked + &,
	.btn-check:active + &,
	&:active,
	&.active,
	&.dropdown-toggle.show {
		border-color: $border-color;
	}
}

// Button Shadows
// @each $color, $value in $theme-colors {
// 	.btn-#{$color} {
// 		@include button-shadow($value);
// 	}
// }

// .btn-white {
// 	background-color: $input-bg;
// 	border-color: $input-border-color;
// 	color: $gray-700;

// 	@at-root body[data-layout-color="dark"] & {
// 		color: $gray-400;
// 	}

// 	i {
// 		color: $gray-600;
// 	}

// 	&:hover,
// 	&.active,
// 	&:active {
// 		background-color: $input-focus-bg;
// 		border-color: $input-focus-border-color;
// 		color: $gray-700;

// 		@at-root body[data-layout-color="dark"] & {
// 			color: $gray-400;
// 		}
// 	}

// 	&.btn:focus {
// 		box-shadow: none;
// 		outline: thin dotted !important;

// 		&:not(:focus-visible) {
// 			outline: 0 !important;
// 		}
// 	}
// }

.btn-dark.dropdown-toggle-split {
	border-left-color: $gray-700;
	z-index: 1;
}

// Dark mode
body[data-layout-color="dark"] {
	.btn-dark.dropdown-toggle-split {
		border-left-color: $gray-400;
	}

	// @each $color, $value in $dark-theme-colors {
	// 	.btn-#{$color} {
	// 		@include button-shadow($value);
	// 	}
	// }

	.btn-light {
		background-color: #{map-get($dark-grays, "300")};
		border-color: #{map-get($dark-grays, "300")};
		color: #{map-get($dark-grays, "900")};
	}

	.btn-dark {
		background-color: #{map-get($dark-grays, "900")};
		border-color: #{map-get($dark-grays, "900")};
		color: #{map-get($dark-grays, "100")};
	}

	.btn-secondary {
		background-color: #{map-get($dark-grays, "600")};
		border-color: #{map-get($dark-grays, "600")};
		color: #{map-get($dark-grays, "300")};
	}

	.btn-outline-light {
		border-color: #{map-get($dark-grays, "300")};
		color: #{map-get($dark-grays, "300")};
		&:hover,
		&.active,
		&:active,
		&:focus {
			color: #{map-get($dark-grays, "900")} !important;
			background-color: #{map-get($dark-grays, "300")};
		}
	}

	.btn-outline-dark {
		border-color: #{map-get($dark-grays, "900")};
		color: #{map-get($dark-grays, "900")};
		&:hover,
		&.active,
		&:active,
		&:focus {
			color: #{map-get($dark-grays, "100")} !important;
			background-color: #{map-get($dark-grays, "900")};
		}
	}

	.btn-outline-secondary {
		border-color: #{map-get($dark-grays, "600")};
		color: #{map-get($dark-grays, "600")};
		&:hover,
		&.active,
		&:active,
		&:focus {
			color: #{map-get($dark-grays, "300")} !important;
			background-color: #{map-get($dark-grays, "600")};
		}
	}
}

.tag-filter {
	align-items: center;
	background-color: $gray-200;
	border-color: $gray-200;
	border-radius: $btn-border-radius-sm;
	color: $gray-600;
	display: inline-flex;
	font-size: $btn-font-size-sm;
	padding: $btn-padding-y-sm .75rem;

	&-x {
		color: inherit;
		font-size: .875rem;
		line-height: 1;
		margin-right: -.375rem;
		order: 1;
		padding: 0 .375rem;
		text-decoration: none;

		&:hover,
		&:focus {
			& ~ * {
				text-decoration: line-through;
			}
		}

		&:focus {
			outline: 0 !important;
		}
	}
}

// --------------------------------------------------

.btn-minimize,
.btn-maximize {
	@extend .btn-close;
}

.btn-minimize {
	background: transparent escape-svg($btn-minimize-bg) center / $btn-close-width auto no-repeat; // include transparent for button elements
}

.btn-maximize {
	background: transparent escape-svg($btn-maximize-bg) center / $btn-close-width auto no-repeat; // include transparent for button elements
}

.btn-group > [data-bs-toggle] {
	border-radius: $border-radius;

	& > .btn {
		border-radius: 0;
		margin-left: calc($btn-border-width * -1);
	}

	&:first-child > .btn {
		@include border-start-radius(inherit);
		margin-left: 0;
	}

	&:last-child > .btn {
		@include border-end-radius(inherit);
	}
}

/// Clear button
.button-clear-input {

	$_btn-clear-width: .5rem;
	$_btn-clear-height: .5rem;
	$_btn-clear-right: $input-padding-x;

	position: absolute;
	top: 50%;
	right: $_btn-clear-right;
	width: $_btn-clear-width;
	height: $_btn-clear-height;
	padding: $btn-close-padding-y $btn-close-padding-x;
	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap;
	background: transparent escape-svg(str-replace($btn-close-bg, #{$btn-close-color}, #{$form-select-border-color})) center / $_btn-clear-height auto no-repeat;
	transform: translateY(-50%);
	// right: 1rem;
	border: none;
	font-size: 1em;

	// Change icon on hover
	&:hover {
		background: transparent escape-svg($btn-close-bg) center / $_btn-clear-height auto no-repeat;
	}

	// Hide children
	>span {
		display: none;
	}
}
