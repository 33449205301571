//
// _expander.scss
//

.expander {
	&-wrap {
		--expander-height: 1.25;
		--expander-width-min: 8rem;
		--expander-width-max: 16rem;
		display: inline-block;

		@at-root .tag & {
			margin: calc($tag-padding-y * var(--expander-height) * -1) calc($tag-padding-x * -1);
			padding: $tag-padding-y $tag-padding-x;
		}

		@at-root .tag-compact & {
			margin: calc($tag-compact-padding-y * var(--expander-height) * -1) calc($tag-compact-padding-x * -1);
			padding: $tag-compact-padding-y $tag-compact-padding-x;
		}
	}

	&-text,
	&-collapsed,
	&-collapsed::before {
		overflow: hidden;
		text-overflow: ellipsis;
		text-decoration: inherit;
	}

	&-text {
		box-sizing: border-box;
		display: inline-flex;
		flex-wrap: wrap;
		font-weight: 400;
		height: calc(var(--expander-height) * 1em);
		line-height: var(--expander-height);
		max-width: clamp(var(--expander-width-min), 33vw, var(--expander-width-max));
		white-space: nowrap;
	}

	&-collapsed {
		display: flex;
		flex-grow: 1;
		text-align: center;
		width: 0;
	}

	&-expanded {
		flex-basis: 100%;
		text-align: left;
	}

	&-collapsed::before,
	&-expanded::before {
		content: var(--expander-text);
	}

	&-collapsed::after {
		align-self: center;
		content: "\f0140";
		font-family: "Material Design Icons";
		font-size: 1.1rem;
		line-height: 1;
		margin-right: -.25em;
	}

	&-check:checked + &-text {
		height: auto;
		white-space: normal;
	}

	&-check:checked + &-text > &-collapsed {
		display: none;
	}
}