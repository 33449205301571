[data-widget-checkbox-list] {
	line-height: calc(var(#{$variable-prefix}body-line-height) * 1rem);

	.readonly,
	[readonly],
	.readonly ~ .form-check-label,
	[readonly] ~ .form-check-label {
		opacity: $btn-disabled-opacity;
		pointer-events: none;
	}

	[data-sort-handle] {
		font-size: 1.125rem;
		cursor: move;
		height: calc(var(#{$variable-prefix}body-line-height) * 1rem);
	}

	.checkbox-list {
		overflow: auto;
		overflow-x: hidden;
	}

	.choices-container {
		> :not(.form-check) {
			margin-left: 3rem;
		}
	}

	.form-check {
		display: flex;
		align-items: center;
		padding: 0;

		> * {
			vertical-align: middle;
		}

		.form-label {
			margin: 0;
		}

		.form-check-input {
			float: none;
			margin: 0 .5rem 0 0;
		}

		[data-sort-handle] ~ .form-check-input {
			margin: 0 .5rem 0 .25rem;
		}
	}

	.form-check-title {
		[data-sort-handle] {
			visibility: hidden;
		}
	}
}

[data-widget-sortable] {
	[data-sort-handle] {
		font-size: 1.125rem;
		cursor: move;
		height: calc(var(#{$variable-prefix}body-line-height) * 1rem);
	}
}