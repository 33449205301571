//
// _circle.scss
//

.circle {
	&-wrap {
		--circle-active: #{$success};
		--circle-animation-duration: 1s;
		--circle-inactive: #{$gray-500};
		--circle-inactive-opacity: .25;
		--circle-percent: 0;
		--circle-size: 3.25em;
		--circle-width: 5;
		aspect-ratio: 1;
		color: $gray-800;
		display: grid;
		font-size: 16px;
		height: var(--circle-size);
		place-items: center;
		position: relative;
		width: var(--circle-size);

		@at-root {
			body[data-layout-color="dark"] &,
			body:not([data-leftbar-theme="light"]) .leftside-menu & {
				color: $gray-200;
			}

			body[data-leftbar-theme="light"] .leftside-menu & {
				color: $gray-800;
			}
		}
	}

	&-wrap > &-svg,
	&-wrap > &-text {
		grid-area: 1 / 1;
	}

	&-shape {
		fill: none;
		stroke-width: var(--circle-width);
		transform: rotate(.75turn);
		transform-origin: 50% 50%;
	}

	&-inactive {
		stroke: var(--circle-inactive);
		stroke-opacity: var(--circle-inactive-opacity);
		stroke-width: var(--circle-width);
	}

	&-active {
		stroke: var(--circle-active);
		stroke-dasharray: var(--circle-percent), 100;
		stroke-width: var(--circle-width);
	}

	&-text {
		display: flex;
		flex-direction: column;
		font-weight: 600;
		line-height: 1;
		max-height: 1em;
		overflow: hidden;

		&::before {
			content: counter(percent) "%";
			counter-reset: percent calc(var(--circle-percent));
			letter-spacing: -.5px;
		}

		&::after {
			content: counter(start) " / " counter(end);
			counter-reset: start var(--circle-steps-start) end var(--circle-steps-end);
			letter-spacing: -1px;
			order: min(-1, var(--circle-steps-end));
			text-align: center;
		}
	}

	@media (prefers-reduced-motion: no-preference) {
		.wrapper.content-loaded &-active {
			animation: circle-progress var(--circle-animation-duration, 1s) ease-in-out;
		}

		@keyframes circle-progress {
			0% {
				stroke-dasharray: 0 100;
			}
		}
	}
}
