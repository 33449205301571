[data-widget-product-variants] {
	.input-group {
		flex-wrap: nowrap;
	}

	[data-bulk-edit-table] {
		font-size: $font-size-sm;
		vertical-align: middle;
	}

	.table-responsive {
		@include scrollbar;
	}

	th {
		border-bottom-width: 2px;
	}
}
