// =======================================================================
/* Custom animations */
// =======================================================================


@keyframes scaleInY {
	from {
		opacity: 0;
		transform: scaleY(0.1);
	}

	40% {
		opacity: .9;
	}
}

.scaleInY {
	animation-name: scaleInY;
	animation-timing-function: get-easing("easeOutCubic");
}