//
// preloader.scss
//

#preloader {
	background-color: #{map-get($grays, "100")};
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 9999;
}

#status {
	height: 80px;
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 80px;
}

.spinner-loader {
	--spinner-width: 2.5px;
	aspect-ratio: 1;
	background-color: $white;
	border-radius: 50%;
	box-shadow: $box-shadow;
	display: grid;
	place-items: center;
	position: relative;

	&::before,
	&::after {
		border-radius: inherit;
		border-style: solid;
		border-width: var(--spinner-width);
		content: "";
	}

	&::before {
		border-color: $primary transparent;
		height: calc(75% - var(--spinner-width));
		position: absolute;
		width: calc(75% - var(--spinner-width));
	}

	&::after {
		border-color: transparent tint-color($primary, 25%);
		height: calc(40% - var(--spinner-width));
		width: calc(40% - var(--spinner-width));
	}

	@media (prefers-reduced-motion: no-preference) {
		&::before,
		&::after {
			animation-iteration-count: infinite;
			animation-timing-function: linear;
		}

		&::before {
			animation-duration: 1.5s;
			animation-name: spin-cw;
		}

		&::after {
			animation-duration: 1s;
			animation-name: spin-ccw;
		}

		@keyframes spin-cw {
			to {
				transform: rotate(1turn);
			}
		}

		@keyframes spin-ccw {
			to {
				transform: rotate(-1turn);
			}
		}
	}
}

// Dark mode
body[data-layout-color="dark"] {
	#preloader {
		background-color: #{map-get($dark-grays, "100")};
	}
}
