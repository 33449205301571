.select2-container--bootstrap-5 {
	// Valid
	.is-valid + &,
	.was-validated select:valid + & {
		// Set border color
		.select2-selection {
			border-color: $s2bs5-valid-border-color;
		}

		&.select2-container--focus,
		&.select2-container--open {
			// Set border color & box shadow
			.select2-selection {
				border-color: $s2bs5-valid-border-color;
				box-shadow: $s2bs5-valid-focus-box-shadow;
			}
		}

		&.select2-container--open {
			// Hide bottom border when open and below
			&.select2-container--below .select2-selection {
				border-bottom: 0 solid transparent;
			}

			// Hide top border when open and above
			&.select2-container--above .select2-selection {
				border-top: 0 solid transparent;
				@include border-top-radius(0);
			}
		}
	}

	// Invalid
	.row-is-invalid &,
	.is-invalid + &,
	.was-validated select:invalid + & {
		// Set border color
		.select2-selection {
			border-color: $s2bs5-invalid-border-color;

			@if $enable-validation-icons {
				&.select2-selection--single {
					background-image: escape-svg($form-select-indicator), escape-svg($form-feedback-icon-invalid);
					background-position: $form-select-bg-position, right $form-select-indicator-padding center;
					background-repeat: no-repeat;
					background-size: $form-select-bg-size, $form-select-feedback-icon-size;
					padding-right: $form-select-feedback-icon-padding-end;

					&.select2-selection--clearable {
						background-position: $form-select-bg-position, right calc($form-select-indicator-padding + 1rem) center;
					}
				}

				&.select2-selection--multiple {
					background-image: escape-svg($form-feedback-icon-invalid);
					background-position: right calc(.375em + .25rem) center;
					background-repeat: no-repeat;
					background-size: $form-select-feedback-icon-size;
					padding-right: calc(1.5em + 1rem);

					&.select2-selection--clearable {
						background-position: right calc($form-select-padding-x * 2) center;
						padding-right: calc($form-select-indicator-padding + 1rem);
					}
				}
			}
		}

		&.select2-container--focus,
		&.select2-container--open {
			// Set border color & box shadow
			.select2-selection {
				border-color: $s2bs5-invalid-border-color;
				box-shadow: $s2bs5-invalid-focus-box-shadow;
			}
		}

		&.select2-container--open {
			// Hide bottom border when open and below
			&.select2-container--below .select2-selection {
				border-bottom: 0 solid transparent;
			}

			// Hide top border when open and above
			&.select2-container--above .select2-selection {
				border-top: 0 solid transparent;
				@include border-top-radius(0);
			}
		}
	}
}
