//
// avatar.scss (avatar thumbnail sizes)
//

.avatar-xxxs {
	height: 0.75rem;
	width: 0.75rem;
}

.avatar-xxs {
	height: 1rem;
	width: 1rem;
}

.avatar-xs {
	height: 1.5rem;
	width: 1.5rem;
}

.avatar-sm {
	height: 3rem;
	width: 3rem;
}

.avatar-md {
	height: 4.5rem;
	width: 4.5rem;
}

.avatar-lg {
	height: 6rem;
	width: 6rem;
}

.avatar-xl {
	height: 7.5rem;
	width: 7.5rem;
}

.avatar {
	--avatar-width: 3rem;
	--avatar-height: 3rem;
	--avatar-bg-color: transparent;
	--avatar-color: #{$gray-800};
	--avatar-outline-color: transparent;

	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--avatar-bg-color);
	border-radius: $border-radius;
	flex-shrink: 0;
	// position: relative;
	// overflow: hidden;
	height: var(--avatar-height);
	width: var(--avatar-width);
	outline: solid 1px var(--avatar-outline-color, currentColor);
	color: var(--avatar-color);
}

.avatar-title {
	align-items: center;
	background-color: $primary;
	color: $white;
	display: flex;
	font-weight: $font-weight-semibold;
	height: 100%;
	justify-content: center;
	width: 100%;
}
