@mixin loading {
	// overflow: hidden;
	position: relative !important;
	@if $loader-show-progress-cursor == true {
		cursor: progress !important;
	}
	@if $loader-disable-pointer-events == true {
		pointer-events: none !important;
	}

	// ? This affects animations - not sure why it was added
	// opacity: 1 !important;

	min-height: $loader-size * 1.75 !important;
	// min-width: $loader-size * 1.75 !important; // this rule interferes with the container width, it is better to not be set at all, the min-height is enough

	// Make sure the loading animation is visible
	// max-height: 100vh; This was causing problems with page scrolling upon section reload.

	.modal &,
	.offcanvas & {
		overflow: hidden !important;
	}

	&#{&}--auto {
		overflow: inherit !important;
	}

	&--absolute {
		position: absolute !important;
	}

	&::after {
		// opacity: 1 !important;

		background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20d%3D%22m8%201a7%207%200%200%201%207%207v0a7%207%200%200%201-7%207%22%20fill%3D%22none%22%20stroke%3D%22%23868e96%22%20stroke-linecap%3D%22round%22%20stroke-width%3D%221%22%3E%3CanimateTransform%20attributeName%3D%22transform%22%20type%3D%22rotate%22%20from%3D%220%208%208%22%20to%3D%22360%208%208%22%20dur%3D%22500ms%22%20repeatCount%3D%22indefinite%22%3E%3C%2FanimateTransform%3E%3C%2Fpath%3E%3C%2Fsvg%3E") !important;

		// background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' width='200px' height='200px' style='height:100%25;width:100%25;background:0 0'%3E%3Cdefs%3E%3Cstyle id='breath-1' type='text/css'%3E@keyframes breath-1%7B0%25%7Banimation-timing-function:cubic-bezier(1.394,.1255,-.4453,.8952);transform:scale(.75)%7D51%25%7Banimation-timing-function:cubic-bezier(1.4015,.1472,-.4614,.8794);transform:scale(1.25)%7Dto%7Btransform:scale(.75)%7D%7D%3C/style%3E%3C/defs%3E%3Cg style='transform-origin:50%25 50%25'%3E%3Cg style='transform-origin:50px 50px'%3E%3Cg%3E%3Cg style='transform-origin:20px 50px;animation:1.2s linear -1.2s infinite normal forwards running breath-1'%3E%3Cpath d='M12.5 25h15v50h-15z' fill='%23d8dddf'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cg style='transform-origin:40px 50px;animation:1.2s linear -.96s infinite normal forwards running breath-1'%3E%3Cpath d='M32.5 25h15v50h-15z' fill='%23b8babd'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cg style='transform-origin:60px 50px;animation:1.2s linear -.72s infinite normal forwards running breath-1'%3E%3Cpath d='M52.5 25h15v50h-15z' fill='%23a3a3ac'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cg style='transform-origin:80px 50px;animation:1.2s linear -.48s infinite normal forwards running breath-1'%3E%3Cpath d='M72.5 25h15v50h-15z' fill='%23727a7e'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat center center / contain;

		// transition: background-color ease 0.3s, background-image ease 0.3s, opacity ease 0.2s !important;
		background-position: center center !important;
		background-repeat: no-repeat !important;
		background-size: $loader-size $loader-size !important;
		background-color: $loader-bg;
		border-radius: inherit !important;
		position: absolute !important;
		inset: 0 !important;
		z-index: var(--#{$variable-prefix}zindex-loader);
		display: flex !important;
		content: attr(data-loading-text) !important;
		align-items: center !important;
		justify-content: center !important;
		color: inherit;
		font-size: 1rem;
		font-weight: 600;
	}

	// TODO
	&--animated::after {
		opacity: 0 !important;
		transition: background-color ease 0.3s, background-image ease 0.3s, opacity ease 0.2s !important;
	}

	&--removing::after {
		opacity: 0 !important;
		transition: background-color ease 0.3s, background-image ease 0.3s, opacity ease 0.2s !important;
	}

	&--transparent::after {
		background-color: rgba(255, 255, 255, 0) ;
	}

	&--bg-25::after {
		background-color: rgba(255, 255, 255, 0.25);
	}

	&--bg-50::after {
		background-color: rgba(255, 255, 255, 0.5);
	}

	&--bg-75::after {
		background-color: rgba(255, 255, 255, 0.75);
	}

	&--bg-100::after {
		background-color: rgb(255, 255, 255);
	}

	&--none::after {
		background-image: none;
	}

	&--lg {
		min-height: $loader-size * 2.25 !important;
		// min-width: $loader-size * 2.25 !important; // this rule interferes with the container width, it is better to not be set at all, the min-height is enough

		&::after {
			background-size: $loader-size * 1.5 $loader-size * 1.5 !important;
			font-size: 1.4rem;
		}
	}

	&--md {
		min-height: $loader-size * 1.5 !important;
		// min-width: $loader-size * 1.5 !important; // this rule interferes with the container width, it is better to not be set at all, the min-height is enough

		&::after {
			background-size: $loader-size * 0.75 $loader-size * 0.75 !important;
			font-size: 0.75rem;
		}
	}

	&--sm {
		min-height: $loader-size * 0.84 !important;
		// min-width: $loader-size !important; // this rule interferes with the container width, it is better to not be set at all, the min-height is enough

		&::after {
			background-size: $loader-size * 0.5 $loader-size * 0.5 !important;
			font-size: 0.6rem;
		}
	}

	&--xs {
		min-height: $loader-size * 0.4 !important;
		// min-width: $loader-size * .4 !important; // this rule interferes with the container width, it is better to not be set at all, the min-height is enough

		&::after {
			background-size: $loader-size * 0.35 $loader-size * 0.35 !important;
			font-size: 0.4rem;
		}
	}
}

.loading {
	@include loading;
}

body.loading {
	&::after {
		position: fixed;
	}
}

// Markup for .loader
// div.loader.visible
// div.loader-wrapper.visible
// div.loader-animation

.loader {
	opacity: 0;
	background-color: #fff;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -100;
	transition:
		opacity 0.3s ease-out,
		z-index 0.3s step-end;

	&.visible {
		cursor: progress;
		opacity: 0.7;
		z-index: 2000;
		transition:
			opacity 0.2s ease-in,
			z-index 0.2s step-start;
	}

	.loader-wrapper {
		display: none;
		margin: 40vh auto auto;
		margin: calc(var(--vh, 1vh) * 40) auto auto;
		padding-left: 1em;
		padding-right: 1em;
		transition: visibility 0.3s step-end;
		visibility: hidden;
		width: 195px;
	}

	.loader-wrapper.visible {
		display: block;
		visibility: visible;
	}

	.loader-animation {
		border-radius: 50%;
		position: fixed;
		display: inline-block;
		vertical-align: middle;
		width: 80px;
		height: 80px;
		margin: 3em;

		&::before,
		&::after {
			position: absolute;
			content: "";
			border: 4px solid #5858dc;
			opacity: 1;
			border-radius: 50%;
			animation: lds-ripple 0.7s cubic-bezier(0, 0.2, 0.8, 1) infinite;
			height: 100%;
			width: 100%;
			left: 0;
			top: 0;
		}
		&::after {
			animation-delay: -0.35s;
		}
	}

	@media screen and (min-width: 52.5em) {
		position: absolute;

		.loader-wrapper {
			margin-top: 20vh;
			margin-top: calc(var(--vh, 1vh) * 20);
		}
	}
}

// Ripple animation
.lds-ripple {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;

	&::before,
	&::after {
		position: absolute;
		content: "";
		border: 4px solid #5858dc;
		opacity: 1;
		border-radius: 50%;
		animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
	}

	&::after {
		animation-delay: -0.5s;
	}
}

@keyframes lds-ripple {
	0% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 1;
		border: 4px solid #5858dc;
	}
	100% {
		top: 0px;
		left: 0px;
		width: 72px;
		height: 72px;
		opacity: 0;
		border-color: #5858dc;
	}
}

@keyframes loading {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}

/* ****************** */

.btn-loading::after,
.over-loading::after,
.over-message::after {
	background: rgba(255, 255, 255, 0.25);
	bottom: 0;
	content: "";
	display: block;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
}

.over-loading.bg-none::after {
	background: none;
}

.over-message::before {
	border-radius: 4px;
	box-sizing: border-box;
	content: attr(data-message);
	font-size: 12px;
	left: 50%;
	min-height: 28px;
	min-width: 28px;
	padding: 5px 10px;
	position: absolute;
	text-align: center;
	text-decoration: inherit;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	z-index: 2;
}

.over-loading .over-error::before,
.over-loading .over-message::before {
	content: "";
}

.btn-loading::before,
.over-loading::before,
.input-loading {
	animation: 0.6s linear infinite loading;
	border-color: #{$primary} rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
	border-radius: 60%;
	border-style: solid;
	border-width: 2px;
	content: "";
	display: block;
	height: 24px;
	left: 50%;
	margin: -12px 0 0 -12px;
	position: absolute;
	top: 50%;
	width: 24px;
	z-index: 2;
}

.over-loading::before {
	background: none;
}

.btn-loading::before {
	animation-duration: 0.5s;
	border-right-color: currentColor;
	border-top-color: currentColor;
	height: 12px;
	margin: -6px 0 0 -6px;
	width: 12px;
}

.btn-loading::after {
	// background: inherit;
	// border-radius: inherit;
}

.btn-loading.btn-link::after {
	background: #f4f6f8;
}

.btn-loading {
	position: relative;
}
