// Utilities

@import "utilities/background";
@import "utilities/object";
@import "utilities/text";

// border style
.border-dashed {
	border-style: dashed !important;
}

// Dark mode
body[data-layout-color="dark"] {
	@each $color, $value in $dark-theme-colors {
		.border-#{$color} {
			border-color: $value !important;
		}
	}
}

.break-word {
	// word-break: break-word;
	// word-wrap: break-word;
	overflow-wrap: break-word;
}

.pointer-none {
	pointer-events: none;
}

.pointer-auto {
	pointer-events: auto;
}

.pointer-all {
	pointer-events: all;
}

.transition-none {
	transition: none !important;
	will-change: auto !important;
}

.font-system {
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.font-serif {
	font-family: ui-serif, Georgia, Cambria, 'Times New Roman', Times, serif;
}

.max-height-defined {
	max-height: var(--max-height, 12lh);
	overflow-y: auto;

	@include scrollbar;
}