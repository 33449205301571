[data-widget-single-tree-selector],
[data-widget-multi-tree-selector] {
	@include scrollbar;

	line-height: calc($line-height-base * 1rem);
	overflow: auto;
	overflow-x: hidden;

	> .form-row.form-row.form-row {
		margin-bottom: 0;
	}

	@for $level from 2 through 6 {
		> [data-level="#{$level}"] {
			padding-left: .5rem * $level;
		}
	}
}
